import React from 'react';
import { BasicSideBarMenuItem } from '@izi-logistics/common/lib/components';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {
  WarehouseIcon,
  DashboardIcon,
  PreparationIcon,
  InProgressIcon,
} from '@izi-logistics/common/lib/components/icons';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EditIcon from '@material-ui/icons/Edit';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import LayersIcon from '@material-ui/icons/Layers';
import ReportIssue from '@izi-logistics/common/lib/components/ReportIssue/icon';
// import DashboardIcon from '@material-ui/icons/Dashboard';
const isHideInvoice = process.env.REACT_APP_VERSION === '2.1.0';

export const menu: any = (langMenu: any, langApp: any, currentUser): BasicSideBarMenuItem[] | any => {
  if (!currentUser._id) {
    return [];
  }
  return [
    {
      icon: <ControlPointIcon />,
      title: langMenu.t('menu_sidebar_new_order_title'),
      path: '/orders/create',
      _id: 'menu-sidebar-new-order',
    },

    {
      icon: (
        <span>
          <PreparationIcon title={langApp.t('app_preparation')} />
        </span>
      ),
      title: langApp.t('app_preparation'),
      path: '/preparation',
      _id: 'menu-sidebar-preparing',
    },

    {
      icon: <DescriptionOutlinedIcon />,
      title: langMenu.t('menu_sidebar_open_orders_title'),
      path: '/open-orders',
      _id: 'menu-sidebar-open-orders',
    },
    {
      icon: (
        <span>
          <InProgressIcon title={langMenu.t('menu_sidebar_in_progress_title')} />
        </span>
      ),
      title: langMenu.t('menu_sidebar_in_progress_title'),
      path: '/in-progress',
      _id: 'menu-sidebar-in-progress',
    },
    {
      icon: <RestoreOutlinedIcon />,
      title: langMenu.t('menu_sidebar_archive_title'),
      path: '/archive',
      _id: 'menu-sidebar-archive',
      isHiddenUnderOther: true,
    },
    {
      icon: <EditIcon id={'edit-btn'} />,
      title: langMenu.t('menu_sidebar_draft_title'),
      path: '/drafts',
      _id: 'menu-sidebar-drafts',
      isHiddenUnderOther: true,
    },

    {
      icon: <FeaturedPlayListIcon />,
      title: langMenu.t('menu_sidebar_template_title'),
      path: '/templates',
      _id: 'menu-sidebar-templates',
      isHiddenUnderOther: true,
    },
    // !isHideInvoice && {
    //   icon: (
    //     <span>
    //       <ManufacturingIcon />
    //     </span>
    //   ),
    //   title: langApp.t('main_manufacturing'),
    //   path: '/manufacturing',
    // },

    {
      icon: <GroupOutlinedIcon />,
      title: langMenu.t('menu_sidebar_team_title'),
      path: '/team',
      _id: 'menu-sidebar-team',
      isHiddenUnderOther: true,
    },
    {
      icon: <LocalShippingIcon />,
      title: langMenu.t('menu_sidebar_carriers'),
      path: '/carriers',
      _id: 'menu-sidebar-carriers',
      isHiddenUnderOther: true,
    },
    {
      icon: <LocationOnIcon />,
      title: langMenu.t('menu_sidebar_locations_title'),
      path: '/locations',
      _id: 'menu-sidebar-locations',
      isHiddenUnderOther: true,
    },
    {
      icon: (
        <span>
          <WarehouseIcon title={langMenu.t('menu_sidebar_warehouse')} />
        </span>
      ),
      title: langMenu.t('menu_sidebar_warehouse'),
      path: '/warehouses',
      _id: 'menu-sidebar-warehouses',
    },
    // {
    //   icon: (
    //     <span>
    //       <ChannelChatIcon title={langMenu.t('menu_sidebar_chat_title')} />
    //     </span>
    //   ),
    //   title: langMenu.t('menu_sidebar_chat_title'),
    //   path: '/chats',
    // },

    !isHideInvoice && {
      icon: <AllInboxIcon />,
      title: langApp.t('main_finance'),
      path: '/finance',
      _id: 'menu-sidebar-finance',
      isHiddenUnderOther: true,
    },

    {
      icon: <LayersIcon />,
      title: langApp.t('main_terms'),
      path: '/terms',
      _id: 'menu-sidebar-terms',
      isHiddenUnderOther: true,
    },
    {
      icon: <ReportIssue />,
      title: langApp.t('main_issue'),
      path: '/issues',
      _id: 'menu-sidebar-issues',
      isHiddenUnderOther: true,
    },
    {
      icon: (
        <span>
          <DashboardIcon title={langMenu.t('menu_sidebar_dashboard')} />
        </span>
      ),
      title: langMenu.t('menu_sidebar_dashboard'),
      path: '/dashboard',
      _id: 'menu-sidebar-dashboard',
    },
  ].filter((e) => e);
};
