var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE, USER_COMPANY_BASE_I2, USER_WITH_COMPANY_BASE_I } from '../base';
import { USER_WITH_COMPANY_BASE } from '../user';
import { COMMENT_BASE } from '../comments';
import { ORDER_BASE_TO_VIEW_MORE } from './types';
import { ORDER_LIST_DETAILS_v1 } from './type.v1';
import { ORDER_PAYMENT_BASE } from './orderPayment';
import { ORDER_LOAN_BASE } from './orderLoan';
import { ORDER_REQUEST_CHANGE_BASE } from './orderQuoteRequestChange';
import { USER_RATING_BASE } from '../userRatings';
import { LOGS_BASE } from '../logs';
import { FAST_PAYMENT_BASE } from '../fastPayment';
export var ORDER_QUOTE_BASE_LESS_V1 = "\n  " + BASE_QUERY + "\n       amount\n       indexId\n       forwarderAmount\n       availableForCustomer\n       status\n       fastPaymentStatus\n       comments{\n        " + COMMENT_BASE + "\n       }\n       approvedAt \n       rejectedAt \n       inProgressStatus\n       canceledAt\n       vehicleCO2Type\n        distance\n        co2Emission\n       expireStart\n       needApprove\n       expireEnd\n       deniedContraOfferAt\n       deletedAt\n        currency\n         tempTransportations{\n          startDate\n          endDate\n        }\n       createdBy{\n        " + USER_WITH_COMPANY_BASE + "\n       } \n";
export var ORDER_QUOTE_BASE_LESS = "\n  " + ORDER_QUOTE_BASE_LESS_V1 + "\n       fastPayment{\n            " + FAST_PAYMENT_BASE + "\n        } \n";
export var ORDER_QUOTE_BASE = "\n    " + ORDER_QUOTE_BASE_LESS + "\n       order{\n            _id\n            orderId\n            forwarders{\n                _id\n                company{\n                    _id\n                }\n            }\n       }\n      \n";
export var ORDER_QUOTE_BASE_MORE = "\n    " + ORDER_QUOTE_BASE + "\n   \n";
export var FetchOrderQuotes = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query orderQuotes($filter: MainFilter) {\n        orderQuotes(filter: $filter) {\n            data{\n                ", "\n                lastLog{\n                    ", "\n                }\n                canceledAt\n                parent{\n                    _id\n                }\n                contraOffer{\n                    _id\n                    amount\n                    currency\n                    log{\n                    ", "\n                    }\n                }\n            }\n            count\n\n            priceRange{\n                min\n                max\n            }\n        }\n    }\n"], ["\n    query orderQuotes($filter: MainFilter) {\n        orderQuotes(filter: $filter) {\n            data{\n                ", "\n                lastLog{\n                    ", "\n                }\n                canceledAt\n                parent{\n                    _id\n                }\n                contraOffer{\n                    _id\n                    amount\n                    currency\n                    log{\n                    ", "\n                    }\n                }\n            }\n            count\n\n            priceRange{\n                min\n                max\n            }\n        }\n    }\n"])), ORDER_QUOTE_BASE, LOGS_BASE, LOGS_BASE);
export var FetchOrderQuote = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query orderQuote($_id: MongoId!) {\n        orderQuote(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    query orderQuote($_id: MongoId!) {\n        orderQuote(_id: $_id) {\n            ", "\n        }\n    }\n"])), ORDER_QUOTE_BASE);
var ORDER_QUOTE_DETAILS_QUERY = "\n  query orderQuoteDetails($_id: String) {\n        orderQuoteDetails(_id: $_id) {\n            mainHTML\n            " + ORDER_QUOTE_BASE_LESS + "\n            order {\n                noQuotes\n                orderId\n                status\n                commentCMR\n                sorting_hasInvoice\n                sorting_countryPickup\n                sorting_countryDelivery\n                sorting_deliveryAt\n                sorting_pickupAt\n                sorting_totalWeight\n                publishedAt\n                vehicleType{\n                    name\n                }\n                rating{\n                    " + USER_RATING_BASE + "\n                }\n                forwarders{\n                  " + USER_WITH_COMPANY_BASE_I + "\n                }\n                lastPayment{\n                    " + ORDER_PAYMENT_BASE + "\n                }\n                lastRequestChange{\n                    " + ORDER_REQUEST_CHANGE_BASE + "\n                }\n                lastLoan{\n                    " + ORDER_LOAN_BASE + "\n                }\n                manager{\n                    " + USER_WITH_COMPANY_BASE + "\n                }\n                company{\n                    " + USER_COMPANY_BASE_I2 + "\n                }\n                companies\n                " + BASE_QUERY.toString().replace('company{', 'company{    bank\n     bankCode\n     bankSwiftCode\n     bankAccountCode') + "\n                " + ORDER_BASE_TO_VIEW_MORE + "\n            }\n        }\n    }";
var ORDER_QUOTE_DETAILS_QUERY_V1 = "\n  query orderQuoteDetails($_id: String) {\n        orderQuoteDetails(_id: $_id) {\n            mainHTML\n            " + ORDER_QUOTE_BASE_LESS_V1 + "\n            order {\n                noQuotes\n                orderId\n                status\n                timeToSpend\n                commentCMR\n                sorting_hasInvoice\n                sorting_countryPickup\n                sorting_countryDelivery\n                sorting_deliveryAt\n                sorting_pickupAt\n                sorting_totalWeight\n                publishedAt\n                rating{\n                    " + USER_RATING_BASE + "\n                }\n                vehicleType{\n                    name\n                }\n                manager{\n                    " + USER_WITH_COMPANY_BASE + "\n                }\n                company{\n                    " + USER_COMPANY_BASE_I2 + "\n                }\n                companies\n                " + ORDER_BASE_TO_VIEW_MORE + "\n            }\n        }\n    }";
export var FetchOrderQuoteDetails = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), ORDER_QUOTE_DETAILS_QUERY);
export var FetchOrderQuoteDetailsV1 = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), ORDER_QUOTE_DETAILS_QUERY_V1.toString().replace('warehouse{', 'warehouse{templateInfo'));
export var FetchOrderQuoteCalendarView = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    query orderQuoteView($filter:MainFilter) {\n        orderQuoteView(filter: $filter) {\n            ", "\n            order {\n                 ", "\n            }\n        }\n    }\n"], ["\n    query orderQuoteView($filter:MainFilter) {\n        orderQuoteView(filter: $filter) {\n            ", "\n            order {\n                 ", "\n            }\n        }\n    }\n"])), ORDER_QUOTE_BASE_LESS, ORDER_LIST_DETAILS_v1);
export var UpdateOrderQuote = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation updateOrderQuote($input: OrderQuoteInputUpdate!) {\n        updateOrderQuote(input: $input) {\n            ", "\n        }\n    } \n"], ["\n    mutation updateOrderQuote($input: OrderQuoteInputUpdate!) {\n        updateOrderQuote(input: $input) {\n            ",
    "\n        }\n    } \n"])), ORDER_QUOTE_BASE_MORE.replace("order{\n            _id\n            orderId\n       }", ''));
export var UpdateOrderQuoteDetails = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    mutation updateOrderQuoteDetails($input: OrderQuoteInputUpdate!) {\n        updateOrderQuoteDetails(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderQuoteDetails($input: OrderQuoteInputUpdate!) {\n        updateOrderQuoteDetails(input: $input) {\n            ",
    "\n        }\n    }\n"])), ORDER_QUOTE_BASE_MORE.replace("order{\n            _id\n            orderId\n       }", ''));
export var DeleteOrderQuote = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    mutation deleteOrderQuote($_id: MongoId!) {\n        deleteOrderQuote(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderQuote($_id: MongoId!) {\n        deleteOrderQuote(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
export var CreateOrderQuote = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    mutation createOrderQuote($input: OrderQuoteInput!) {\n        createOrderQuote(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderQuote($input: OrderQuoteInput!) {\n        createOrderQuote(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_QUOTE_BASE);
export var CreateOrderQuoteForCarrier = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    mutation createOrderQuoteForCarrier($input: OrderQuoteInput!) {\n        createOrderQuoteForCarrier(input: $input) {\n            ", " \n        }\n    }\n"], ["\n    mutation createOrderQuoteForCarrier($input: OrderQuoteInput!) {\n        createOrderQuoteForCarrier(input: $input) {\n            ", " \n        }\n    }\n"])), ORDER_QUOTE_BASE);
export var AcceptOrderQuote = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    mutation acceptOrderQuote($input: OrderQuoteInputUpdateStatus!) {\n        acceptOrderQuote(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation acceptOrderQuote($input: OrderQuoteInputUpdateStatus!) {\n        acceptOrderQuote(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_QUOTE_BASE);
export var RejectOrderQuote = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    mutation rejectOrderQuote($input: OrderQuoteInputUpdateStatus!) {\n        rejectOrderQuote(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation rejectOrderQuote($input: OrderQuoteInputUpdateStatus!) {\n        rejectOrderQuote(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_QUOTE_BASE);
export var AcceptContraOrderQuote = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    mutation acceptContraOrderQuote($_id: MongoId!) {\n        acceptContraOrderQuote(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation acceptContraOrderQuote($_id: MongoId!) {\n        acceptContraOrderQuote(_id: $_id) {\n            ", "\n        }\n    }\n"])), ORDER_QUOTE_BASE);
export var DenyContraOrderQuote = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    mutation denyContraOrderQuote($parentId: MongoId!) {\n        denyContraOrderQuote(parentId: $parentId) {\n            ", "\n        }\n    }\n"], ["\n    mutation denyContraOrderQuote($parentId: MongoId!) {\n        denyContraOrderQuote(parentId: $parentId) {\n            ", "\n        }\n    }\n"])), ORDER_QUOTE_BASE);
export var CheckOrderOfferPrice = gql(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  query checkOrderOfferPrice($filter: MainFilter) {\n    checkOrderOfferPrice(filter: $filter) {\n      averagePrice\n      isOfferExceed\n    }\n  }\n"], ["\n  query checkOrderOfferPrice($filter: MainFilter) {\n    checkOrderOfferPrice(filter: $filter) {\n      averagePrice\n      isOfferExceed\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
