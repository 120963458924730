var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid, LinearProgress, useMediaQuery, useTheme } from '@material-ui/core';
import BaseTooltip from '../../../components/BaseTooltip';
import { useTranslation } from 'react-i18next';
import { FetchEmailNotificationsLogs } from '../../../graphql/logs';
import { KEYS } from '../../../i18n';
import Loader from '../../../components/Loader';
import { TextOverflow } from '../../../components';
import CheckIcon from '@material-ui/icons/Check';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { ContactStatus } from './ItemStatus';
import { AppType } from './DocumentItemVisibleTo';
import { documentInitialValues } from '../../../components/Uploads/utils';
import { inject } from 'mobx-react';
import RemoveIcon from '@material-ui/icons/Remove';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import moment from 'moment';
function EmailStatusV1(_a) {
    var _this = this;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var record = _a.record, _l = _a.store.accountStore, currentUser = _l.currentUser, currentCompany = _l.currentCompany, props = __rest(_a, ["record", "store"]);
    var _m = __read(React.useState(false), 2), loading = _m[0], setLoading = _m[1];
    var _o = __read(React.useState(null), 2), data = _o[0], setData = _o[1];
    var isQuoteOwner = ((_e = (_d = (_c = (_b = props === null || props === void 0 ? void 0 : props.quote) === null || _b === void 0 ? void 0 : _b.createdBy) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d._id) === null || _e === void 0 ? void 0 : _e.toString()) === currentCompany._id.toString();
    var langApp = useTranslation(KEYS.APP);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langForm = useTranslation(KEYS.FORM);
    var theme = useTheme();
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var firstRecord = ((_f = data === null || data === void 0 ? void 0 : data.data) === null || _f === void 0 ? void 0 : _f.length) && data.data[0];
    var date = new Date(firstRecord === null || firstRecord === void 0 ? void 0 : firstRecord.createdAt);
    var isSuccessfullySended = firstRecord && ((_g = data === null || data === void 0 ? void 0 : data.data) === null || _g === void 0 ? void 0 : _g.length) &&
        !((_h = data === null || data === void 0 ? void 0 : data.data) === null || _h === void 0 ? void 0 : _h.find(function (e) {
            return moment(e === null || e === void 0 ? void 0 : e.createdAt).toDate().getTime() >=
                new Date(moment(firstRecord === null || firstRecord === void 0 ? void 0 : firstRecord.createdAt).toDate().getTime() - 10000).getTime() &&
                e.status === ContactStatus.INACTIVE;
        }));
    React.useEffect(function () {
        var loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var filter, users_1, data_1, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        setLoading(true);
                        filter = {
                            fields: [
                                { name: 'uploads', value: record._id },
                                { name: 'withType', value: 1 },
                            ],
                        };
                        users_1 = documentInitialValues(props.quote.order, currentUser, props, true)
                            .map(function (e) { return e.encoded && e.email; })
                            .filter(function (e) { return e; });
                        return [4 /*yield*/, props.config.client.query({
                                query: FetchEmailNotificationsLogs,
                                variables: { filter: filter },
                            })];
                    case 1:
                        data_1 = (_a.sent()).data.logsEmailNotification;
                        data_1.data = data_1.data.map(function (e) {
                            var _a, _b;
                            if (users_1.includes((_b = (_a = e.requestData) === null || _a === void 0 ? void 0 : _a.to[0]) === null || _b === void 0 ? void 0 : _b.email.toLowerCase()))
                                e.requestData.to[0].email = !isQuoteOwner
                                    ? langApp.t('user_app_type_carrier')
                                    : langApp.t('user_app_type_customer');
                            return e;
                        });
                        setData(data_1);
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 4];
                    case 3:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        loadData();
    }, []);
    if ((_j = record === null || record === void 0 ? void 0 : record.isSendingToEmails) === null || _j === void 0 ? void 0 : _j.length) {
        return React.createElement(TextOverflow, null, langForm.t('form_attached_document_sending_emails'));
    }
    if (loading) {
        return React.createElement(LinearProgress, { color: "primary", style: { width: '100%' } });
    }
    if (!(data === null || data === void 0 ? void 0 : data.count)) {
        return React.createElement(TextOverflow, null, '-');
    }
    return (React.createElement(BaseTooltip, { interactive: true, id: 'big-tooltip', title: React.createElement(Grid, { style: __assign(__assign({}, (isSmall ? { width: '100%' } : { width: 340 })), { padding: theme.spacing(2, 0.5) }) }, loading ? (React.createElement(Loader, null)) : (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' }, !(data === null || data === void 0 ? void 0 : data.count) ? (langOrderList.t('order_list_filter_no_data_general')) : (React.createElement(React.Fragment, null, data.data.map(function (e) {
            var _a, _b;
            return (React.createElement(Grid, { container: true, alignItems: 'center', style: { minHeight: 24 }, key: e._id },
                React.createElement(Grid, { item: true, xs: 10, container: true, alignItems: 'center' },
                    React.createElement(AppType, { type: e.receiver ? e.receiver.appType[0] : 'NA', avatarStyle: { width: 24, height: 24 } }),
                    React.createElement(TextOverflow, { color: 'primary', style: { maxWidth: 'calc(100% - 36px)', marginLeft: theme.spacing(1) } }, (_b = (_a = e.requestData) === null || _a === void 0 ? void 0 : _a.to[0]) === null || _b === void 0 ? void 0 : _b.email)),
                React.createElement(Grid, { item: true, xs: 2, container: true, justify: 'flex-end' }, e.status === ContactStatus.ACTIVE ? (React.createElement(CheckIcon, { color: 'primary', style: { minHeight: 24, minWidth: 24 } })) : e.status === ContactStatus.INACTIVE ? (React.createElement(RemoveIcon, { color: 'error', style: { minHeight: 24, minWidth: 24 } })) : (React.createElement(RotateLeftIcon, { color: 'primary', style: { minHeight: 24, minWidth: 24 } })))));
        })))))) },
        React.createElement(Grid, { container: true, alignItems: "center" },
            !isSuccessfullySended ? React.createElement(PriorityHighIcon, { color: 'error' }) : null,
            React.createElement(TextOverflow, { style: __assign({ textDecoration: 'underline', color: theme.palette.primary.main }, (!isSuccessfullySended && { maxWidth: "calc(100% - " + theme.spacing(9) + "px)" })) }, (_k = data === null || data === void 0 ? void 0 : data.data) === null || _k === void 0 ? void 0 : _k.map(function (e) { var _a, _b; return (_b = (_a = e.requestData) === null || _a === void 0 ? void 0 : _a.to[0]) === null || _b === void 0 ? void 0 : _b.email; }).join(', ')))));
}
export default inject('store')(EmailStatusV1);
