import { types } from 'mobx-state-tree';
export var USER_ROLE_TOOLS = {
    GENERAL_isTest: 'isTest',
    GENERAL_can_test_orders: 'can_test_orders',
    GENERAL_can_cancel_orderl: 'can_cancel_order',
    GENERAL_can_join_to_any_company_chat: 'can_join_to_any_company_chat',
    GENERAL_can_assign_roles: 'can_assign_roles',
    GENERAL_can_edit_company_data: 'can_edit_company_data',
    GENERAL_can_edit_company_settings: 'can_edit_company_settings',
    GENERAL_can_edit_company_documents: 'can_edit_company_documents',
    GENERAL_allow_change_dates: 'allow_change_dates',
    GENERAL_team_orders_crud: 'team_orders_crud',
    GENERAL_not_send_sms: 'not_send_sms',
    GENERAL_not_send_email: 'not_send_email',
    GENERAL_can_see_company_date: 'can_see_company_date',
    GENERAL_can_view_offer: 'can_view_offer',
    GENERAL_can_merge_ramps: 'can_merge_ramps',
    GENERAL_can_see_p44_tracking_settings: 'can_see_p44_tracking_settings',
    SETTINGS_can_see_email_templates_settings: 'can_see_email_templates_settings',
    SETTINGS_can_see_push_notifications_settings: 'can_see_push_notifications_settings',
    SETTINGS_can_see_session_settings: 'can_see_session_settings',
    SETTINGS_can_see_general_settings_settings: 'can_see_general_settings_settings',
    ADMIN_payment_term_support: 'admin_payment_term_support',
    ADMIN_issues_support: 'admin_issues_support',
};
export var BaseUserRoleTool = types.model('BaseUserRoleTool', {
    general: types.maybeNull(types.optional(types.array(types.union(types.string)), [])),
    admin: types.maybeNull(types.optional(types.array(types.union(types.string)), [])),
    settings: types.maybeNull(types.optional(types.array(types.union(types.string)), [])),
});
export var BaseUserRoles = types.model('BaseUserRoles', {
    _id: types.maybeNull(types.optional(types.string, '')),
    name: types.maybeNull(types.optional(types.string, '')),
    description: types.maybeNull(types.optional(types.string, '')),
    tools: types.maybeNull(types.optional(BaseUserRoleTool, function () { return BaseUserRoleTool.create(); })),
});
