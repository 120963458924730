var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { orderRoutesList } from './utils';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { OrderRouteType, OrderStatus } from '../../../models';
import OrderProgress from './OrderProgress';
import clsx from 'clsx';
import OrderRoutesItem from './OrderRoutesItem';
import React from 'react';
import { useStyles } from './styles';
import { BaseUserAppTYpe } from '../../../stores';
import { inject } from 'mobx-react';
import PreloadingComponent from '../../PreloadingComponent';
import { onPreventEvent } from '../../Dialog';
var PreparationAssignDate = React.lazy(function () { return import('../form/formsV1/PreparationAssignDate'); });
function OrderRoutesHeader(_a) {
    var _b, _c, _d, _e, _f;
    var onClick = _a.onClick, order = _a.order, canViewAdditional = _a.canViewAdditional, store = _a.store, helpIcons = _a.helpIcons, menuIcon = _a.menuIcon, continueContent = _a.continueContent, v1 = _a.v1, hideActions = _a.hideActions, props = __rest(_a, ["onClick", "order", "canViewAdditional", "store", "helpIcons", "menuIcon", "continueContent", "v1", "hideActions"]);
    var classes = useStyles();
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var isMiddle = useMediaQuery(theme1.breakpoints.down('md'));
    var isLarge = useMediaQuery(theme1.breakpoints.down('lg'));
    var _g = store.accountStore, currentUser = _g.currentUser, currentCompany = _g.currentCompany;
    var _onClick = onClick;
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var isCAAllowed = !isCarrier || !((_b = order.approvedCarrier) === null || _b === void 0 ? void 0 : _b._id) || (isCarrier && currentCompany._id === order.approvedCarrier._id);
    var isOwner = currentCompany._id === ((_d = (_c = order.createdBy) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d._id);
    var isInProgress = [OrderStatus.ApprovedQuote].includes(order.status) || props.inProgressView;
    var isOpenOrders = !!window.location.href.match('open-orders');
    var isOpenOrdersStatus = [OrderStatus.WaitingQuotes, OrderStatus.HasQuotes].includes(order.status);
    var isPreparation = !!window.location.href.match('preparation');
    var isAdmin = window.location.href.match('localhost:3002') || window.location.href.match('admin');
    var routesList = orderRoutesList({ order: order, canViewAdditional: canViewAdditional }).routesList;
    var style = __assign(__assign({}, (!props.v2 && !props.v3 && isSmall ? { maxWidth: '100%' } : { maxWidth: "calc(100% - 60px)" })), { width: '100%', textDecoration: 'none', display: 'flex' });
    if ((props.v6 && isMiddle) || (props.v7 && isLarge)) {
        props.v5 = true;
    }
    var isToDo = (_f = (_e = props === null || props === void 0 ? void 0 : props.defaultFilter) === null || _e === void 0 ? void 0 : _e.fields) === null || _f === void 0 ? void 0 : _f.find(function (e) { return ['withP44Issues', 'to-do-list'].includes(e.name); });
    var isAllowSpaceForPreparationStation = (isOpenOrders || isPreparation) && !isCarrier && !isAdmin && !isToDo;
    return (React.createElement("div", { style: style },
        React.createElement(Grid, { container: true, direction: "row", onClick: _onClick, key: canViewAdditional ? 1 : -1, style: { position: 'relative' } }, routesList.map(function (transportation, mainIndex) {
            var _a;
            var _types = [];
            var items = transportation.routes
                .filter(function (e) {
                if (!e || !e.orderTransportation)
                    return false;
                if (_types.includes(e.type)) {
                    return false;
                }
                _types.push(e.type);
                return true;
            })
                .sort(function (e, b) { return (e.type === OrderRouteType.Preferred ? -1 : 1); });
            var hasLeftIcons = isInProgress && !isAdmin && isCAAllowed;
            var hasLeftIconsPreparingOrder = isOwner && ((_a = order.preparingStatuses) === null || _a === void 0 ? void 0 : _a.length) &&
                [OrderStatus.WaitingQuotes, OrderStatus.HasQuotes].includes(order.status) &&
                !isAdmin &&
                isCAAllowed;
            var helpIconsContent = (helpIcons && (React.createElement(Grid, { container: true, style: __assign({ paddingLeft: 13, marginTop: 3, alignItems: 'center' }, (isOpenOrdersStatus && { paddingLeft: '13%' })), spacing: 1 }, helpIcons))) ||
                null;
            var preparationContent = (React.createElement(Grid, { md: !isMiddle ? 1 : 2, sm: 12, container: true, onClick: onPreventEvent, style: { overflow: 'hidden', minHeight: 64 }, justify: isSmall ? 'center' : 'flex-start' },
                helpIconsContent,
                React.createElement(PreloadingComponent, null,
                    React.createElement(PreparationAssignDate, { v2: true, isProgress: true, config: props.config, order: order, preparingStatuses: order.preparingStatuses || [], disabled: !(order.preparingStatuses && order.preparingStatuses.length) }))));
            var orderStatus = !props.v5 ? (React.createElement(Grid, { md: !isMiddle ? 2 : 3, sm: 12, style: { minHeight: 64 }, container: true, justify: isSmall ? 'center' : 'flex-start' },
                helpIconsContent,
                React.createElement(OrderProgress, { order: order }))) : null;
            return (React.createElement(Grid, { sm: 12, container: true, justify: "flex-start", alignItems: "center", className: classes.item },
                isPreparation
                    ? preparationContent
                    : hasLeftIcons
                        ? orderStatus
                        : isAllowSpaceForPreparationStation
                            ? preparationContent
                            : null,
                items.map(function (route, index) {
                    var _a, _b;
                    return (React.createElement(Grid, { md: props.v5
                            ? 12
                            : (hasLeftIconsPreparingOrder || isInProgress || isAllowSpaceForPreparationStation) && !isAdmin
                                ? index === 0
                                    ? !isMiddle
                                        ? isAllowSpaceForPreparationStation
                                            ? 5
                                            : 4
                                        : isAllowSpaceForPreparationStation
                                            ? 4
                                            : 3
                                    : 6
                                : index === 0
                                    ? 5
                                    : 7, sm: 12, container: true, className: clsx(index === 0 && classes.root, index === 0 && !props.v5 && classes.rootAdditional, index === 0 && !props.v5 && props.v8 && classes.rootAdditionalGrey, index === 0 && props.v5 && classes.rootV1, index === 0 && props.v5 && props.v8 && classes.rootV1Grey, index === 0 && 'route-start-preview', (_a = {},
                            _a[classes.itemExpanded] = canViewAdditional,
                            _a), (_b = {},
                            _b[classes.itemExpandedElement] = index === 0 && canViewAdditional,
                            _b[classes.itemExpandedElementV3] = index === 0 && !isMiddle && props.v3,
                            _b)), justify: "flex-start", alignItems: "center" },
                        React.createElement(OrderRoutesItem, __assign({}, __assign(__assign({}, props), { route: route,
                            index: index,
                            mainIndex: mainIndex,
                            transportation: transportation,
                            continueContent: continueContent,
                            canViewAdditional: canViewAdditional, helpIcons: null, isPreparation: isPreparation,
                            v1: v1,
                            store: store,
                            order: order,
                            hideActions: hideActions,
                            menuIcon: menuIcon })))));
                }),
                !props.v4 && routesList.length === 1 && items.length === 0 && menuIcon && (React.createElement(Grid, { container: true, justify: 'flex-end', alignItems: 'center', style: { minHeight: 56 } },
                    menuIcon,
                    isAdmin && order.status === OrderStatus.Draft && React.createElement("div", { style: { width: 24, height: 22 } })))));
        }))));
}
export default inject('store')(OrderRoutesHeader);
