var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer, useLocalStore, useObserver } from 'mobx-react';
import { toast } from 'react-toastify';
import { Grid, Typography, makeStyles, FormControlLabel, Checkbox, useTheme, useMediaQuery, InputAdornment, IconButton, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import InputTitle from '../../../components/Formik/InputTitle';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikWorkingHour, FormikGoogleTextFieldV1, FormikTextField, BasicDialog, onPreventEvent, parseTime, FormikAutocompleteAsync, TextOverflow, BASIC_DIALOG_ACTIONS, FormikRadioGroupField, MainButton, AddFormButton, } from '../../../components';
import { formUserErrorMessage, onlySpacesRegExp, vatCodeRegExp } from '../../../common';
import { EWarehouse, OrderLocation, OrderLocationAddress, OrderLocationAddressGPS, OrderLocationAdvanced, OrderLocationMode, OrderRouteType, } from '../../../models';
import { getSnapshot } from 'mobx-state-tree';
import { CheckLocationAdvanced, CreateLocation, FetchCompanyWarehousesAddresses, FetchLocationCompanies, FetchLocations, FetchLocationByMember, FetchWarehouses, UpdateLocation, } from '../../../graphql';
import { i18n, KEYS } from '../../../i18n';
import { Warehouse } from '../../../models/Warehouse';
import { theme } from '../../../theme';
import { BaseUserAppTYpe } from '../../../stores/common';
import { checkIsDevEnv, isValidMongoId } from '../../../utils';
import BaseTooltip from '../../BaseTooltip';
import ExpandFormItem from '../../ExpandFormItem';
import LocationDialogAdditional, { LOCATION_DIALOG_EVENTS } from './LocationDialogAdditional';
import LocationMembers, { defaultLocationMemberItem } from './LocationMembers';
import { HelpIconComponent } from '../../HelpIcon';
import { createAppTypeUser } from '../../../utils1';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReportIssueForm from '../../../pages/Issues/FormItem';
import { ISSUE_STATUS, Issues } from '../../../models/Issues';
import { USER_ROLE_TOOLS } from '../../../models/UserRoles';
var useStyles = makeStyles(function (theme) { return ({
    dialog: {},
    title: {
        paddingTop: 0,
        textAlign: 'center',
    },
    button: {
        marginBottom: theme.spacing(1),
    },
    subHeader: {
        padding: theme.spacing(1, 0),
    },
    inputLabel: {
        '& .MuiFormControlLabel-label': {
            maxWidth: 'calc(100% - 40px)',
        },
    },
}); });
var isLocationFormInProgress = false;
export var LocationFormDialog = inject('store')(observer(function (p) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var defaultValue = p.defaultValue, config = p.config, onClose = p.onClose, store = p.store, locationType = p.locationType, props = __rest(p, ["defaultValue", "config", "onClose", "store", "locationType"]);
    var accountStore = store.accountStore;
    var _v = JSON.parse(JSON.stringify(__assign(__assign({}, getSnapshot(OrderLocation.create())), defaultValue)));
    var isPickup = locationType === OrderRouteType.Preferred;
    var lang_key = isPickup ? 'pickup' : 'delivery';
    if (!_v.warehouse)
        _v.warehouse = Warehouse.create({});
    if (!_v.my_warehouses)
        _v.my_warehouses = [];
    if (!_v.warehouses)
        _v.warehouses = [];
    if (!_v.companyAddress)
        _v.companyAddress = {};
    if (!_v.emailV1)
        _v.emailV1 = '';
    if ((_a = _v.email) === null || _a === void 0 ? void 0 : _a.length)
        _v.emailV1 = _v.email[0];
    ['companyVAT', 'name'].forEach(function (key) {
        if (!_v[key])
            _v[key] = '';
    });
    var _o = __read(React.useState(1), 2), updated = _o[0], setUpdated = _o[1];
    var _p = __read(React.useState({}), 2), updatedFields = _p[0], setUpdatedFields = _p[1];
    var _q = __read(React.useState(null), 2), warehouseTemp = _q[0], setWarehouseTemp = _q[1];
    var _r = __read(React.useState(new Set([0])), 2), visitedModes = _r[0], setVisitedModes = _r[1];
    var _s = __read(React.useState(_v), 2), values = _s[0], _setValues = _s[1];
    var setValues = function (_values) {
        _setValues(_values);
    };
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langError = useTranslation(KEYS.ERRORS);
    var langFormLocation = useTranslation(KEYS.FORM_LOCATIONS);
    var langToast = useTranslation(KEYS.TOAST);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var formRef = React.useRef(null);
    var formAdvancedRef = React.useRef(null);
    var formMembersRef = React.useRef(null);
    var formAdditionalMembersRef = React.useRef(null);
    var dialogRef = React.useRef(null);
    var dialogRef1 = React.useRef(null);
    var reportRef = React.useRef(null);
    var reportRef1 = React.useRef(null);
    var reportRef2 = React.useRef(null);
    var dialogRef2 = React.useRef(null);
    var dialogRef3 = React.useRef(null);
    var confirmCompanyWithSameVatCodeDialog = React.useRef(null);
    var _t = __read(React.useState(null), 2), companyWithSameVatCode = _t[0], setCompanyWithSameVatCode = _t[1];
    var _u = __read(React.useState(_v.mode === OrderLocationMode.SIMPLE ||
        (_v.address && JSON.stringify(_v.address) === JSON.stringify(_v.companyAddress))), 2), useCompanyAddress = _u[0], setUseCompanyAddress = _u[1];
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var open = props.open;
    var classes = useStyles();
    var generateLocationName = function (value) {
        return value.companyName + " [" + value.address.country + value.address.postal + "/" + value.warehouse.name + "]";
    };
    var canGenerateLocationName = function (value) {
        var _a, _b, _c;
        return [value.companyName, (_a = value.address) === null || _a === void 0 ? void 0 : _a.country, (_b = value.address) === null || _b === void 0 ? void 0 : _b.postal, (_c = value.warehouse) === null || _c === void 0 ? void 0 : _c.name].filter(function (field) { return field && field !== ''; }).length === 4;
    };
    var localStore = useLocalStore(function () { return ({
        error: '',
        assignCompany: null,
        assignEmail: null,
        emailWarning: '',
        isLoading: false,
        onSubmitV1: function (data) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
            return __awaiter(this, void 0, void 0, function () {
                var _r, _s, _t, isSameRamp, warehouses, validation, item, item, e_1;
                return __generator(this, function (_u) {
                    switch (_u.label) {
                        case 0:
                            _u.trys.push([0, 17, 18, 19]);
                            localStore.assignCompany = localStore.assignEmail = null;
                            _s = data.mode == OrderLocationMode.ADVANCED;
                            if (!_s) return [3 /*break*/, 2];
                            return [4 /*yield*/, formMembersRef.current.submitForm()];
                        case 1:
                            _s = !(_u.sent());
                            _u.label = 2;
                        case 2:
                            _r = (_s);
                            if (_r) return [3 /*break*/, 5];
                            _t = data.mode == OrderLocationMode.ADVANCED;
                            if (!_t) return [3 /*break*/, 4];
                            return [4 /*yield*/, formAdditionalMembersRef.current.submitForm()];
                        case 3:
                            _t = !(_u.sent());
                            _u.label = 4;
                        case 4:
                            _r = (_t);
                            _u.label = 5;
                        case 5:
                            if (_r) {
                                // console.log('formMembersRef', await formMembersRef.current.submitForm());
                                // console.log('formAdditionalMembersRef', await formAdditionalMembersRef.current.submitForm());
                                return [2 /*return*/];
                            }
                            isSameRamp = ((_d = (_c = (_b = (_a = values === null || values === void 0 ? void 0 : values.warehouse) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.address) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.coordinates) &&
                                ((_e = data.address.location) === null || _e === void 0 ? void 0 : _e.coordinates[0]) === ((_j = (_h = (_g = (_f = values === null || values === void 0 ? void 0 : values.warehouse) === null || _f === void 0 ? void 0 : _f.parent) === null || _g === void 0 ? void 0 : _g.address) === null || _h === void 0 ? void 0 : _h.location) === null || _j === void 0 ? void 0 : _j.coordinates[0]) &&
                                ((_k = data.address.location) === null || _k === void 0 ? void 0 : _k.coordinates[1]) === ((_p = (_o = (_m = (_l = values === null || values === void 0 ? void 0 : values.warehouse) === null || _l === void 0 ? void 0 : _l.parent) === null || _m === void 0 ? void 0 : _m.address) === null || _o === void 0 ? void 0 : _o.location) === null || _p === void 0 ? void 0 : _p.coordinates[1]);
                            if (!(data.companyId && isValidMongoId(data.companyId._id) && data.companyId._id && !isSameRamp)) return [3 /*break*/, 13];
                            localStore.isLoading = true;
                            return [4 /*yield*/, store.warehouseStore.load({
                                    limit: 1,
                                    fields: __spread([
                                        { name: 'all', value: true },
                                        { name: 'company', value: data.companyId._id }
                                    ], ((data.address.location &&
                                        data.address.location.coordinates[1] && [
                                        { name: 'lat', value: data.address.location.coordinates[1] },
                                        { name: 'lng', value: data.address.location.coordinates[0] },
                                    ]) ||
                                        []), ['address.area', 'address.country', 'address.postal', 'address.streetName']
                                        .filter(function (name) { return data.address[name.split('.')[1]]; })
                                        .map(function (name) { return ({
                                        name: name,
                                        value: data.address[name.split('.')[1]],
                                    }); })).filter(function (e) { return e; }),
                                })];
                        case 6:
                            warehouses = _u.sent();
                            if (!!((_q = warehouses.data) === null || _q === void 0 ? void 0 : _q.length)) return [3 /*break*/, 8];
                            return [4 /*yield*/, localStore.validateForms()];
                        case 7:
                            validation = _u.sent();
                            if (validation.data === -1) {
                                return [2 /*return*/, BASIC_DIALOG_ACTIONS.APPROVE];
                            }
                            dialogRef1.current.click();
                            return [2 /*return*/, BASIC_DIALOG_ACTIONS.APPROVE];
                        case 8: return [4 /*yield*/, localStore.onApprove()];
                        case 9:
                            item = _u.sent();
                            if (item === BASIC_DIALOG_ACTIONS.APPROVE)
                                return [2 /*return*/, item];
                            if (!item) return [3 /*break*/, 11];
                            return [4 /*yield*/, this.saveAdvanced(item)];
                        case 10:
                            _u.sent();
                            _u.label = 11;
                        case 11: return [2 /*return*/, item];
                        case 12: return [3 /*break*/, 16];
                        case 13:
                            localStore.isLoading = true;
                            return [4 /*yield*/, localStore.onApprove()];
                        case 14:
                            item = _u.sent();
                            if (item === BASIC_DIALOG_ACTIONS.APPROVE)
                                return [2 /*return*/, item];
                            return [4 /*yield*/, this.saveAdvanced(item)];
                        case 15:
                            _u.sent();
                            return [2 /*return*/, item];
                        case 16: return [3 /*break*/, 19];
                        case 17:
                            e_1 = _u.sent();
                            console.log('onSubmitV1:::', e_1);
                            return [3 /*break*/, 19];
                        case 18:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 19: return [2 /*return*/, data];
                    }
                });
            });
        },
        validateEmails: function (isSimple, emails) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (defaultValue._id === '')
                        return [2 /*return*/];
                    localStore.emailWarning = '';
                    return [2 /*return*/];
                });
            });
        },
        saveAdvanced: function (location) {
            return __awaiter(this, void 0, void 0, function () {
                var advancedItem;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!location)
                                return [2 /*return*/];
                            if (!(formAdvancedRef && formAdvancedRef.current)) return [3 /*break*/, 3];
                            return [4 /*yield*/, formAdvancedRef.current.submitForm()];
                        case 1:
                            advancedItem = _a.sent();
                            if (!advancedItem) {
                                throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                            }
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: CheckLocationAdvanced,
                                    variables: {
                                        input: {
                                            location: location._id,
                                            list: advancedItem.advancedEmails.map(function (_a) {
                                                var __typename = _a.__typename, e = __rest(_a, ["__typename"]);
                                                return (__assign(__assign({}, e), (e.documentType && { documentType: e.documentType._id })));
                                            }),
                                        },
                                    },
                                })];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        validateForms: function () {
            return __awaiter(this, void 0, void 0, function () {
                var data, membersForm, _a, membersAdditionalForm, _b, advancedItem;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            data = JSON.parse(JSON.stringify(formRef.current.values));
                            localStore.error = '';
                            delete data.__typename;
                            if (data.address)
                                delete data.address.__typename;
                            if (data.companyAddress)
                                delete data.companyAddress.__typename;
                            _a = data.mode == OrderLocationMode.ADVANCED;
                            if (!_a) return [3 /*break*/, 2];
                            return [4 /*yield*/, formMembersRef.current.submitForm()];
                        case 1:
                            _a = (_c.sent());
                            _c.label = 2;
                        case 2:
                            membersForm = _a;
                            if (data.mode == OrderLocationMode.ADVANCED && !membersForm) {
                                // console.log('no membersForm');
                                return [2 /*return*/, { data: -1 }];
                            }
                            _b = data.mode == OrderLocationMode.ADVANCED;
                            if (!_b) return [3 /*break*/, 4];
                            return [4 /*yield*/, formAdditionalMembersRef.current.submitForm()];
                        case 3:
                            _b = (_c.sent());
                            _c.label = 4;
                        case 4:
                            membersAdditionalForm = _b;
                            if (data.mode == OrderLocationMode.ADVANCED && !membersAdditionalForm) {
                                // console.log('no membersAdditionalForm');
                                return [2 /*return*/, { data: -1 }];
                            }
                            return [4 /*yield*/, formAdvancedRef.current.submitForm()];
                        case 5:
                            advancedItem = _c.sent();
                            if (!advancedItem) {
                                // console.log('missing advancedItem', formAdvancedRef.current, advancedItem);
                                return [2 /*return*/, { data: -1 }];
                            }
                            return [2 /*return*/, {
                                    data: data,
                                    membersForm: membersForm,
                                    membersAdditionalForm: membersAdditionalForm,
                                    advancedItem: advancedItem,
                                }];
                    }
                });
            });
        },
        onApprove: function () {
            var _a, _b, _c, _d, _e, _f;
            return __awaiter(this, void 0, void 0, function () {
                var input, _g, data, membersForm, membersAdditionalForm, _h, additionalName, address, addresses, addressesKeys, _loop_1, state_1, result_1, data_1, data_2, e_2, error, details, details1;
                return __generator(this, function (_j) {
                    switch (_j.label) {
                        case 0:
                            _j.trys.push([0, 7, 8, 9]);
                            localStore.assignCompany = localStore.assignEmail = null;
                            return [4 /*yield*/, this.validateForms()];
                        case 1:
                            _g = _j.sent(), data = _g.data, membersForm = _g.membersForm, membersAdditionalForm = _g.membersAdditionalForm;
                            if (data === -1) {
                                // console.log('validateForms');
                                return [2 /*return*/];
                            }
                            _h = data.address, additionalName = _h.additionalName, address = __rest(_h, ["additionalName"]);
                            input = Object.assign(__assign({ name: data.name, email: ((data.mode == OrderLocationMode.SIMPLE && data.emailV1 && [data.emailV1]) ||
                                    data.email ||
                                    []).filter(function (e) { return e; }), address: address }, ((data.mode && { mode: data.mode }) || {})), __assign({
                                warehouse: ((_a = data.warehouseTemp) === null || _a === void 0 ? void 0 : _a._id) ||
                                    (typeof data.warehouseTemp === 'string' && data.warehouseTemp) || ((_b = data.warehouse) === null || _b === void 0 ? void 0 : _b._id) ||
                                    null,
                            }), (data.mode == OrderLocationMode.ADVANCED && __assign(__assign(__assign({ companyId: (data.companyId && isValidMongoId(data.companyId._id) && data.companyId._id) || null }, (((_c = values.company) === null || _c === void 0 ? void 0 : _c._id) && { company: values.company._id })), { companyName: data.companyName, companyVAT: ((_d = data.companyVAT) === null || _d === void 0 ? void 0 : _d._id) || data.companyVAT, companyAddress: data.companyAddress, additionalMembers: membersAdditionalForm.members, members: membersForm.members, startTime: data.startTime, endTime: data.endTime, warehouses: data.warehouses.map(function (e) { return e._id; }), my_warehouses: data.my_warehouses.map(function (e) { return e._id; }) }), (data.timeToSpend && { timeToSpend: data.timeToSpend }))) ||
                                (data.mode == OrderLocationMode.SIMPLE &&
                                    data.warehouse &&
                                    data.warehouse._id &&
                                    data.warehouse.createdBy && __assign({ companyId: data.warehouse.createdBy.company._id })) ||
                                {}, data._id ? { _id: data._id } : null);
                            addresses = input.mode === OrderLocationMode.SIMPLE ? ['address'] : ['address', 'companyAddress'];
                            addressesKeys = ['company', 'place_id', 'city'];
                            _loop_1 = function () {
                                var key = addresses.shift();
                                var item = input[key];
                                if (!item || !Object.keys(item).filter(function (key1) { return item[key1]; }).length) {
                                    localStore.error = langForm.t('form_input_validate_required_address');
                                    return { value: void 0 };
                                }
                                for (var i = 0; i < addressesKeys.length; i++) {
                                    if (!item[addressesKeys[i]])
                                        delete item[addressesKeys[i]];
                                }
                                if (item.location) {
                                    delete item.location.__typename;
                                    // item.location.__typename = 'LocationAddressGPSInput';
                                }
                            };
                            while (addresses === null || addresses === void 0 ? void 0 : addresses.length) {
                                state_1 = _loop_1();
                                if (typeof state_1 === "object")
                                    return [2 /*return*/, state_1.value];
                            }
                            delete input.company;
                            localStore.isLoading = true;
                            return [4 /*yield*/, checkIsDevEnv()];
                        case 2:
                            _j.sent();
                            if (!config) return [3 /*break*/, 6];
                            if (!input._id) return [3 /*break*/, 4];
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: UpdateLocation,
                                    variables: {
                                        input: input,
                                    },
                                })];
                        case 3:
                            result_1 = _j.sent();
                            result_1 = result_1.data.updateLocation;
                            try {
                                data_1 = config.client.readQuery({ query: FetchLocations });
                                if (data_1 && data_1.locations)
                                    config.client.writeQuery({
                                        //Update Apollo Cache
                                        query: FetchLocations,
                                        data: {
                                            locations: {
                                                count: data_1.locations.count,
                                                data: data_1.locations.data.map(function (e) {
                                                    if (e._id === result_1._id) {
                                                        return result_1;
                                                    }
                                                    else {
                                                        return e;
                                                    }
                                                }),
                                            },
                                        },
                                    });
                            }
                            catch (e) {
                                console.log('===', e);
                            }
                            return [3 /*break*/, 6];
                        case 4: return [4 /*yield*/, config.client.mutate({
                                mutation: CreateLocation,
                                variables: {
                                    input: input,
                                },
                            })];
                        case 5:
                            result_1 = _j.sent();
                            result_1 = result_1.data.createLocation;
                            try {
                                data_2 = config.client.readQuery({ query: FetchLocations });
                                if (data_2 && data_2.locations)
                                    config.client.writeQuery({
                                        //Update Apollo Cache
                                        query: FetchLocations,
                                        data: {
                                            locations: {
                                                count: data_2.locations.count + 1,
                                                data: __spread([result_1], data_2.locations.data),
                                            },
                                        },
                                    });
                            }
                            catch (e) {
                                console.log('====--', e);
                            }
                            _j.label = 6;
                        case 6:
                            toast.success(langToast.t('toast_items_saved').replace(/_X_/, langOrderForm.t('create_order_route_location_label')));
                            if (data.warehouse._id) {
                                result_1.warehouse._id = data.warehouse._id;
                                result_1.warehouse.emails = data.warehouse.emails;
                                result_1.warehouse.name = data.warehouse.name;
                            }
                            return [2 /*return*/, result_1];
                        case 7:
                            e_2 = _j.sent();
                            error = formUserErrorMessage(e_2) || '';
                            details = error === null || error === void 0 ? void 0 : error.split('type:');
                            if (error.match(/type:/) &&
                                0 /* &&
                            !error.match(
                              new RegExp([BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].join('|'))
                            ) */) {
                                localStore.error = details[0];
                                toast.error(e_2.message || e_2);
                            }
                            else if ((details === null || details === void 0 ? void 0 : details.length) > 0 && details.find(function (e) { return e.match(/__/); })) {
                                details1 = details[1].split(';')[0].split('__');
                                localStore.assignCompany = __assign({ type: details1[0] }, details1
                                    .map(function (label) {
                                    var _a;
                                    var k = label.split(':');
                                    return _a = {}, _a[k[0]] = k[1], _a;
                                })
                                    .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                                localStore.assignEmail = details[0].split('.')[1].trim();
                                if (input._id) {
                                    if ((_e = dialogRef3 === null || dialogRef3 === void 0 ? void 0 : dialogRef3.current) === null || _e === void 0 ? void 0 : _e.click)
                                        dialogRef3.current.click();
                                }
                                else {
                                    if ((_f = dialogRef2 === null || dialogRef2 === void 0 ? void 0 : dialogRef2.current) === null || _f === void 0 ? void 0 : _f.click)
                                        dialogRef2.current.click();
                                }
                                return [2 /*return*/, BASIC_DIALOG_ACTIONS.APPROVE];
                            }
                            else if (e_2) {
                                localStore.error = error;
                                toast.error(e_2.message || e_2);
                                return [2 /*return*/, BASIC_DIALOG_ACTIONS.APPROVE];
                            }
                            console.log('error==', e_2, error);
                            return [3 /*break*/, 9];
                        case 8:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 9: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    React.useEffect(function () {
        // if (!values._id) values.address = { country: '' };
        ['startTime', 'endTime'].forEach(function (e, index) {
            if (!values[e])
                values[e] = index === 0 ? '08:00' : '20:00';
            values[e] = parseTime(values[e]);
        });
        _setValues(JSON.parse(JSON.stringify(values)));
    }, [defaultValue]);
    React.useEffect(function () {
        if (dialogRef && dialogRef.current && open) {
            dialogRef.current.click();
        }
        return function () {
            onReset(new Event('click'));
        };
    }, [dialogRef, open]);
    React.useEffect(function () {
        return function () {
            isLocationFormInProgress = false;
        };
    }, []);
    React.useEffect(function () {
        if (!visitedModes.has(values.mode)) {
            setVisitedModes(new Set(__spread(Array.from(visitedModes), [values.mode])));
            var v = __assign(__assign({}, values), { updatedAt: Date.now() });
            _setValues(v);
        }
    }, [values.mode]);
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (createdItem === BASIC_DIALOG_ACTIONS.APPROVE)
                        throw createdItem;
                    if (!createdItem) {
                        throw Error([
                            i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'),
                            formRef.current.errors &&
                                Object.keys(formRef.current.errors)
                                    .map(function (e) { return e + "-" + formRef.current.errors[e]; })
                                    .join(', '),
                        ]
                            .filter(function (e) { return e; })
                            .join('. '));
                    }
                    if (props.onChange) {
                        props.onChange(e, createdItem);
                    }
                    else if (props.onClose)
                        props.onClose(e, createdItem);
                    onReset(e);
                    return [2 /*return*/];
            }
        });
    }); };
    var onOk1 = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    onPreventEvent(e);
                    return [4 /*yield*/, localStore.onApprove()];
                case 1:
                    createdItem = _a.sent();
                    if (createdItem === BASIC_DIALOG_ACTIONS.APPROVE)
                        throw BASIC_DIALOG_ACTIONS.WAIT;
                    return [4 /*yield*/, localStore.saveAdvanced(createdItem)];
                case 2:
                    _a.sent();
                    if (props.onChange) {
                        props.onChange(e, createdItem);
                    }
                    else if (props.onClose)
                        props.onClose(e, createdItem);
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    console.log(e_3);
                    throw BASIC_DIALOG_ACTIONS.WAIT;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onOk3 = function (e) {
        if (reportRef1 === null || reportRef1 === void 0 ? void 0 : reportRef1.current)
            reportRef1.current.click();
        return onPreventEvent(e);
    };
    var onOk3_1 = function (e) {
        if (reportRef2 === null || reportRef2 === void 0 ? void 0 : reportRef2.current)
            reportRef2.current.click();
        return onPreventEvent(e);
    };
    var onOk2 = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var res, firstUser, updates_1, e_4;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    onPreventEvent(e);
                    return [4 /*yield*/, config.client.query({
                            //Update Apollo Cache
                            query: FetchLocationByMember,
                            variables: {
                                filter: {
                                    fields: [
                                        { name: 'emails', value: formMembersRef.current.values.members.map(function (e) { return e.email; }) },
                                        { name: 'company', value: (_a = localStore.assignCompany) === null || _a === void 0 ? void 0 : _a._id },
                                    ],
                                },
                            },
                        })];
                case 1:
                    res = (_b.sent()).data.loadLocationByMembers.data;
                    if (!res)
                        throw 'No data';
                    localStore.clearError();
                    firstUser = res[0];
                    updates_1 = {
                        address: JSON.parse(JSON.stringify(getSnapshot(OrderLocationAddress.create(firstUser.company.addressV1)))),
                        companyAddress: JSON.parse(JSON.stringify(getSnapshot(OrderLocationAddress.create(firstUser.company.addressV1)))),
                        warehouses: [],
                        warehouse: firstUser.warehouses[0],
                        companyName: firstUser.company.name,
                        companyVAT: firstUser.company.vatCode,
                        companyId: firstUser.company,
                        members: res.map(function (e) { return (__assign(__assign({}, defaultLocationMemberItem()), { email: e.email, phone: e.phone, name: [e.firstName, e.lastName].filter(function (e) { return e; }).join(' ') })); }),
                    };
                    Object.keys(updates_1).forEach(function (key) {
                        formRef.current.setFieldValue(key, updates_1[key]);
                    });
                    setValues(__assign(__assign({}, values), updates_1));
                    return [3 /*break*/, 3];
                case 2:
                    e_4 = _b.sent();
                    console.log(e_4);
                    toast.error(e_4.message || e_4);
                    throw BASIC_DIALOG_ACTIONS.WAIT;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onReset = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var _v_1, values_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            onPreventEvent(e);
            try {
                _v_1 = __assign(__assign(__assign({}, getSnapshot(OrderLocation.create())), defaultValue), { emailV1: '' });
                values_1 = _v_1;
                if ((_a = _v_1.email) === null || _a === void 0 ? void 0 : _a.length)
                    _v_1.emailV1 = _v_1.email[0];
                if (!_v_1.warehouse)
                    _v_1.wasrehouse = getSnapshot(Warehouse.create({}));
                if (!_v_1.companyAddress)
                    _v_1.companyAddress = {};
                if (!values_1._id)
                    values_1.address = { country: '' };
                if ((_b = values_1.companyId) === null || _b === void 0 ? void 0 : _b._id) {
                    [
                        {
                            key: 'name',
                            from: canGenerateLocationName(values_1) && generateLocationName(values_1),
                        },
                        { key: 'companyName', from: values_1.companyId.name },
                        { key: 'companyVAT', from: values_1.companyId.vatCode },
                        { key: 'companyAddress', from: JSON.parse(JSON.stringify(values_1.companyId.addressV1)) },
                    ].forEach(function (_a) {
                        var key = _a.key, from = _a.from;
                        if (!values_1[key] && from) {
                            values_1[key] = from;
                        }
                    });
                    if (!((_c = values_1.companyAddress) === null || _c === void 0 ? void 0 : _c.country)) {
                        values_1.companyAddress = JSON.parse(JSON.stringify(values_1.companyId.addressV1));
                    }
                    if (((_d = values_1.email) === null || _d === void 0 ? void 0 : _d.length) && !values_1.members.length) {
                        if (!values_1.members.length) {
                            values_1.members.push(defaultLocationMemberItem());
                        }
                        if (!values_1.members[0].email)
                            values_1.members[0].email = values_1.email[0];
                    }
                }
                [{ key: 'name' }, { key: 'companyName' }, { key: 'companyVAT' }].forEach(function (_a) {
                    var key = _a.key, from = _a.from;
                    if (!values_1[key]) {
                        values_1[key] = '';
                    }
                });
                ['startTime', 'endTime'].forEach(function (e, index) {
                    if (!values_1[e])
                        values_1[e] = index === 0 ? '08:00' : '20:00';
                    values_1[e] = parseTime(values_1[e]);
                });
                if (!values_1.timeToSpend)
                    values_1.timeToSpend = '';
                _setValues(JSON.parse(JSON.stringify(_v_1)));
                setUpdated(Date.now());
            }
            catch (e) {
                console.log('---ERRR', e);
            }
            return [2 /*return*/];
        });
    }); };
    var visibleToName = Object.values(BaseUserAppTYpe)
        .filter(function (e) {
        return ![
            BaseUserAppTYpe.WAREHOUSE,
            BaseUserAppTYpe.CONSIGNEE,
            BaseUserAppTYpe.SYSTEM,
            BaseUserAppTYpe.FORWARDER,
            BaseUserAppTYpe.AFFILIATE,
        ].includes(e);
    })
        .map(function (e) {
        var appUser = createAppTypeUser(e);
        return (appUser.firstName + appUser.lastName).toUpperCase();
    });
    var showReportIssue = function (e) {
        if (reportRef === null || reportRef === void 0 ? void 0 : reportRef.current)
            reportRef.current.click();
        return onPreventEvent(e);
    };
    var documentAccessHeader = (React.createElement(React.Fragment, null, visibleToName.map(function (subName) { return (React.createElement(TextOverflow, { variant: 'subtitle1', style: {
            fontWeight: 700,
            paddingLeft: theme.spacing(4),
        } }, subName)); })));
    var validateSchemaObject = {
        address: Yup.object({
            country: Yup.string().typeError(langForm.t('form_input_invalid_address')),
        })
            .test('country', langForm.t('form_input_validate_required'), function (val) {
            return !!val.country;
        })
            .typeError(langForm.t('form_input_invalid_address')),
    };
    if (values.mode === OrderLocationMode.SIMPLE) {
        Object.assign(validateSchemaObject, {
            emailV1: Yup.string()
                .typeError(langForm.t('form_input_validate_required'))
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                .email(langForm.t('form_input_validate_invalid_email'))
                .required(langForm.t('form_input_validate_required')),
        });
    }
    else if (values.mode === OrderLocationMode.ADVANCED) {
        Object.assign(validateSchemaObject, __assign({}, (((!values.warehouse || !values.warehouse._id || 1) && {
            name: Yup.string()
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .max(100, langForm.t('form_input_validate_max_char').replace(/_X_/, '100')),
            companyName: Yup.string()
                .max(100, langForm.t('form_input_validate_max_char').replace(/_X_/, '100'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .required(langForm.t('form_input_validate_required')),
            companyVAT: Yup.string()
                .max(200, langForm.t('form_input_validate_max_char').replace(/_X_/g, '200'))
                .matches(vatCodeRegExp, langForm.t('form_input_invalid_vat_code'))
                .required(langForm.t('form_input_validate_required')),
            companyAddress: Yup.object({
                country: Yup.string(),
            })
                .test('country', langForm.t('form_input_validate_required'), function (val) {
                return !!val.country;
            })
                .typeError(langForm.t('form_input_invalid_address')),
            startTime: Yup.string()
                .test('start', function () { var _a, _b; return langForm.t('form_input_validate_max_size').replace(/_X_/, ((_b = (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b.end) || ''); }, function (value) {
                var _a = this.parent, startTime = _a.startTime, endTime = _a.endTime;
                return (formRef.current &&
                    parseInt((startTime || '').replace(':', '')) < parseInt((endTime || '').replace(':', '')));
            })
                .required(langForm.t('form_input_validate_required')),
            endTime: Yup.string()
                .test('end', function () { var _a, _b; return langForm.t('form_input_validate_min_size').replace(/_X_/, ((_b = (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b.startTime) || ''); }, function (value) {
                var _a = this.parent, startTime = _a.startTime, endTime = _a.endTime;
                return (formRef.current &&
                    parseInt((startTime || '').replace(':', '')) < parseInt((endTime || '').replace(':', '')));
            })
                .required(langForm.t('form_input_validate_required')),
        }) ||
            {})));
    }
    var validationSchema = Yup.object(validateSchemaObject);
    var generalProps = { style: { marginBottom: theme.spacing(3) } };
    var theme1 = useTheme();
    var isMobile = useMediaQuery(theme1.breakpoints.down('sm'));
    var canEditV1 = (_b = store.accountStore.currentUser.rolesV1) === null || _b === void 0 ? void 0 : _b.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data); });
    var disabledEdit = !(!((_c = values.company) === null || _c === void 0 ? void 0 : _c._id) ||
        (((_d = values.company) === null || _d === void 0 ? void 0 : _d._id) === store.accountStore.currentCompany._id && canEditV1) ||
        !((_e = values.createdBy) === null || _e === void 0 ? void 0 : _e._id) ||
        ((_f = values.createdBy) === null || _f === void 0 ? void 0 : _f._id) === store.accountStore.currentUser._id);
    var dialogBody = (React.createElement(Grid, { container: true, key: "" + [updated, values._id].join('-') },
        (localStore.error && (React.createElement(TextOverflow, { color: "error", style: { textAlign: 'center', width: '100%' } }, localStore.error))) ||
            null,
        React.createElement(Formik, { key: ((_g = values.warehouse) === null || _g === void 0 ? void 0 : _g._id) + " " + (useCompanyAddress ? 1 : -1) + " " + values.updatedAt + " " + values.mode, innerRef: formRef, initialValues: values, validationSchema: validationSchema, onSubmit: localStore.onSubmitV1 }, function (formik) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            var onChangeInput = function () {
                var address = JSON.parse(JSON.stringify(!useCompanyAddress ? formik.values.companyAddress : getSnapshot(OrderLocationAddress.create())));
                setUseCompanyAddress(!useCompanyAddress);
                setValues(__assign(__assign({}, values), { address: address }));
            };
            var onSelectAddress = function (newVal, address) {
                var company = newVal.companyId;
                if (!company || !address || !company.createdAt) {
                    setValues(__assign(__assign(__assign({}, values), newVal), { updatedAt: Date.now() }));
                    return;
                }
                store.warehouseStore
                    .load({
                    limit: 1,
                    fields: __spread([
                        { name: 'all', value: true },
                        { name: 'company', value: company._id }
                    ], ((address.location &&
                        address.location.coordinates[1] && [
                        { name: 'lat', value: address.location.coordinates[1] },
                        { name: 'lng', value: address.location.coordinates[0] },
                    ]) ||
                        []), ['address.area', 'address.country', 'address.postal', 'address.streetName']
                        .filter(function (name) { return address[name.split('.')[1]]; })
                        .map(function (name) { return ({
                        name: name,
                        value: address[name.split('.')[1]],
                    }); })),
                })
                    .then(function (res) {
                    var _a;
                    var warehouse = __assign(__assign({}, getSnapshot(EWarehouse.create())), (((_a = res.data) === null || _a === void 0 ? void 0 : _a.length) && __assign(__assign({}, res.data[0].ramps[0]), { parent: res.data[0] })));
                    var updates = {
                        warehouseTemp: EWarehouse.create(),
                        warehouse: EWarehouse.create(),
                        warehouses: [],
                    };
                    if (warehouse) {
                        updates = {
                            warehouse: warehouse,
                        };
                    }
                    var v = __assign(__assign(__assign(__assign({}, values), newVal), updates), { updatedAt: Date.now() });
                    v.addressV1 = JSON.parse(JSON.stringify(getSnapshot(OrderLocationAddress.create(v.addressV1))));
                    v.companyAddress = JSON.parse(JSON.stringify(getSnapshot(OrderLocationAddress.create(v.companyAddress))));
                    setValues(v);
                    Object.keys(v).forEach(function (key) {
                        formik.setFieldValue(key, v[key]);
                    });
                })
                    .catch(function (e) {
                    var v = __assign(__assign(__assign({ warehouseTemp: EWarehouse.create(), warehouse: EWarehouse.create(), warehouses: [] }, values), newVal), { updatedAt: Date.now() });
                    setValues(v);
                    Object.keys(v).forEach(function (key) {
                        formik.setFieldValue(key, v[key]);
                    });
                });
            };
            var onFieldChangeHandlerAddress = function (_a) {
                var updates = _a.updates, __value = _a.__value, _value = _a._value;
                return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_b) {
                        return [2 /*return*/, store.accountStore.currentCompany
                                .locationGeoCode({
                                fields: [{ name: 'name', value: (__value === null || __value === void 0 ? void 0 : __value.name) || (__value === null || __value === void 0 ? void 0 : __value.address) || _value.name || _value.address }],
                            })
                                .then(function (_address) {
                                updates.address = JSON.parse(JSON.stringify(getSnapshot(OrderLocationAddress.create(_address))));
                                Object.keys(updates).forEach(function (key) {
                                    formik.setFieldValue(key, updates[key]);
                                });
                                var newVal = __assign(__assign(__assign({}, values), updates), { updatedAt: Date.now() });
                                onSelectAddress(newVal, updates.address);
                                isLocationFormInProgress = false;
                            })];
                    });
                });
            };
            var onFieldChangeHandler = function (e, __value, args) { return __awaiter(void 0, void 0, void 0, function () {
                var value, name_1, companyId_1, isAbleToClear, isCompanyVatInputed, _value, updates_2, ignoreUpdates, addressFields, newVal, _name, e_5;
                var _a, _b, _c;
                var _d, _e, _f;
                return __generator(this, function (_g) {
                    switch (_g.label) {
                        case 0:
                            _g.trys.push([0, 6, 7, 8]);
                            value = e.target.value;
                            name_1 = e.target.name;
                            companyId_1 = (_d = formik.values.companyId) === null || _d === void 0 ? void 0 : _d._id;
                            // if (values[name] === value) {
                            //   console.warn('No Changes:', name);
                            //   return;
                            // }
                            // console.log('address updates', name, value);
                            if (name_1 === 'warehouse' && value === ((_e = formik.values.warehouse) === null || _e === void 0 ? void 0 : _e._id)) {
                                console.warn('No Changes:', name_1);
                                return [2 /*return*/];
                            }
                            if (isLocationFormInProgress) {
                                console.warn('isLocationFormInProgress', name_1);
                                return [2 /*return*/];
                            }
                            isLocationFormInProgress = true;
                            if (name_1 === 'companyVAT') {
                                accountStore.currentCompany
                                    .checkVatCodeExists((value === null || value === void 0 ? void 0 : value.vatCode) || (value === null || value === void 0 ? void 0 : value._id) || value, [
                                    {
                                        name: 'type',
                                        value: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
                                    },
                                ])
                                    .then(function (_companyWithSameVatCode) {
                                    var _a;
                                    if (_companyWithSameVatCode) {
                                        setCompanyWithSameVatCode(_companyWithSameVatCode);
                                        (_a = confirmCompanyWithSameVatCodeDialog.current) === null || _a === void 0 ? void 0 : _a.click();
                                        isLocationFormInProgress = false;
                                        return;
                                    }
                                });
                            }
                            isAbleToClear = ['companyName'].includes(name_1);
                            isCompanyVatInputed = false;
                            _value = ((__value === null || __value === void 0 ? void 0 : __value._id) && __value) ||
                                (['address', 'warehouse'].includes(name_1) && value) ||
                                EWarehouse.create();
                            updates_2 = {};
                            localStore.clearError();
                            formik.handleChange(e);
                            if (isAbleToClear && ((__value === null || __value === void 0 ? void 0 : __value.isManual) || !__value)) {
                                console.log('no changes', name_1, value, __value);
                                return [2 /*return*/];
                            }
                            if (['companyId'].includes(name_1) || isCompanyVatInputed || isAbleToClear) {
                                updates_2.members = [];
                                updates_2.additionalMembers = [];
                                updates_2.locationAdvanced = [JSON.parse(JSON.stringify(getSnapshot(OrderLocationAdvanced.create())))];
                                // updates.locationAdvanced = [];
                            }
                            if ((args === null || args === void 0 ? void 0 : args.length) && ((_f = args[2]) === null || _f === void 0 ? void 0 : _f.isManual)) {
                                if (name_1 === 'address') {
                                    formik.setFieldValue(name_1, _value);
                                    setUseCompanyAddress(false);
                                    setValues(__assign(__assign({}, values), (_a = {}, _a[name_1] = _value, _a.updatedAt = Date.now(), _a)));
                                    return [2 /*return*/];
                                }
                            }
                            else if (['warehouse', 'address', 'companyId', 'companyName', 'companyAddress'].includes(name_1)) {
                                updates_2.warehouses = [];
                                updates_2.warehouseTemp = EWarehouse.create();
                                updates_2.warehouse = EWarehouse.create();
                                values.warehouses = [];
                                values.warehouse = EWarehouse.create();
                                values.warehouseTemp = EWarehouse.create();
                            }
                            if (isAbleToClear || isCompanyVatInputed) {
                                if ((__value === null || __value === void 0 ? void 0 : __value.createdAt) && ((_value.place_id && !companyId_1) || !_value.place_id)) {
                                    updates_2.warehouse = EWarehouse.create();
                                    updates_2.warehouseTemp = EWarehouse.create();
                                    updates_2.warehouses = [];
                                }
                            }
                            else if ('address' === name_1) {
                                ignoreUpdates = 0;
                                if (_value.place_id) {
                                    updates_2.warehouseTemp = EWarehouse.create();
                                    updates_2.warehouse = EWarehouse.create();
                                    updates_2.warehouses = [];
                                    return [2 /*return*/, onFieldChangeHandlerAddress({ updates: updates_2, __value: __value, _value: _value })];
                                }
                                else if (_value.__typename === 'LocationAddress') {
                                    updates_2.address = _value;
                                    value = updates_2.address;
                                    Object.keys(updates_2).forEach(function (key) {
                                        formik.setFieldValue(key, updates_2[key]);
                                    });
                                    ignoreUpdates = 1;
                                }
                                else {
                                    updates_2.warehouse = _value;
                                }
                                if (!ignoreUpdates) {
                                    updates_2.address = _value.address;
                                    value = updates_2.address;
                                    Object.keys(updates_2).forEach(function (key) {
                                        formik.setFieldValue(key, updates_2[key]);
                                    });
                                }
                                // console.log('address updates', updates, _value, value);
                            }
                            addressFields = ['postal', 'country', 'city', 'streetName'];
                            if (!(name_1 === 'companyName' || isCompanyVatInputed)) return [3 /*break*/, 4];
                            if (!(__value === null || __value === void 0 ? void 0 : __value.createdAt)) {
                                values.companyId = { _id: '', addressV1: {} };
                                if (isCompanyVatInputed) {
                                    values.companyName = '';
                                }
                                else {
                                    values.companyVAT = '';
                                }
                                values.companyAddress = {};
                                values.address = {};
                                values.members = [];
                                values.additionalMembers = [];
                                values.updatedAt = Date.now();
                                Object.keys(values).forEach(function (key) {
                                    formik.setFieldValue(key, values[key]);
                                });
                                setValues(__assign(__assign({}, values), (_b = {}, _b[name_1] = value, _b)));
                                isLocationFormInProgress = false;
                                // return;
                                if (!__value.place_id) {
                                    return [2 /*return*/];
                                }
                            }
                            updates_2.address = JSON.parse(JSON.stringify(getSnapshot(OrderLocationAddress.create())));
                            if (!__value.place_id) return [3 /*break*/, 1];
                            if (!companyId_1) {
                                updates_2.companyVAT = '';
                                formik.setFieldValue('companyVAT', '');
                                formik.setFieldValue('address', {});
                            }
                            return [2 /*return*/, store.accountStore.currentCompany
                                    .locationGeoCode({ fields: [{ name: 'name', value: __value.name }] })
                                    .then(function (_address) {
                                    var address = JSON.parse(JSON.stringify(getSnapshot(OrderLocationAddress.create(_address))));
                                    var companyName = __value.name.split(',')[0];
                                    if (companyName) {
                                        updates_2.companyName = companyName;
                                    }
                                    updates_2.companyAddress = address;
                                    if (useCompanyAddress) {
                                        updates_2.address = address;
                                    }
                                    if (!companyId_1)
                                        updates_2.companyId = { _id: '', addressV1: address };
                                    Object.keys(updates_2).forEach(function (key) {
                                        formik.setFieldValue(key, updates_2[key]);
                                    });
                                    var newVal = __assign(__assign(__assign({}, values), updates_2), { updatedAt: Date.now() });
                                    setValues(newVal);
                                    if (useCompanyAddress) {
                                        onSelectAddress(updates_2, updates_2.address);
                                    }
                                    isLocationFormInProgress = false;
                                })];
                        case 1:
                            if (!__value.addressV1) {
                                __value.addressV1 = JSON.parse(JSON.stringify(getSnapshot(OrderLocationAddress.create())));
                            }
                            else {
                                if (!__value.addressV1.location)
                                    __value.addressV1.location = JSON.parse(JSON.stringify(getSnapshot(OrderLocationAddressGPS.create())));
                                addressFields.forEach(function (key) {
                                    if (!__value.addressV1[key])
                                        __value.addressV1[key] = '';
                                });
                            }
                            if (name_1 === 'companyName') {
                                updates_2.companyVAT = __value.vatCode;
                            }
                            else {
                                updates_2.companyName = __value.name;
                            }
                            updates_2.companyAddress = __value.addressV1;
                            Object.keys(updates_2).forEach(function (key) {
                                formik.setFieldValue(key, updates_2[key]);
                            });
                            updates_2.updatedAt = Date.now();
                            updates_2.companyId = __value || { _id: '', addressV1: {} };
                            if (!useCompanyAddress) return [3 /*break*/, 3];
                            updates_2.address = __value.addressV1;
                            return [4 /*yield*/, onSelectAddress(updates_2, updates_2.address)];
                        case 2:
                            _g.sent();
                            _g.label = 3;
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            if (name_1 === 'companyAddress' && useCompanyAddress) {
                                updates_2.companyAddress = JSON.parse(JSON.stringify(value));
                                updates_2.address = JSON.parse(JSON.stringify(value));
                                updates_2.updatedAt = Date.now();
                                formik.setFieldValue('address', updates_2.address);
                            }
                            else if (name_1 === 'phone' && Array.isArray(value)) {
                                updates_2.phone = value.filter(function (e) { return e; }).map(function (e) { return e.trim(); });
                                formik.setFieldValue('phone', updates_2.phone);
                            }
                            _g.label = 5;
                        case 5:
                            newVal = __assign(__assign(__assign({}, values), updates_2), (_c = {}, _c[name_1] = (name_1 === 'warehouse' && (_value || getSnapshot(EWarehouse.create()))) ||
                                (value && value.trim && value.trim()) ||
                                value, _c));
                            if (name_1 === 'warehouseTemp') {
                                setWarehouseTemp(__value);
                            }
                            if (formik.values.mode === OrderLocationMode.ADVANCED &&
                                (!newVal['name'] || newVal['name'] === '') &&
                                canGenerateLocationName(newVal) &&
                                name_1 !== 'name') {
                                _name = generateLocationName(__assign(__assign({}, newVal), (rampKey === 'warehouseTemp' && { warehouse: __value })));
                                newVal['name'] = _name;
                                formik.setFieldValue('name', _name);
                            }
                            if (name_1 === 'address') {
                                setUseCompanyAddress(false);
                                onSelectAddress(newVal, updates_2.address || newVal.address);
                            }
                            else {
                                setValues(newVal);
                            }
                            if (e.target.name === 'email' && Array.isArray(e.target.value)) {
                                localStore.validateEmails(isSimple, e.target.value);
                            }
                            if (name_1 === 'companyName' || isCompanyVatInputed) {
                                if (useCompanyAddress) {
                                    onSelectAddress(newVal, newVal.address);
                                }
                            }
                            if (name_1 === 'companyAddress' && useCompanyAddress) {
                                onSelectAddress(newVal, updates_2.address || newVal.address);
                            }
                            if (typeof newVal.locationAdvanced !== undefined &&
                                (newVal.locationAdvanced.map(function (e) { return e.email; }).join('') !==
                                    values.locationAdvanced.map(function (e) { return e.email; }).join('') ||
                                    (!values._id && !newVal.locationAdvanced.length))) {
                                document.body.dispatchEvent(new CustomEvent(LOCATION_DIALOG_EVENTS.LOCATION_DIALOG_ADDITIONAL_UPDATE, {}));
                            }
                            return [3 /*break*/, 8];
                        case 6:
                            e_5 = _g.sent();
                            return [3 /*break*/, 8];
                        case 7:
                            isLocationFormInProgress = false;
                            return [7 /*endfinally*/];
                        case 8: return [2 /*return*/];
                    }
                });
            }); };
            var onChangeField = function (e) {
                var _a, _b;
                var _c, _d;
                var action = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    action[_i - 1] = arguments[_i];
                }
                var mode = undefined;
                if (e.target.name === 'mode') {
                    onReset(new Event('click'));
                    mode = (_c = e === null || e === void 0 ? void 0 : e.target) === null || _c === void 0 ? void 0 : _c.value;
                }
                var value = e.target.value;
                if (action[1] === 'select-option' && e.target.name === 'address') {
                    if (action[0].place_id) {
                        value = action[0];
                        var updates_3 = (_a = {},
                            _a[e.target.name] = value,
                            _a.warehouseTemp = EWarehouse.create(),
                            _a.warehouse = EWarehouse.create(),
                            _a);
                        return store.accountStore.currentCompany
                            .locationGeoCode({ fields: [{ name: 'name', value: value.name }] })
                            .then(function (_address) {
                            updates_3.address = JSON.parse(JSON.stringify(getSnapshot(OrderLocationAddress.create(_address))));
                            Object.keys(updates_3).forEach(function (key) {
                                formik.setFieldValue(key, updates_3[key]);
                            });
                            var newVal = __assign(__assign(__assign({}, values), updates_3), { updatedAt: Date.now() });
                            onSelectAddress(newVal, updates_3.address);
                        });
                    }
                    else {
                        value = action[0].address;
                        formik.setFieldValue('warehouse', action[0] || EWarehouse.create());
                        formik.setFieldValue('warehouseTemp', action[0] || EWarehouse.create());
                    }
                    ['country'].forEach(function (e) {
                        if (!value[e])
                            value[e] = '';
                    });
                }
                formik.setFieldValue(e.target.name, value);
                _setValues(__assign(__assign(__assign(__assign({}, values), (mode === OrderLocationMode.SIMPLE && {
                    emailV1: ((_d = values === null || values === void 0 ? void 0 : values.members) === null || _d === void 0 ? void 0 : _d.length) ? values.members[0].email : values.emailV1,
                })), (mode === OrderLocationMode.ADVANCED && { emailV1: values.emailV1 })), (_b = {}, _b[e.target.name] = value, _b)));
            };
            var disabled = !!((_a = formik === null || formik === void 0 ? void 0 : formik.values) === null || _a === void 0 ? void 0 : _a.approvedAt) || !!((_c = (_b = formik.values) === null || _b === void 0 ? void 0 : _b.warehouse) === null || _c === void 0 ? void 0 : _c._id) || disabledEdit;
            var rampKey = formik.values._id ? 'warehouse' : 'warehouseTemp';
            var isSimple = formik.values.mode === OrderLocationMode.SIMPLE;
            var companySimple = isSimple
                ? formik.values.companyId && formik.values.companyId._id
                    ? formik.values.companyId
                    : formik.values.warehouse &&
                        formik.values.warehouse._id &&
                        formik.values.warehouse.createdBy &&
                        formik.values.warehouse.createdBy.company
                : null;
            var nameInput = (React.createElement(FormikTextField, { required: true, tabIndex: 1, title: langForm.t("form_location_name"), variant: "outlined", name: "name", disabled: disabledEdit, value: formik.values.name, autofocus: true, onChange: onFieldChangeHandler, InputProps: __assign({ autofocus: true }, (canGenerateLocationName(__assign(__assign({}, values), (rampKey === 'warehouseTemp' && { warehouse: warehouseTemp }))) &&
                    formik.values.mode === OrderLocationMode.ADVANCED && {
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(BaseTooltip, { title: langForm.t("form_generate_location_name") },
                            React.createElement(IconButton, { onClick: function (e) {
                                    return onFieldChangeHandler({
                                        target: {
                                            name: 'name',
                                            value: generateLocationName(__assign(__assign({}, values), (rampKey === 'warehouseTemp' && { warehouse: warehouseTemp }))),
                                        },
                                    }, null);
                                }, style: { padding: theme.spacing(0.5) }, color: 'primary' },
                                React.createElement(RefreshIcon, null))))),
                })) }));
            var companyAutoCompleProps = {
                disabled: disabledEdit,
                getOptionSelected: function (option, value) {
                    return value && option._id === value._id;
                },
                renderOption: function (option) {
                    return (option && option.name && (React.createElement(Grid, { container: true, alignItems: 'center' },
                        React.createElement(Grid, { item: true, xs: 11, container: true, alignItems: 'center' },
                            React.createElement(TextOverflow, { style: { width: 'initial', marginRight: theme.spacing(1) } }, option.name),
                            (option.vatCode && option.vatCode.match('generated')) || !option.vatCode ? ('') : (React.createElement(TextOverflow, { style: {
                                    width: 'initial',
                                    color: theme.palette.success.light,
                                    marginRight: theme.spacing(1),
                                } }, option.vatCode)),
                            (OrderLocation.create(JSON.parse(JSON.stringify({ address: option.addressV1 }))).fullAddress && (React.createElement(TextOverflow, { style: { width: 'initial' } }, OrderLocation.create(JSON.parse(JSON.stringify({ address: option.addressV1 }))).fullAddress))) ||
                                null),
                        React.createElement(Grid, { item: true, xs: 1 }, option.place_id ? null : (React.createElement(BaseTooltip, { title: langForm.t('form_input_approved') },
                            React.createElement(DoneIcon, { style: { color: theme.palette.success.light } }))))))) ||
                        (option &&
                            [
                                option.name || (option.companyId && option.companyId.name),
                                option.vatCode && option.vatCode.match('generated') ? '' : option.vatCode,
                                OrderLocation.create(JSON.parse(JSON.stringify({ address: option.addressV1 }))).fullAddress,
                            ]
                                .filter(function (e) { return e; })
                                .join(', ')) ||
                        option.title;
                },
                getOptionLabel: function (option) {
                    return ([
                        option.name || (option.companyId && option.companyId.name),
                        option.vatCode && option.vatCode.match('generated') ? '' : option.vatCode,
                        OrderLocation.create(JSON.parse(JSON.stringify({ address: option.addressV1 }))).fullAddress,
                    ]
                        .filter(function (e) { return e; })
                        .join(', ') || option.title);
                },
            };
            var isVatNotEditable = ((_d = formik.values.companyId) === null || _d === void 0 ? void 0 : _d._id) && formik.values.companyVAT && ((_e = formik.values.companyId) === null || _e === void 0 ? void 0 : _e.isNotEditable);
            var companyVatAutoCompleProps = __assign(__assign({}, companyAutoCompleProps), { disabled: disabledEdit || isVatNotEditable });
            var dialogVatCodeExists = formik.values.mode === OrderLocationMode.ADVANCED && (React.createElement(BasicDialog, { onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        onFieldChangeHandler({ target: { name: 'companyName', value: companyWithSameVatCode.name } }, companyWithSameVatCode);
                        return [2 /*return*/];
                    });
                }); }, actionCancelTitle: langApp.t('app_no'), onClose: function () {
                    setCompanyWithSameVatCode(null);
                }, onCloseWithoutOk: function () {
                    setValues(__assign(__assign({}, values), { companyVAT: '', updatedAt: Date.now() }));
                }, body: React.createElement(Grid, { container: true, alignItems: "center", justify: "center" },
                    React.createElement(Typography, { variant: 'body1', style: { textAlign: 'center' }, dangerouslySetInnerHTML: {
                            __html: langForm
                                .t('form_choose_another_company_confirmation')
                                .replace(/_X_/, (companyWithSameVatCode === null || companyWithSameVatCode === void 0 ? void 0 : companyWithSameVatCode.name) || (companyWithSameVatCode === null || companyWithSameVatCode === void 0 ? void 0 : companyWithSameVatCode.name) || ''),
                        } })), title: langForm.t('form_btn_confirm'), actionOkTitle: langApp.t('app_yes'), withoutWrapper: true, bodyStyle: { alignItems: 'center', justifyContent: 'center' }, dialogProps: { maxWidth: 'xl' }, actionInit: function (onOpen) { return React.createElement("span", { ref: confirmCompanyWithSameVatCodeDialog, onClick: onOpen }); } }));
            return (React.createElement(React.Fragment, null,
                dialogVatCodeExists,
                React.createElement(Form, { style: { width: '100%' }, key: [formik.values.mode].join('_'), id: 'form-order-create-location' },
                    React.createElement(Grid, { container: true, direction: "row", alignItems: 'center' },
                        React.createElement(TextOverflow, { style: { marginRight: theme.spacing(2), fontWeight: 600 } }, langFormLocation.t('form_input_location_mode')),
                        React.createElement(Grid, { container: true, direction: "row", alignItems: 'center', justify: "space-between", style: { width: 'initial' } },
                            React.createElement(FormikRadioGroupField, { tabIndex: -2, v1: true, name: 'mode', value: formik.values.mode, onChange: onChangeField, options: Object.keys(OrderLocationMode).map(function (key) { return ({
                                    value: OrderLocationMode[key],
                                    label: (React.createElement(Grid, { container: true, alignItems: 'center' },
                                        React.createElement(TextOverflow, null, langFormLocation.t("form_input_location_mode_" + key.toLowerCase())))),
                                }); }) }))),
                    isSimple ? (React.createElement(React.Fragment, null,
                        localStore.emailWarning && !Object.keys(formik.errors).length ? (React.createElement(Grid, __assign({ container: true }, generalProps, { style: { minHeight: '0.75rem' } }),
                            React.createElement(TextOverflow, { v1: true, warn: true }, localStore.emailWarning && !Object.keys(formik.errors).length
                                ? localStore.emailWarning
                                : ''))) : null,
                        React.createElement(Grid, __assign({ container: true }, generalProps, { style: { marginBottom: theme.spacing(1.5) }, spacing: 2 }),
                            React.createElement(Grid, { item: true, xs: 12, md: 6 }, nameInput),
                            React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                React.createElement(FormikTextField, { required: true, title: langForm.t("form_location_email_to_notify"), variant: "outlined", name: "emailV1", value: formik.values.emailV1, onChange: onFieldChangeHandler }))),
                        React.createElement(Grid, __assign({ item: true, xs: 12 }, generalProps, { key: (formik.values.companyId && formik.values.companyId._id) || formik.values.emailV1 || 1 }),
                            React.createElement(FormikGoogleTextFieldV1, { required: true, title: langFormLocation.t("form_location_" + lang_key + "_address"), variant: "outlined", margin: 'dense', fullWidth: true, v14: true, v16: true, v17: true, v26: true, name: "address", queryProps: {
                                    query: FetchCompanyWarehousesAddresses,
                                    variables: {
                                        filter: {
                                            sortBy: 'address',
                                            sortDir: 'desc',
                                            fields: [
                                                formik.values.companyId && { name: 'company', value: formik.values.companyId._id },
                                            ].filter(function (e) { return e; }),
                                        },
                                    },
                                    filterFields: [
                                        'address.address',
                                        'address.area',
                                        'address.country',
                                        'address.postal',
                                        'address.streetName',
                                        'address.state',
                                        'address.streetNumber',
                                    ],
                                }, value: formik.values.address, autocompleteProps: {
                                    disabled: disabledEdit,
                                    renderOption: function (option) {
                                        return (option && option.name && (React.createElement(Grid, { container: true, alignItems: 'center' },
                                            React.createElement(Grid, { item: true, xs: 11, container: true, alignItems: 'center' }, option.place_id ? (React.createElement(TextOverflow, { style: {
                                                    width: 'initial',
                                                } }, option.name)) : (React.createElement(React.Fragment, null,
                                                (OrderLocation.create(JSON.parse(JSON.stringify({ address: option.address })))
                                                    .fullAddress && (React.createElement(TextOverflow, { style: { width: 'initial', marginRight: theme.spacing(1) } }, OrderLocation.create(JSON.parse(JSON.stringify({ address: option.address }))).fullAddress))) ||
                                                    null,
                                                (option.name && (React.createElement(TextOverflow, { style: {
                                                        width: 'initial',
                                                        color: theme.palette.success.light,
                                                        marginRight: theme.spacing(1),
                                                    } }, "[" + option.name + "]"))) ||
                                                    null))),
                                            React.createElement(Grid, { item: true, xs: 1 }, option.place_id ? null : (React.createElement(BaseTooltip, { title: langForm.t('form_input_approved') },
                                                React.createElement(DoneIcon, { style: { color: theme.palette.success.light } }))))))) ||
                                            [
                                                OrderLocation.create(JSON.parse(JSON.stringify({ address: option.address })))
                                                    .fullAddress,
                                                option.name,
                                            ]
                                                .filter(function (e) { return e; })
                                                .join(', ') ||
                                            option.title;
                                    },
                                }, onChange: function (e, value) {
                                    var args = [];
                                    for (var _i = 2; _i < arguments.length; _i++) {
                                        args[_i - 2] = arguments[_i];
                                    }
                                    if (value) {
                                        onFieldChangeHandler(e, value, args);
                                    }
                                } })),
                        companySimple && (React.createElement(Grid, __assign({ container: true, direction: "column" }, generalProps),
                            React.createElement(TextOverflow, null, [
                                companySimple.name,
                                companySimple.vatCode,
                                OrderLocation.create(JSON.parse(JSON.stringify({ address: companySimple.addressV1 })))
                                    .fullAddress,
                            ]
                                .filter(function (e) { return e; })
                                .join(', ')))))) : (React.createElement(React.Fragment, null,
                        ((_f = formik.values.companyId) === null || _f === void 0 ? void 0 : _f.isDeleted) && (React.createElement(Grid, { container: true, direction: "row" },
                            React.createElement(TextOverflow, { warn: true, style: { marginBottom: theme.spacing(1) }, variant: 'caption' }, "Assigned company got removed, please assign correct company to fix the location"))),
                        React.createElement(Grid, { container: true, direction: "row", justify: "space-between", spacing: 2 },
                            React.createElement(Grid, { item: true, xs: 12, md: 6, style: { marginBottom: theme.spacing(1.5) } }, nameInput),
                            React.createElement(Grid, { item: true, xs: 12, md: 6, style: { marginBottom: theme.spacing(1.5) } },
                                React.createElement(FormikAutocompleteAsync, { v17: true, v28: true, required: true, tabIndex: 2, label: langFormLocation.t("form_location_" + lang_key + "_company_name"), variant: "outlined", margin: 'dense', fullWidth: true, name: "companyName", key: updatedFields.companyName, isClient: true, client: config.client, query: FetchLocationCompanies, variables: { filter: { sortBy: 'addressV1', sortDir: 'desc' } }, filterFields: [
                                        'name',
                                        'addressV1.address',
                                        'addressV1.area',
                                        'addressV1.country',
                                        'addressV1.postal',
                                        'addressV1.streetName',
                                        'addressV1.state',
                                        'addressV1.streetNumber',
                                    ], value: formik.values.companyName, canAddNew: true, canAddNewV1: true, canAddNewV2: true, loadOnSearch: true, withoutNoOption: true, autocompleteProps: __assign({ defaultValue: formik.values.companyName, mapValue: function (value) { return value && [value.name].filter(function (e) { return e; }).join(', '); } }, companyAutoCompleProps), onBlur: function (e) {
                                        var _a, _b, _c;
                                        setValues(__assign(__assign({}, values), { companyId: __assign(__assign({}, (((_a = formik === null || formik === void 0 ? void 0 : formik.values) === null || _a === void 0 ? void 0 : _a.companyId) || {})), { name: (_b = e === null || e === void 0 ? void 0 : e.target) === null || _b === void 0 ? void 0 : _b.value }), companyName: (_c = e === null || e === void 0 ? void 0 : e.target) === null || _c === void 0 ? void 0 : _c.value }));
                                        setUpdatedFields(__assign(__assign({}, updatedFields), { companyName: Date.now() }));
                                    }, onChange: function (e, __value) {
                                        if (e.type === 'blur')
                                            return;
                                        return onFieldChangeHandler(e, __value);
                                    }, onClear: onFieldChangeHandler }))),
                        React.createElement(Grid, { container: true, direction: "row", justify: "space-between", spacing: 2 },
                            React.createElement(Grid, __assign({ item: true, xs: 12, md: 6 }, generalProps, { key: values.companyVAT }),
                                React.createElement(FormikAutocompleteAsync, __assign({ v17: true, tabIndex: 3, required: true, title: langFormLocation.t("form_location_" + lang_key + "_company_vat"), label: langFormLocation.t("form_location_" + lang_key + "_company_vat"), variant: "outlined", margin: 'dense', fullWidth: true, name: "companyVAT", isClient: true, client: config.client, query: FetchLocationCompanies, variables: { filter: { sortBy: 'vatCode', sortDir: 'desc' } }, filterFields: ['vatCode'], value: formik.values.companyVAT, canAddNew: true, canAddNewV1: true, canAddNewV2: true, loadOnSearch: true, withoutNoOption: true }, (isVatNotEditable && {
                                    warnText: (React.createElement(Grid, { container: true, alignItems: "center", justify: "space-between", style: { paddingTop: theme.spacing(1) } },
                                        React.createElement(TextOverflow, { warn: true }, langError.t('CREATE_LOCATION_2')),
                                        ![ISSUE_STATUS.IN_REVIEW, ISSUE_STATUS.REQUESTED].includes((_g = values.lastIssue) === null || _g === void 0 ? void 0 : _g.status) ? (React.createElement(Link, { to: "#", id: 'modal_incorect_vat', onClick: showReportIssue },
                                            React.createElement(TextOverflow, { style: __assign({}, (isMobile && { marginTop: theme.spacing(1), width: '100%' })), primary: true }, langForm.t('form_btn_incorect_vat')))) : (React.createElement(Link, { to: "/issues?search=" + values.lastIssue._id },
                                            React.createElement(TextOverflow, { primary: true }, langOfferForm.t('offer_requested')))))),
                                }), { autocompleteProps: __assign({ defaultValue: formik.values.companyVAT, mapValue: function (value) { return value && [value.vatCode].filter(function (e) { return e; }).join(', '); } }, companyVatAutoCompleProps), onBlur: function (e, __value) {
                                        if (e.type === 'blur' && companyWithSameVatCode)
                                            return;
                                        return onFieldChangeHandler(e, __value);
                                    }, onClear: onFieldChangeHandler }))),
                            React.createElement(Grid, __assign({ item: true, xs: 12, md: 6 }, generalProps),
                                React.createElement(FormikGoogleTextFieldV1, { required: true, v11: true, v17: true, tabIndex: 3, disabled: disabledEdit, style: __assign({ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }, (disabledEdit && { pointerEvents: 'none' })), additionalStyle: { margin: 0 }, title: langFormLocation.t("form_location_" + lang_key + "_company_registration_address"), label: langFormLocation.t("form_location_" + lang_key + "_company_registration_address"), variant: "outlined", name: "companyAddress", value: formik.values.companyAddress, savedValue: values.companyAddress, onChange: function (e, value, args) {
                                        console.log('value', value);
                                        if (value) {
                                            onFieldChangeHandler(e, value, args);
                                        }
                                    } }))),
                        React.createElement(Grid, { container: true, spacing: 2, direction: "row", style: __assign(__assign({}, generalProps.style), { marginBottom: 0 }), key: ((_h = formik.values.warehouse) === null || _h === void 0 ? void 0 : _h._id) || 1 },
                            React.createElement(Grid, { item: true, xs: 12, md: 6, style: (disabledEdit && { pointerEvents: 'none' }) || {} },
                                React.createElement(FormikGoogleTextFieldV1, { required: true, title: langFormLocation.t("form_location_" + lang_key + "_address"), variant: "outlined", margin: 'dense', fullWidth: true, tabIndex: 5, v14: true, v16: true, v17: true, helpAdditional: React.createElement(FormControlLabel, { className: classes.inputLabel, style: { width: '100%', marginBottom: theme.spacing(2) }, disabled: disabledEdit, control: React.createElement(Checkbox, { tabIndex: 4, checked: useCompanyAddress, name: "useCompanyAddress", onChange: onChangeInput, color: "primary" }), label: React.createElement(TextOverflow, { onClick: onChangeInput }, langFormLocation.t("form_location_" + lang_key + "_company_registration_address_is_same")) }), name: "address", queryProps: {
                                        query: FetchCompanyWarehousesAddresses,
                                        variables: {
                                            filter: {
                                                sortBy: 'address',
                                                sortDir: 'desc',
                                                fields: [
                                                    formik.values.companyId && {
                                                        name: 'company',
                                                        value: formik.values.companyId._id,
                                                    },
                                                ].filter(function (e) { return e; }),
                                            },
                                        },
                                        filterFields: [
                                            'address.address',
                                            'address.area',
                                            'address.country',
                                            'address.postal',
                                            'address.streetName',
                                            'address.state',
                                            'address.streetNumber',
                                        ],
                                    }, value: formik.values.address, savedValue: values.address, autocompleteProps: {
                                        disabled: disabledEdit,
                                        renderOption: function (option) {
                                            return (option && option.name && (React.createElement(Grid, { container: true, alignItems: 'center' },
                                                React.createElement(Grid, { item: true, xs: 11, container: true, alignItems: 'center' }, option.place_id ? (React.createElement(TextOverflow, { style: {
                                                        width: 'initial',
                                                    } }, option.name)) : (React.createElement(React.Fragment, null,
                                                    (OrderLocation.create(JSON.parse(JSON.stringify({ address: option.address }))).fullAddress && (React.createElement(TextOverflow, { style: { width: 'initial', marginRight: theme.spacing(1) } }, OrderLocation.create(JSON.parse(JSON.stringify({ address: option.address }))).fullAddress))) ||
                                                        null,
                                                    (option.name && (React.createElement(TextOverflow, { style: {
                                                            width: 'initial',
                                                            color: theme.palette.success.light,
                                                            marginRight: theme.spacing(1),
                                                        } }, "[" + option.name + "]"))) ||
                                                        null))),
                                                React.createElement(Grid, { item: true, xs: 1 }, option.place_id ? null : (React.createElement(BaseTooltip, { title: langForm.t('form_input_approved') },
                                                    React.createElement(DoneIcon, { style: { color: theme.palette.success.light } }))))))) ||
                                                [
                                                    OrderLocation.create(JSON.parse(JSON.stringify({ address: option.address })))
                                                        .fullAddress,
                                                    option.name,
                                                ]
                                                    .filter(function (e) { return e; })
                                                    .join(', ') ||
                                                option.title;
                                        },
                                    }, onChange: function (e, value) {
                                        var args = [];
                                        for (var _i = 2; _i < arguments.length; _i++) {
                                            args[_i - 2] = arguments[_i];
                                        }
                                        if (value) {
                                            onFieldChangeHandler(e, value, args);
                                        }
                                    } })),
                            React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                React.createElement(FormikAutocompleteAsync, { isClient: true, tabIndex: 6, client: config.client, ignoreInputChange: true, label: langApp.t("warehouse_ramp"), title: langApp.t("warehouse_ramp"), variant: "outlined", margin: 'dense', fullWidth: true, helpAdditional: React.createElement(FormControlLabel, { className: classes.inputLabel, style: { width: '100%', visibility: 'hidden' }, control: React.createElement(Checkbox, null), label: null }), v14: true, v16: true, v17: true, name: rampKey, style: isSmall ? {} : { marginBottom: theme.spacing(2) }, query: FetchWarehouses, variables: {
                                        filter: {
                                            sortBy: 'address',
                                            sortDir: 'desc',
                                            fields: __spread([
                                                { name: 'ramps', value: 1 },
                                                { name: 'all', value: 2 }
                                            ], (((((_j = formik.values.companyId) === null || _j === void 0 ? void 0 : _j._id) || ((_k = formik.values.warehouse) === null || _k === void 0 ? void 0 : _k._id)) && [
                                                { name: 'rampsCompany', value: (_l = formik.values.companyId) === null || _l === void 0 ? void 0 : _l._id },
                                                {
                                                    name: 'parent',
                                                    value: (formik.values.warehouse.parent || formik.values.warehouse || {})._id || '',
                                                },
                                            ]) ||
                                                [])).filter(function (e) { return e; }),
                                        },
                                    }, filterFields: ['name'], value: formik.values[rampKey], autocompleteProps: {
                                        disabledInput: true,
                                        defaultValue: formik.values[rampKey],
                                        // disabled: !formik.values.warehouse?._id,
                                        getOptionLabel: function (option) { return (option === null || option === void 0 ? void 0 : option.name) || (option === null || option === void 0 ? void 0 : option.title) || ''; },
                                    }, onChange: onFieldChangeHandler }))),
                        React.createElement(Grid, { container: true, style: { marginTop: theme.spacing(4) } },
                            React.createElement(LocationMembers, { title: React.createElement(InputTitle, { v1: true, title: React.createElement("div", { style: { display: 'flex' } },
                                        langForm.t("form_list_members"),
                                        ' ',
                                        React.createElement(TextOverflow, { v1: true, warn: true, style: { lineHeight: '1.6' } },
                                            "\u2003",
                                            localStore.emailWarning && !Object.keys(formik.errors).length
                                                ? localStore.emailWarning
                                                : ' ')), style: { width: 'initial' } }), parent: values, onCreate: function () {
                                    _setValues(__assign(__assign({}, values), { emailV1: '' }));
                                }, defaultValue: (values.members && values.members.length && values.members) || null, lang_key: lang_key, config: config, ref: formMembersRef, onChange: function () {
                                    var _a, _b;
                                    if ((_b = (_a = formMembersRef === null || formMembersRef === void 0 ? void 0 : formMembersRef.current) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b.members) {
                                        setValues(__assign(__assign({}, values), { members: formMembersRef.current.values.members }));
                                    }
                                }, validateEmails: localStore.validateEmails })),
                        React.createElement(Grid, { container: true },
                            React.createElement(LocationMembers, { title: React.createElement(InputTitle, { v1: true, title: langFormLocation.t("form_location_delivery_additional_email"), help: langFormLocation.t('form_input_location_additional_email_help'), style: { width: 'initial' } }), isAdditional: true, suffix: 'additional', defaultValue: (values.additionalMembers && values.additionalMembers.length && values.additionalMembers) ||
                                    null, lang_key: lang_key, config: config, ref: formAdditionalMembersRef, onChange: function () {
                                    var _a, _b;
                                    if ((_b = (_a = formAdditionalMembersRef === null || formAdditionalMembersRef === void 0 ? void 0 : formAdditionalMembersRef.current) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b.members) {
                                        setValues(__assign(__assign({}, values), { additionalMembers: formAdditionalMembersRef.current.values.members }));
                                    }
                                } })),
                        React.createElement(Grid, __assign({ container: true, direction: "row", spacing: 2 }, generalProps),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(Typography, { className: classes.subHeader }, langFormLocation.t("form_location_" + lang_key + "_working_hours")),
                                React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                        React.createElement(FormikWorkingHour, __assign({ disabled: disabled || (values.warehouse && values.warehouse._id), label: langOrderForm.t('create_order_route_location_working_hours_from'), name: "startTime" }, (disabled && {
                                            help: langForm.t("form_input_help_order_time_to_spend_on_load_time_booking_help"),
                                        }), { value: formik.values.startTime, onChange: onFieldChangeHandler }))),
                                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                        React.createElement(FormikWorkingHour, __assign({ disabled: disabled || (values.warehouse && values.warehouse._id), label: langOrderForm.t('create_order_route_location_working_hours_to'), name: "endTime" }, (disabled && {
                                            help: langForm.t("form_input_help_order_time_to_spend_on_load_time_booking_help"),
                                        }), { value: formik.values.endTime, onChange: onFieldChangeHandler })))))))),
                    (!isSimple && (React.createElement(Grid, __assign({ container: true, direction: "row" }, generalProps),
                        React.createElement(TextOverflow, { variant: 'caption', warn: true },
                            React.createElement(Grid, { container: true, alignItems: 'center', justify: 'flex-start' },
                                React.createElement(WarningIcon, { style: { color: theme.palette.warning.light } }),
                                langOrderForm.t('create_order_route_location_warning')))))) ||
                        null)));
        }),
        props.isAdmin ? null : (React.createElement(Grid, __assign({ container: true }, generalProps),
            React.createElement(ExpandFormItem, { v1: true, title: langFormLocation.t("form_location_more_settings"), help: React.createElement(React.Fragment, null,
                    React.createElement(HelpIconComponent, { title: langFormLocation.t('form_location_more_settings_help') })), additionalHelp: React.createElement(AddFormButton, { style: {}, title: "" + langForm.t('form_btn_add'), onClick: function (e) {
                        document.body.dispatchEvent(new CustomEvent(LOCATION_DIALOG_EVENTS.LOCATION_DIALOG_ADDITIONAL_ADD, {}));
                        return onPreventEvent(e);
                    } }), tableHeader: !isSmall && documentAccessHeader, hideSpace: true },
                React.createElement(LocationDialogAdditional, { defaultValue: (((_h = values.locationAdvanced) === null || _h === void 0 ? void 0 : _h.length) && values.locationAdvanced) || null, lang_key: lang_key, config: config, ref: formAdvancedRef, key: (_j = values === null || values === void 0 ? void 0 : values.companyId) === null || _j === void 0 ? void 0 : _j._id }))))));
    var companyName = (_k = localStore.assignCompany) === null || _k === void 0 ? void 0 : _k.name;
    if (companyName) {
        companyName += " (" + langApp.t("user_app_type_" + localStore.assignCompany.type.toLowerCase()) + ")";
    }
    if (!companyName)
        companyName = 'NA';
    var dialogBodyAssign = (React.createElement(Grid, { container: true, alignItems: 'center', direction: 'column', key: JSON.stringify(localStore.assignCompany) || 1 },
        React.createElement(Typography, { variant: 'body1', style: { textAlign: 'center' }, dangerouslySetInnerHTML: {
                __html: (langFormLocation.t('location_assign_emal') || '')
                    .replace(/_email_/, localStore.assignEmail || 'NA')
                    .replace(/_companyName_/, companyName)
                    .replace(/_vatCode_/, ((_l = localStore.assignCompany) === null || _l === void 0 ? void 0 : _l.vatCode) || 'NA')
                    .replace(/_name_/, values.companyName),
            } }),
        React.createElement(Typography, { variant: 'body1', style: { textAlign: 'center' }, dangerouslySetInnerHTML: {
                __html: (langFormLocation.t('location_assign_emal_1') || '')
                    .replace(/_companyName_/, companyName)
                    .replace(/_name_/, values.companyName || 'NA'),
            } })));
    var dialogBodyAssign2 = (React.createElement(Grid, { container: true, alignItems: 'center', direction: 'column', key: JSON.stringify(localStore.assignCompany) || 1 },
        React.createElement(Typography, { variant: 'body1', style: { textAlign: 'center' }, dangerouslySetInnerHTML: {
                __html: (langFormLocation.t('location_assign_emal_2') || '')
                    .replace(/_email_/, localStore.assignEmail || 'NA')
                    .replace(/_companyName_/, companyName)
                    .replace(/_vatCode_/, ((_m = localStore.assignCompany) === null || _m === void 0 ? void 0 : _m.vatCode) || 'NA')
                    .replace(/_name_/, values.companyName),
            } })));
    return useObserver(function () { return (React.createElement(React.Fragment, null,
        React.createElement(ReportIssueForm, { config: config, type: 1, item: __assign(__assign({}, getSnapshot(Issues.create({
                title: langForm.t('form_btn_incorect_vat'),
            }))), { parent_collection_name: 'locations', parent: values._id }), title: langForm.t('form_btn_incorect_vat'), onChange: function (item) {
                setValues(__assign(__assign({}, values), { updatedAt: new Date().toString(), lastIssue: item }));
                if (defaultValue)
                    defaultValue.lastIssue = item;
            }, actionOnInit: function (onClick) { return React.createElement("span", { style: { display: 'none' }, ref: reportRef, onClick: onClick }); } }),
        React.createElement(ReportIssueForm, { config: config, type: 1, item: __assign(__assign({}, getSnapshot(Issues.create({
                title: "Fix email company",
            }))), { parent_collection_name: 'locations', parent: values._id }), title: langForm.t('form_register_issue'), onChange: function (item) {
                setValues(__assign(__assign({}, values), { updatedAt: new Date().toString(), lastIssue: item }));
                if (defaultValue)
                    defaultValue.lastIssue = item;
            }, actionOnInit: function (onClick) { return React.createElement("span", { style: { display: 'none' }, ref: reportRef1, onClick: onClick }); } }),
        React.createElement(ReportIssueForm, { config: config, type: 1, item: __assign(__assign({}, getSnapshot(Issues.create({
                title: "Incorrect user company type",
            }))), { parent_collection_name: 'locations', parent: values._id }), title: langForm.t('form_register_issue'), onChange: function (item) {
                setValues(__assign(__assign({}, values), { updatedAt: new Date().toString(), lastIssue: item }));
                if (defaultValue)
                    defaultValue.lastIssue = item;
            }, actionOnInit: function (onClick) { return React.createElement("span", { style: { display: 'none' }, ref: reportRef2, onClick: onClick }); } }),
        React.createElement(BasicDialog, { title: langFormLocation.t('location_assign_email_2') || '', onOk: onOk2, body: dialogBodyAssign, actionInit: function (handleClickOpen) { return React.createElement("span", { ref: dialogRef2, onClick: handleClickOpen }); }, moreActions: function () { return (React.createElement(Link, { to: "#", id: 'modal_help', onClick: onOk3_1 },
                React.createElement(TextOverflow, { style: __assign({}, ((isMobile && { marginTop: theme.spacing(1), width: '100%' }) || {
                        marginRight: theme.spacing(1),
                    })), primary: true }, langApp.t('app_help')))); } }),
        React.createElement(BasicDialog, { title: langFormLocation.t('location_assign_email_2') || '', onOk: onOk3, actionOkTitle: langApp.t('main_issue_report'), body: dialogBodyAssign2, actionInit: function (handleClickOpen) { return React.createElement("span", { ref: dialogRef3, onClick: handleClickOpen }); }, moreActions: function () { return (React.createElement(Link, { to: "#", id: 'modal_help', onClick: onOk3_1 },
                React.createElement(TextOverflow, { style: __assign({}, ((isMobile && { marginTop: theme.spacing(1), width: '100%' }) || {
                        marginRight: theme.spacing(1),
                    })), primary: true }, langApp.t('app_help')))); } }),
        React.createElement(BasicDialog, { title: langForm.t('form_btn_confirm'), onOk: onOk1, body: React.createElement(TextOverflow, { v1: true, style: { textAlign: 'center' } }, langFormLocation.t('form_location_confirm_create')), actionInit: function (handleClickOpen) { return React.createElement("span", { ref: dialogRef1, onClick: handleClickOpen }); } }),
        React.createElement(BasicDialog, __assign({ v1: true, v2: true, hidActionOkBth: props.isAdmin, askCanClose: true, dialogProps: { fullWidth: true, maxWidth: 'md' }, onOk: onOk, actionOkTitle: "" + (values._id ? langForm.t("form_btn_save") : langForm.t("form_btn_create")), body: dialogBody, moreActions: function () {
                var _a, _b;
                return (React.createElement(React.Fragment, null,
                    ((_a = props.allActions) === null || _a === void 0 ? void 0 : _a.onEditItem) && (values === null || values === void 0 ? void 0 : values._id) && (React.createElement(MainButton, { style: __assign({}, (!isMobile && { marginRight: theme.spacing(2) })), onClick: function (e) {
                            var _a;
                            var _id = _v._id, warehouse = _v.warehouse, warehouses = _v.warehouses, company = _v.company, item = __rest(_v, ["_id", "warehouse", "warehouses", "company"]);
                            ['additionalMembers', 'members', 'locationAdvanced'].forEach(function (key) {
                                item[key] = (item[key] || []).map(function (_a) {
                                    var _id = _a._id, company = _a.company, rest = __rest(_a, ["_id", "company"]);
                                    return (__assign({}, rest));
                                });
                            });
                            (_a = props.allActions) === null || _a === void 0 ? void 0 : _a.onEditItem(e, item);
                        }, title: langToast.t('toast_items_duplicate').replace(/_X_/, ''), color: 'secondary', id: 'modal_duplicate_button' })),
                    ((_b = props.allActions) === null || _b === void 0 ? void 0 : _b.onDeleteItem) && (values === null || values === void 0 ? void 0 : values._id) && (React.createElement(MainButton, { style: __assign(__assign({}, (!isMobile && { marginRight: theme.spacing(2) })), { backgroundColor: theme.palette.error.light }), onClick: function (e) {
                            var _a;
                            (_a = props.allActions) === null || _a === void 0 ? void 0 : _a.onDeleteItem(e, _v);
                        }, title: langApp.t('modal_delete_title'), id: 'modal_delete_button' }))));
            }, id: !values._id
                ? langFormLocation.t("form_location_" + lang_key + "_title")
                : langFormLocation.t("form_location_" + lang_key + "_title_edit"), title: React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center', direction: 'column' }, "" + (!values._id
                ? langFormLocation.t("form_location_" + lang_key + "_title")
                : langFormLocation.t("form_location_" + lang_key + "_title_edit")),
                values.approvedAt ? (React.createElement(Typography, { variant: 'caption' },
                    React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
                        React.createElement(WarningIcon, { color: 'primary' }),
                        langOrderForm.t('create_order_route_location_no_edit')))) : null), actionInit: function (handleClickOpen) { return React.createElement("span", { ref: dialogRef, onClick: handleClickOpen }); } }, p, { onClose: function (e) {
                onReset(e);
                if (p.onClose)
                    p.onClose(e);
            } })))); });
}));
export var LocationDialog = inject('store')(function (props) { return (React.createElement(LocationFormDialog, __assign({}, props, { key: (props.defaultValue && props.defaultValue._id && props.defaultValue.updatedAt) || 1 }))); });
