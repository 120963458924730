var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { makeStyles, Button } from '@material-ui/core';
import { CircularIndeterminate } from './CircularIndeterminate';
import { globalUtils, wrapTextToId } from '../utils';
import { SOUND_KEY } from './Sounds';
import { TextOverflow } from './TextOverflow';
import { theme } from '../theme';
import { onPreventEvent } from './Dialog';
import { useAnimationStyles } from '../styles/main';
var useStyles = makeStyles(function (theme) { return ({
    title: {
        color: theme.palette.background.default,
        textTransform: 'none',
    },
    title1: {
        color: theme.palette.primary.light,
        textTransform: 'none',
    },
    root: {
        position: 'relative',
        '&.MuiButton-containedPrimary': {
            '& svg:not(.MuiCircularProgress-svg)': {
                marginRight: theme.spacing(1),
                '& path': {
                    fill: theme.palette.common.white,
                },
            },
        },
    },
    loading: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    small: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    big: {
        paddingLeft: theme.spacing(15),
        paddingRight: theme.spacing(15),
        zIndex: 1,
    },
    v2: {
        width: '1005',
    },
    v1: {
        height: 40,
        minWidth: 178,
        background: theme.palette.common.white,
        borderWidth: 2,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: 3,
        '& *': {
            color: theme.palette.primary.light,
        },
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
        '& svg': {},
    },
}); });
export var MainButton = function (props) {
    var _a;
    var title = props.title, icon = props.icon, loading = props.loading, disabled = props.disabled, v1 = props.v1, className = props.className, isOkScreen = props.isOkScreen, remainProps = __rest(props, ["title", "icon", "loading", "disabled", "v1", "className", "isOkScreen"]);
    var classes = useStyles();
    var classesAnimation = useAnimationStyles();
    var events = isOkScreen
        ? [
            {
                name: 'keydown',
                ctx: window,
                callback: function (e) {
                    if (e.keyCode === 13) {
                        onPreventEvent(e);
                        // if (remainProps.onClick) remainProps.onClick(e);
                    }
                },
            },
        ]
        : [];
    React.useEffect(function () {
        events.forEach(function (_a) {
            var ctx = _a.ctx, name = _a.name, callback = _a.callback;
            ctx.addEventListener(name, callback);
        });
        return function () {
            events.forEach(function (_a) {
                var ctx = _a.ctx, name = _a.name, callback = _a.callback;
                ctx.removeEventListener(name, callback);
            });
        };
    }, [isOkScreen]);
    var onClick = function () {
        var e = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            e[_i] = arguments[_i];
        }
        globalUtils.sounds.play(SOUND_KEY.BUTTON_CLICK);
        if (remainProps.onClick)
            remainProps.onClick.apply(remainProps, __spread(e));
    };
    return (React.createElement(Button, __assign({ variant: "contained", color: "primary", disabled: disabled || loading }, remainProps, { onClick: onClick, id: wrapTextToId(title || ''), className: clsx(className, classes.root, (_a = {},
            _a[classes.v1] = v1,
            _a[classes.v2] = props.fullWidth,
            _a[classes.small] = props.v3,
            _a[classes.big] = props.v4,
            _a[classesAnimation.pulse] = props.withPulse,
            _a)) }),
        loading ? (React.createElement(CircularIndeterminate, { size: "1.5em", className: classes.title, style: { marginRight: theme.spacing(1) } })) : null,
        icon || null,
        React.createElement(TextOverflow, { className: remainProps.variant === 'outlined' ? classes.title1 : classes.title, style: __assign(__assign({}, ((disabled || loading) && { color: theme.palette.text.secondary })), props.disabledStyle) }, title)));
};
