var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { createContext, useReducer } from 'react';
import { SOCKET_CUSTOM_EVENT_NAME } from '../types';
import { globalUtils, reloadPageWithoutCache } from '../../../utils';
import { SOUND_KEY } from '../../Sounds';
import { CUSTOM_EVENTS_ORDER_LIST_ITEM } from '../../Order/List/Customer/storeListItem';
import { inject } from 'mobx-react';
export var CUSTOM_EVENTS = {
    REFRESH_USER: 'REFRESH_USER',
    ORDER_FOCUS_TAB: 'ORDER_FOCUS_TAB',
};
var initialState = {
    _id: 0,
    client: null,
    list: [],
    newUpdates: 0,
    newDocumentSigned: 0,
    updatedAt: 0,
};
var NotificationStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = NotificationStore.Provider, Consumer = NotificationStore.Consumer;
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["REVIEW"] = "REVIEW";
    ACTIONS["LOADED"] = "LOADED";
})(ACTIONS || (ACTIONS = {}));
export var NotificationProvider = function (_a) {
    var children = _a.children, client = _a.client, props = __rest(_a, ["children", "client"]);
    var initState = __assign(__assign({}, JSON.parse(JSON.stringify(initialState))), { client: client, _id: Math.random() });
    var _b = __read(useReducer(function (state, action) {
        var payload = action.payload;
        switch (action.type) {
            case ACTIONS.REVIEW: {
                return __assign(__assign({}, state), { newUpdates: 0 });
            }
            case ACTIONS.LOADED: {
                var list = __spread(payload.list, state.list);
                return __assign(__assign({}, state), __assign({ list: list, newUpdates: Math.min(state.newUpdates + 1, list.length) }, (payload.newDocumentSigned && { newDocumentSigned: payload.newDocumentSigned })));
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _b[0], dispatch = _b[1];
    var review = function () {
        dispatch({
            type: ACTIONS.REVIEW,
        });
    };
    var events = client
        ? [
            {
                client: client,
                name: 'notification',
                callback: function (data) {
                    var _a;
                    var payload = { updatedAt: Date.now() };
                    // console.log('=======notifications', data);
                    if ([
                        SOCKET_CUSTOM_EVENT_NAME.QUOTE_ASSIGN_TRANSPORT,
                        SOCKET_CUSTOM_EVENT_NAME.QUOTE_LOADING_AT,
                        SOCKET_CUSTOM_EVENT_NAME.QUOTE_NO_LOADING_AT,
                        SOCKET_CUSTOM_EVENT_NAME.QUOTE_UNLOADING_AT,
                        SOCKET_CUSTOM_EVENT_NAME.QUOTE_NO_UNLOADING_AT,
                        SOCKET_CUSTOM_EVENT_NAME.QUOTE_LOADING_AT,
                        SOCKET_CUSTOM_EVENT_NAME.QUOTE_UNLOADING_AT_CHANGED,
                        SOCKET_CUSTOM_EVENT_NAME.QUOTE_UNLOADING_AT_BOOKED,
                        SOCKET_CUSTOM_EVENT_NAME.QUOTE_LOADING_AT_CHANGED,
                        SOCKET_CUSTOM_EVENT_NAME.QUOTE_LOADING_AT_BOOKED,
                        SOCKET_CUSTOM_EVENT_NAME.DOCUMENT_REQUEST_SIGN,
                        SOCKET_CUSTOM_EVENT_NAME.DOCUMENT_REMOVED,
                        SOCKET_CUSTOM_EVENT_NAME.DOCUMENT_UPDATED,
                        SOCKET_CUSTOM_EVENT_NAME.DOCUMENT_SIGNED,
                        SOCKET_CUSTOM_EVENT_NAME.DOCUMENT_CREATED,
                    ].includes(data.event)) {
                        document.body.dispatchEvent(new CustomEvent(CUSTOM_EVENTS_ORDER_LIST_ITEM.NEW_NOTIFICATION, {
                            detail: {
                                data: data,
                            },
                        }));
                    }
                    if (((_a = data.company) === null || _a === void 0 ? void 0 : _a._id) === props.mobxStore.accountStore.currentCompany._id) {
                        console.log('no need to show up notification on same company');
                        return;
                    }
                    if (data.event === SOCKET_CUSTOM_EVENT_NAME.NOTIFICATION_APP_NEED_TO_RESTART) {
                        if (data.clearKeys) {
                            reloadPageWithoutCache();
                        }
                        else {
                            location.reload();
                        }
                    }
                    else if (data.event === SOCKET_CUSTOM_EVENT_NAME.NOTIFICATION_ITEM_DELETED && 0) {
                    }
                    else {
                        var list = [data].splice(0, 20);
                        Object.assign(payload, {
                            list: list,
                            newUpdates: Math.min(state.newUpdates + 1, list.length),
                        });
                        if ([SOCKET_CUSTOM_EVENT_NAME.DOCUMENT_SIGNED, SOCKET_CUSTOM_EVENT_NAME.DOCUMENT_CREATED].includes(data.event)) {
                            Object.assign(payload, {
                                newDocumentSigned: Date.now(),
                            });
                        }
                        else if ([
                            SOCKET_CUSTOM_EVENT_NAME.COMPANY_REQUEST_TERM_APPROVED,
                            SOCKET_CUSTOM_EVENT_NAME.COMPANY_REQUEST_TERM_DECLINED,
                            SOCKET_CUSTOM_EVENT_NAME.REFRESH_USER,
                        ].includes(data.event)) {
                            document.body.dispatchEvent(new Event(CUSTOM_EVENTS.REFRESH_USER));
                            if ([SOCKET_CUSTOM_EVENT_NAME.REFRESH_USER].includes(data.event)) {
                                return;
                            }
                        }
                    }
                    dispatch({
                        type: ACTIONS.LOADED,
                        payload: payload,
                    });
                    globalUtils.sounds.play(SOUND_KEY.NEW_NOTIFICATION);
                },
            },
        ]
        : [];
    var listen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.on(ev.name, ev.callback); });
        if (!client) {
            console.error('*******************no client');
        }
        else {
            console.log('=======notifications listen');
        }
    };
    var unListen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.off(ev.name, ev.callback); });
        if (!client) {
            console.error('*******************no client');
        }
        else {
            console.log('=======notifications un listen');
        }
    };
    var value = {
        state: state,
        listen: listen,
        unListen: unListen,
        review: review,
    };
    // /*eslint-disable */
    // React.useEffect(() => {
    //   return () => {
    //     console.log("=======notifications un listen");
    //     events.forEach((ev) => ev.client && ev.client.off(ev.name, ev.callback));
    //   };
    // }, []);
    // /*eslint-enable */
    return React.createElement(Provider, { value: value }, children);
};
export var withNotificationStore = function (Component) {
    return inject('store')(function (props) {
        var key = window.hasSocketConnected; ///props.globalSocketState.auth?.user?._id || -1//TODO: use store state instead window
        return (React.createElement(Consumer, { key: key }, function (storeProps) {
            return React.createElement(Component, __assign({}, __assign(__assign({}, props), storeProps)));
        }));
    });
};
