import { ORDER_QUOTE_TRANSPORT_BASE } from './orderQuoteTransport';
import { ORDER_ROUTE_BASE, ORDER_ROUTE_BASE_2 } from './orderRoute';
import { ORDER_CARGO_BASE } from './orderCargo';
import { BASE_QUERY } from '../base';
import { HTML_TEMPLATE_BASE } from '../htmlTemplate';
export var ORDER_TRANSPORTATION_BASE_1 = "\n    " + BASE_QUERY + "\n    order{\n      _id\n      timeToSpend\n    }\n    cargoes{\n      " + ORDER_CARGO_BASE + "\n    }\n    templateCMR{\n      " + HTML_TEMPLATE_BASE + "\n    }\n    startManufacturingDate \n    endManufacturingDate  \n    startDate \n    endDate \n    endTime \n    startTime \n    unloadedAt \n    inTransitAt \n    isInTransitForced\n    arrivedToPickup \n    arrivedToDelivery \n    p44_arrivedToPickup \n    p44_arrivedToDelivery \n    membersStartBookingUpdateUnread {\n      _id\n    }\n    membersEndBookingUpdateUnread {\n      _id\n    }\n       \n";
export var ORDER_TRANSPORTATION_BASE = "\n    " + ORDER_TRANSPORTATION_BASE_1 + "\n     routes{\n       " + ORDER_ROUTE_BASE + "\n       }\n       cargoes{\n       " + ORDER_CARGO_BASE + "\n       }\n";
export var ORDER_TRANSPORTATION_FULL = "\n    " + ORDER_TRANSPORTATION_BASE + "\n      transports{\n          " + ORDER_QUOTE_TRANSPORT_BASE + "\n          }\n";
export var ORDER_TRANSPORTATION_BASE_2 = "\n  " + ORDER_TRANSPORTATION_BASE_1 + "\n     routes{\n       " + ORDER_ROUTE_BASE_2 + "\n       }\n";
export var ORDER_TRANSPORTATION_MORE = "\n" + ORDER_TRANSPORTATION_BASE_2 + "\n";
export var ORDER_TRANSPORTATION_BASE_MORE = "\n    " + ORDER_TRANSPORTATION_MORE + "\n        \n          unloadedAt \n          inTransitAt \n          isInTransitForced\n       \n          transports{\n          " + ORDER_QUOTE_TRANSPORT_BASE + "\n          }\n";
export var ORDER_BASE_TO_VIEW_MORE = "\n                transportations{\n                " + ORDER_TRANSPORTATION_BASE_MORE + "\n             }\n            ";
export var ORDER_BASE_TO_VIEW_SHORT = "\n                " + ORDER_BASE_TO_VIEW_MORE + "\n                cargoes{\n                    " + ORDER_CARGO_BASE + "\n                }\n            ";
export var STATS_DETAILS = "\n            _id\n            count";
