var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { BaseListItemV1 } from '../../components/BasicList/ListItem/BaseListItemV1';
import { AvatarItemView } from '../../components/Account/Avatar';
import { theme } from '../../theme';
import { ACTION_CHANGE, DeleteDialog, onPreventEvent, TextOverflow } from '../../components';
import { Grid } from '@material-ui/core';
import MenuListItem from '../../components/MenuListItem';
import IssueStatus from './IssueStatus';
import { dateViewFormat } from '../../components/DateView';
import { toast } from 'react-toastify';
import { DeleteIssue } from '../../graphql/issues';
import { inject } from 'mobx-react';
import { USER_ROLE_TOOLS } from '../../models/UserRoles';
import PreloadingComponent from '../../components/PreloadingComponent';
import AllUploadList from '../../components/Socket/Chat/ChannelChat/Uploads';
import FormItem from './FormItem';
import ItemHeaderFormItem from './ItemHeaderFormItem';
import OrderShortView from '../../components/Order/OrderShortView';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { BaseUserAppTYpe } from '../../stores';
import { wrapTextToId } from '../../utils';
import Attachments from '../../components/Uploads/Attachments';
function ItemHeader(_a) {
    var _this = this;
    var _b, _c, _d, _e;
    var onChange = _a.onChange, record = _a.record, _config = _a._config, _f = _a.store.accountStore, currentUser = _f.currentUser, currentCompany = _f.currentCompany, props = __rest(_a, ["onChange", "record", "_config", "store"]);
    var langMenu = useTranslation(KEYS.MENU);
    var fileInput = React.useRef();
    var formRef = React.useRef();
    var onDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    _a = props.config;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, props.config.client.mutate({
                            mutation: DeleteIssue,
                            variables: { _id: record._id },
                        })];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    _a;
                    onChange && onChange(record, ACTION_CHANGE.DELETED);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    toast.error(e_1.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var isParentCollectionOrderQuote = record.parent_collection_name === 'order_quotes';
    var isForwarder = currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var isCustomer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isForwarderAndParentCollectionOrderQuote = isParentCollectionOrderQuote && isForwarder;
    var is_owner = record && record.createdBy && record.createdBy._id === currentUser._id;
    var is_ownerCompany = ((_c = (_b = record === null || record === void 0 ? void 0 : record.createdBy) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c._id) === currentCompany._id;
    var canEdit = currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data); });
    var onClick = function (e) { };
    if (isParentCollectionOrderQuote) {
        onClick = function (e) {
            var _a;
            (_a = formRef === null || formRef === void 0 ? void 0 : formRef.current) === null || _a === void 0 ? void 0 : _a.click();
        };
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { "data-id": "file-select-" + wrapTextToId(record === null || record === void 0 ? void 0 : record._id), id: "file-select-record", type: 'file', style: { position: 'absolute', left: "-100vw", zIndex: 99999999 }, ref: fileInput }),
        React.createElement(FormItem, { label: true, withText: true, disabled: !(is_owner || (canEdit && is_ownerCompany)), item: record, onChange: onChange, config: props.config, actionOnInit: function (onOpen) { return React.createElement(React.Fragment, null, React.createElement("span", { onClick: onOpen, ref: formRef })); } }),
        React.createElement(BaseListItemV1, __assign({}, { onChange: onChange, record: record, onClick: onClick }, { v1: !isParentCollectionOrderQuote, headerColumns: _config.headerColumns, childs: [
                {
                    value: (record.createdBy && (React.createElement(AvatarItemView, { more: true, typographyProps: {
                            variant: 'h6',
                            style: {
                                fontSize: 14,
                                fontWeight: 600,
                                color: theme.palette.common.white,
                            },
                        }, user: record.createdBy }))) ||
                        null,
                },
                {
                    value: (React.createElement(Grid, { container: true, direction: 'column' },
                        React.createElement(TextOverflow, { variant: 'subtitle1', style: { lineHeight: 1 } }, record.title || '-'),
                        (!isParentCollectionOrderQuote && (React.createElement(TextOverflow, { variant: 'caption', style: { lineHeight: 1 } }, record.description || '-'))) ||
                            null)),
                },
                {
                    value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' }, (record.parent &&
                        ((!record.parent_collection_name && React.createElement(OrderShortView, { record: { order: record.parent } })) ||
                            (record.parent_collection_name === 'locations' && (React.createElement(Link, { to: "/" + record.parent_collection_name + "?search=" + record.parent._id }, langMenu.t("menu_sidebar_" + record.parent_collection_name + "_title")))))) ||
                        (isParentCollectionOrderQuote && (React.createElement(TextOverflow, { variant: 'caption', style: { lineHeight: 1 } }, record.description || '-'))) ||
                        '-')),
                },
                {
                    value: React.createElement(TextOverflow, null, dateViewFormat(record.createdAt, 1)),
                },
                {
                    value: props.isAdmin || (isParentCollectionOrderQuote && isCustomer) ? (React.createElement(ItemHeaderFormItem, { v1: true, item: record, config: props.config, onChange: props.parentOnChange })) : (React.createElement(IssueStatus, { record: record })),
                },
                {
                    value: (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'flex-end', onClick: onPreventEvent },
                        isParentCollectionOrderQuote && ((_d = record.files) === null || _d === void 0 ? void 0 : _d.length) ? (React.createElement(PreloadingComponent, null,
                            React.createElement(Attachments, { config: props.config, defaultFilter: { fields: [{ name: 'parent', value: record === null || record === void 0 ? void 0 : record._id }] } }))) : null,
                        (React.createElement(MenuListItem, { show: true, list: [
                                {
                                    content: (React.createElement(FormItem, { label: true, withText: true, fileInput: fileInput, disabled: !(is_owner || (canEdit && is_ownerCompany)), item: record, onChange: onChange, config: props.config })),
                                    available: is_owner || (canEdit && is_ownerCompany) || isForwarderAndParentCollectionOrderQuote,
                                },
                                {
                                    content: React.createElement(DeleteDialog, { withoutWrapper: true, withText: true, value: record.title, onOk: onDelete }),
                                    available: is_owner || (canEdit && is_ownerCompany),
                                },
                                {
                                    content: (React.createElement(PreloadingComponent, null,
                                        React.createElement(AllUploadList, { hideHeader: true, label: true, config: props.config, defaultFilter: { fields: [{ name: 'parent', value: (_e = record.chat) === null || _e === void 0 ? void 0 : _e._id }] } }))),
                                    available: (is_ownerCompany || props.isAdmin) && !isParentCollectionOrderQuote,
                                },
                            ] })) || null)),
                },
            ].map(function (e) { return (__assign({}, e)); }) }))));
}
export default inject('store')(ItemHeader);
