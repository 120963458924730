import React from 'react';
import { CompletePaperLayout } from '@izi-logistics/common/lib/components/CompletePaperLayout';
import { Redirect, Route, Switch } from 'react-router';
import OrderList from './pages/Orders/List';
import Account from './pages/Account/Account';
import Team from './pages/Team/Team';
import SetPassword from './pages/Auth/SetPassword/SetPassword';
import CustomerSidebarMenu from './components/common/CustomerSidebarMenu';
import { client } from './api/apolloClient';
import OrderFormV1 from './pages/Orders/OrderFormV1';
import LocationsList from './pages/Locations';
import DashboardList from './pages/Dashboard';
import MainTabsRoutes, { WarehousePage } from './pages/Warehouse';
import ChatContentComponent from './pages/Chat';
import InvoiceForm from './pages/Finance/Invoices/Form';
import CostForm from './pages/Finance/Costs/Form';
import Finance from './pages/Finance';
import Terms from './pages/Terms';
import { BaseUserAppTYpe } from '@izi-logistics/common/lib';
import Issues from './pages/Issues';

import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const Companies = React.lazy(() => import('./pages/Companies'));

const WarehouseRoute = () => (
  <Switch>
    <Route path="/warehouses/:warehouseId" component={MainTabsRoutes} />
    <Route exact path="/warehouses" component={WarehousePage} />
  </Switch>
);

export default function RootRoutes({ store }) {
  const routes = (
    <Switch>
      <Route
        exact
        path="/orders/:orderId/restore"
        component={(p: any) => <OrderFormV1 {...p} key={`restore-${p.match.params.orderId}`} />}
      />
      <Route exact path="/orders/create" component={(p: any) => <OrderFormV1 {...p} key={`create`} />} />
      <Route
        exact
        path="/orders/:orderId"
        component={(p: any) => <OrderFormV1 {...p} key={`create-${p.match.params.orderId}`} />}
      />
      <Route exact path="/finance/invoices/create" component={() => <InvoiceForm key={'invoice-create'} />} />
      <Route
        exact
        path="/finance/invoices/:invoiceId/credit"
        component={() => <InvoiceForm key={'invoice-credit'} />}
      />
      <Route exact path="/finance/invoices/:invoiceId" component={() => <InvoiceForm key={'invoice-form'} />} />
      <Route
        exact
        path="/finance/invoices-templates/:invoiceId"
        component={() => <InvoiceForm key={'invoices-templates-form'} />}
      />
      <Route exact path="/finance/costs/create" component={() => <CostForm key={'cost-create'} />} />
      <Route
        exact
        path="/finance/costs/:invoiceId/request-refund"
        component={() => <CostForm key={'request-refund'} />}
      />
      <Route exact path="/finance/costs/:invoiceId" component={() => <CostForm key={'cost'} />} />
      <Route exact path="/finance/costs-templates/:invoiceId" component={() => <CostForm key={'costs-templates'} />} />
      <Route path="/finance" component={Finance} />

      <Route exact path="/terms" component={Terms} />
      <Route exact path="/issues" component={Issues} />
      <Route exact path="/team" component={Team} />
      <Route exact path="/change-password" component={SetPassword} />
      <Route exact path="/archive" component={(p: any) => <OrderList {...p} key={p.match.url} />} />
      <Route exact path="/drafts" component={(p: any) => <OrderList {...p} key={p.match.url} />} />
      <Route
        exact
        path="/templates"
        component={(p: any) => <OrderList {...p} searchFields={['templateName']} key={p.match.url} />}
      />
      <Route exact path="/preparation" component={(p: any) => <OrderList {...p} key={p.match.url} />} />
      <Route exact path="/open-orders" component={(p: any) => <OrderList {...p} key={p.match.url} />} />
      <Route exact path="/in-progress" component={(p: any) => <OrderList {...p} key={p.match.url} />} />
      <Route exact path="/account/payment-term" component={Account} />
      <Route exact path="/account/details" component={Account} />
      <Route exact path="/account/settings" component={Account} />
      <Route path="/account">
        <Redirect to={'/account/details'} />
      </Route>
      <Route path="/warehouses" component={WarehouseRoute} />
      <Route path="/chats" component={ChatContentComponent} />
      <Route path="/locations" component={LocationsList} />
      <Route path="/dashboard" component={DashboardList} />
      <Route
        exact
        path="/carriers"
        component={(p: any) => (
          <PreloadingComponent>
            <Companies {...p} />
          </PreloadingComponent>
        )}
      />
      <Route path="*">
        <Redirect to="/open-orders" />
      </Route>
    </Switch>
  );
  return (
    <CompletePaperLayout
      hasAffiliate
      appType={BaseUserAppTYpe.CUSTOMER}
      config={{ client }}
      sideBarMenuContent={<CustomerSidebarMenu />}
      pageContent={routes}
    />
  );
}
