var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { types, castToSnapshot, applySnapshot } from 'mobx-state-tree';
import { OrderRoute } from './OrderRoute';
import { OrderCargo } from './OrderCargo';
import { BaseUser, MaybeEmptyModel, AsyncActionStatus, UserForwarder } from '../stores/common';
import { OrderQuotes } from './OrderQuotes';
import { OrderVehicleType } from './OrderVehicleType';
import { OrderTransportation } from './OrderTransportation';
import { OrderPayment } from './OrderPayment';
import { OrderCurrency } from './types';
import { OrderCertificate } from './OrderCertificate';
import { BaseModel } from './BaseModel';
import { OrderLocationAddress } from './LocationAddress';
import { OrderPreparingStatuses } from './OrderPreparingStatuses';
import { OrderStatus } from './Order_additional';
export { OrderStatus };
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var OrderQuoteState;
(function (OrderQuoteState) {
    OrderQuoteState["Confirmed"] = "confirmed";
    OrderQuoteState["Transport"] = "transport-assigned";
    OrderQuoteState["TimeSelected"] = "time-selected";
    OrderQuoteState["InTransit"] = "in-transit";
    OrderQuoteState["Unloaded"] = "unloaded";
    // DocumentsAttached = "documents-attached",
})(OrderQuoteState || (OrderQuoteState = {}));
export var OrderQuoteStatusState;
(function (OrderQuoteStatusState) {
    OrderQuoteStatusState["Confirmed"] = "confirmed";
    OrderQuoteStatusState["Transport"] = "transportAssigned";
    OrderQuoteStatusState["TimeSelected"] = "timeSelected";
    OrderQuoteStatusState["InTransit"] = "inTransit";
    OrderQuoteStatusState["Unloaded"] = "unloaded";
    // DocumentsAttached = "documents-attached",
})(OrderQuoteStatusState || (OrderQuoteStatusState = {}));
export var OrderMenu;
(function (OrderMenu) {
    OrderMenu["REQUESTED"] = "Offer requested";
    OrderMenu["QUOTED"] = "Offer submitted";
    OrderMenu["ACCEPTED"] = "Offer accepted";
})(OrderMenu || (OrderMenu = {}));
export var OrderMenuId = (_a = {},
    _a[OrderMenu.REQUESTED.toLowerCase().replace(/ /, '-')] = OrderStatus.WaitingQuotes,
    _a[OrderMenu.QUOTED.toLowerCase().replace(/ /, '-')] = OrderStatus.HasQuotes,
    _a[OrderMenu.ACCEPTED.toLowerCase().replace(/ /, '-')] = OrderStatus.AcceptedQuote,
    _a);
export var DeliveryTerms;
(function (DeliveryTerms) {
    DeliveryTerms["EXW"] = "EXW";
    DeliveryTerms["FCA"] = "FCA";
    DeliveryTerms["FAS"] = "FAS";
    DeliveryTerms["FOB"] = "FOB";
    DeliveryTerms["CFR"] = "CFR";
    DeliveryTerms["CIF"] = "CIF";
    DeliveryTerms["CPT"] = "CPT";
    DeliveryTerms["CIP"] = "CIP";
    DeliveryTerms["DAP"] = "DAP";
    DeliveryTerms["DPU"] = "DPU";
    DeliveryTerms["DDP"] = "DDP";
})(DeliveryTerms || (DeliveryTerms = {}));
export var OrderLoadingType;
(function (OrderLoadingType) {
    OrderLoadingType["Top"] = "Top";
    OrderLoadingType["Side"] = "Side";
    OrderLoadingType["Rear"] = "Rear";
    OrderLoadingType["Other"] = "Other";
})(OrderLoadingType || (OrderLoadingType = {}));
export var OrderStrap;
(function (OrderStrap) {
    OrderStrap["Indifferent"] = "Indifferent";
    OrderStrap["TYPE_1"] = "TYPE_1";
    OrderStrap["another"] = "another";
})(OrderStrap || (OrderStrap = {}));
export var OrderSideBoards;
(function (OrderSideBoards) {
    OrderSideBoards["Indifferent"] = "Indifferent";
    OrderSideBoards["TYPE_1"] = "TYPE_1";
    OrderSideBoards["another"] = "another";
})(OrderSideBoards || (OrderSideBoards = {}));
export var OrderWeight;
(function (OrderWeight) {
    OrderWeight["Pounds"] = "lbs";
    OrderWeight["Tonnes"] = "t";
    OrderWeight["Kilogram"] = "kg";
    OrderWeight["Gram"] = "g";
})(OrderWeight || (OrderWeight = {}));
export var OrderTemperatureMode;
(function (OrderTemperatureMode) {
    OrderTemperatureMode["Persistent"] = "Persistent";
    OrderTemperatureMode["Automatic"] = "Automatic";
})(OrderTemperatureMode || (OrderTemperatureMode = {}));
export var ORDER_PREPARE_MODE = {
    SELF_ORGANIZE: 1,
    AS_INNER_ORDER: 2,
};
export var OrderPreparingStatusesState = types
    .compose(AsyncActionStatus, types
    .model('OrderPreparingStatusesState', {
    _id: types.optional(types.string, function () { return ''; }),
    value: types.maybeNull(MaybeEmptyModel(OrderPreparingStatuses)),
    assignedAt: types.maybeNull(types.string),
    estimatedAt: types.maybeNull(types.string),
    plannedPickupStartDate: types.maybeNull(types.string),
    plannedPickupEndDate: types.maybeNull(types.string),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, __assign({}, snapshot));
    },
}); })
    .views(function (self) { return ({}); }))
    .named('OrderPreparingStatusesState');
export var Order = types
    .compose(AsyncActionStatus, types
    .model('Order', {
    _id: types.optional(types.string, function () { return ''; }),
    createdBy: types.maybeNull(MaybeEmptyModel(BaseUser)),
    deletedAt: types.maybeNull(types.optional(types.string, '')),
    company: types.maybeNull(MaybeEmptyModel(BaseModel)),
    indexId: types.maybeNull(types.union(types.optional(types.string, ''), types.optional(types.number, 0))),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    orderId: types.maybeNull(types.string),
    orderCustomId: types.maybeNull(types.string),
    canP44Sync: types.maybeNull(types.optional(types.boolean, false)),
    timePickupBooked: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    distance: types.maybeNull(types.optional(types.string, '')),
    timeIsPickup: types.maybeNull(types.number),
    timeToSpend: types.maybeNull(types.number),
    cargoType: types.maybeNull(types.optional(types.string, '')),
    exportDeclarationAddress: types.maybeNull(types.optional(OrderLocationAddress, function () { return OrderLocationAddress.create(); })),
    importDeclarationAddress: types.maybeNull(types.optional(OrderLocationAddress, function () { return OrderLocationAddress.create(); })),
    exportDeclaration: types.maybeNull(types.optional(types.boolean, false)),
    exportDeclarationBrokerCompany: types.maybeNull(types.optional(types.string, '')),
    exportDeclarationContacts: types.maybeNull(types.optional(types.string, '')),
    importDeclaration: types.maybeNull(types.optional(types.boolean, false)),
    importDeclarationBrokerCompany: types.maybeNull(types.optional(types.string, '')),
    importDeclarationContacts: types.maybeNull(types.optional(types.string, '')),
    temperatureMode: types.maybeNull(types.optional(types.string, '')),
    temperatureFrom: types.maybeNull(types.number),
    temperatureTo: types.maybeNull(types.number),
    forwarders: types.maybeNull(types.optional(types.array(MaybeEmptyModel(UserForwarder)), function () { return []; })),
    forwarderOrderMarkUpPercentage: types.optional(types.maybeNull(types.number), 0),
    forwarderOrderMarkUpMinPrice: types.maybeNull(types.optional(types.number, 0)),
    forwarderOrderMarkUpMinPriceCurrency: types.maybeNull(types.optional(types.enumeration(OrderCurrency.USD, Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    forwarderOrderMarkUpMaxPriceCurrency: types.maybeNull(types.optional(types.enumeration(OrderCurrency.USD, Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    forwarderOrderMarkUpMaxPrice: types.maybeNull(types.optional(types.number, 0)),
    templateName: types.maybeNull(types.string),
    manufacturingConfirmedAt: types.maybeNull(types.string),
    manufacturingInProgressAt: types.maybeNull(types.string),
    sorting_countryPickup: types.maybeNull(types.string),
    sorting_countryDelivery: types.maybeNull(types.string),
    sorting_deliveryAt: types.maybeNull(types.string),
    sorting_pickupAt: types.maybeNull(types.string),
    sorting_totalWeight: types.maybeNull(types.string),
    comment: types.maybeNull(types.string),
    cancelComment: types.maybeNull(types.string),
    transport_other: types.maybeNull(types.string),
    commentCMR: types.maybeNull(types.string),
    cancelAt: types.maybeNull(types.string),
    noQuotes: types.maybeNull(types.boolean),
    publishedAt: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    isTest: types.maybeNull(types.boolean),
    isGPM: types.maybeNull(types.boolean),
    isTemplate: types.maybeNull(types.boolean),
    asTemplate: types.maybeNull(types.boolean),
    isADR: types.maybeNull(types.boolean),
    isOnlyToMyCA: types.maybeNull(types.boolean),
    isCargoCornerProtector: types.maybeNull(types.boolean),
    isAntiSplitMats: types.maybeNull(types.boolean),
    isTent: types.maybeNull(types.boolean),
    currency: types.maybeNull(types.optional(types.enumeration(OrderCurrency.USD, Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    strap: types.maybeNull(types.optional(types.string, 'standard')),
    sideBoards: types.maybeNull(types.optional(types.string, 'standard')),
    acceptedQuote: types.maybeNull(MaybeEmptyModel(OrderQuotes)),
    latestQuote: types.maybeNull(MaybeEmptyModel(OrderQuotes)),
    manager: types.maybeNull(MaybeEmptyModel(BaseUser)),
    status: types.optional(types.enumeration('Isotherm', Object.values(OrderStatus)), OrderStatus.Draft),
    routes: types.optional(types.array(MaybeEmptyModel(OrderRoute)), []),
    transportations: types.optional(types.array(MaybeEmptyModel(OrderTransportation)), function () { return [
        OrderTransportation.create(),
    ]; }),
    cargoes: types.optional(types.array(MaybeEmptyModel(OrderCargo)), []),
    vehicleType: types.optional(types.array(MaybeEmptyModel(OrderVehicleType)), function () { return []; }),
    certificates: types.optional(types.array(MaybeEmptyModel(OrderCertificate)), function () { return []; }),
    loadingType: types.optional(types.array(types.string), function () { return []; }),
    deliveryTerms: types.maybeNull(types.optional(types.array(types.string), function () { return []; })),
    membersSawRatingPromotion: types.maybeNull(types.optional(types.array(MaybeEmptyModel(UserForwarder)), function () { return []; })),
    hasPreparing: types.maybeNull(types.boolean),
    preparingComment: types.maybeNull(types.string),
    preparedAt: types.maybeNull(types.optional(types.string, '')),
    preparingStatuses: types.maybeNull(types.optional(types.array(MaybeEmptyModel(OrderPreparingStatusesState)), function () { return []; })),
    preparationFinishedAt: types.maybeNull(types.optional(types.string, '')),
})
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(Order.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        //переустановка без согласования с сервером
        applySnapshot(self, __assign(__assign({}, snapshot), { updatedAt: new Date().toString() }));
    },
}); })
    .views(function (self) { return ({}); }))
    .named('Order');
export var OrderMore = types
    .compose(AsyncActionStatus, types
    .model('OrderMore', {
    _id: types.optional(types.string, function () { return ''; }),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    createdAt: types.maybeNull(types.optional(types.string, '')),
    deletedAt: types.maybeNull(types.optional(types.string, '')),
    orderId: types.maybeNull(types.string),
    orderCustomId: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    createdBy: types.maybeNull(MaybeEmptyModel(BaseUser)),
    company: types.maybeNull(MaybeEmptyModel(BaseModel)),
    lastPayment: types.maybeNull(MaybeEmptyModel(OrderPayment)),
    forwarderOrderMarkUpPercentage: types.optional(types.maybeNull(types.number), 0),
    forwarderOrderMarkUpMinPrice: types.maybeNull(types.optional(types.number, 0)),
    forwarderOrderMarkUpMinPriceCurrency: types.maybeNull(types.optional(types.enumeration(OrderCurrency.USD, Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    forwarderOrderMarkUpMaxPriceCurrency: types.maybeNull(types.optional(types.enumeration(OrderCurrency.USD, Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    forwarders: types.maybeNull(types.optional(types.array(MaybeEmptyModel(UserForwarder)), function () { return []; })),
    distance: types.maybeNull(types.optional(types.string, '')),
    forwarderOrderMarkUpMaxPrice: types.maybeNull(types.optional(types.number, 0)),
    templateName: types.maybeNull(types.string),
    transport_other: types.maybeNull(types.string),
    sorting_countryPickup: types.maybeNull(types.string),
    sorting_countryDelivery: types.maybeNull(types.string),
    sorting_deliveryAt: types.maybeNull(types.string),
    sorting_pickupAt: types.maybeNull(types.string),
    sorting_totalWeight: types.maybeNull(types.string),
    comment: types.maybeNull(types.string),
    cancelComment: types.maybeNull(types.string),
    commentCMR: types.maybeNull(types.string),
    cancelAt: types.maybeNull(types.string),
    publishedAt: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    isTest: types.maybeNull(types.boolean),
    isGPM: types.maybeNull(types.boolean),
    isTemplate: types.maybeNull(types.boolean),
    asTemplate: types.maybeNull(types.boolean),
    isADR: types.maybeNull(types.boolean),
    isOnlyToMyCA: types.maybeNull(types.boolean),
    isCargoCornerProtector: types.maybeNull(types.boolean),
    isAntiSplitMats: types.maybeNull(types.boolean),
    isTent: types.maybeNull(types.boolean),
    currency: types.maybeNull(types.optional(types.enumeration(OrderCurrency.USD, Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    strap: types.maybeNull(types.optional(types.string, 'standard')),
    sideBoards: types.maybeNull(types.optional(types.string, 'standard')),
    acceptedQuote: types.maybeNull(MaybeEmptyModel(OrderQuotes)),
    latestQuote: types.maybeNull(MaybeEmptyModel(OrderQuotes)),
    manager: types.maybeNull(MaybeEmptyModel(BaseUser)),
    status: types.optional(types.enumeration('Isotherm', Object.values(OrderStatus)), OrderStatus.Draft),
    routes: types.optional(types.array(MaybeEmptyModel(OrderRoute)), []),
    transportations: types.optional(types.array(MaybeEmptyModel(OrderTransportation)), function () { return [
        OrderTransportation.create(),
    ]; }),
    cargoes: types.optional(types.array(MaybeEmptyModel(OrderCargo)), []),
    vehicleType: types.optional(types.array(MaybeEmptyModel(OrderVehicleType)), function () { return []; }),
    loadingType: types.optional(types.array(types.string), function () { return []; }),
    deliveryTerms: types.maybeNull(types.optional(types.array(types.string), function () { return []; })),
})
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(Order.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        //переустановка без согласования с сервером
        applySnapshot(self, __assign(__assign({}, snapshot), { updatedAt: new Date().toString() }));
    },
}); })
    .views(function (self) { return ({}); }))
    .named('Order');
