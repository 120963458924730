var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useState } from 'react';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useField } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextOverflow } from '../TextOverflow';
import { useChipStyles, useInputStyles } from '../../styles/form';
import { Grid } from '@material-ui/core';
import { theme } from '../../theme';
import { HelpIconComponent } from '../HelpIcon';
import InputTitle from './InputTitle';
import BaseTooltip from '../BaseTooltip';
import { useStyles as useStylesFormik } from './styles';
import BaseChip from '../BaseChip';
import { BaseTextField } from './BaseTextField';
export var FormikAutocomplete = function (props) {
    var helperText = props.helperText, options = props.options, autocompleteProps = props.autocompleteProps, chipProps = props.chipProps, title = props.title, disabled = props.disabled, _a = props.margin, margin = _a === void 0 ? 'dense' : _a, _b = props.fullWidth, fullWidth = _b === void 0 ? true : _b, _c = props.variant, variant = _c === void 0 ? 'standard' : _c, inputProps = __rest(props, ["helperText", "options", "autocompleteProps", "chipProps", "title", "disabled", "margin", "fullWidth", "variant"]);
    var classes = useChipStyles();
    var classes1 = useInputStyles();
    var _d = __read(useField(inputProps), 2), field = _d[0], _e = _d[1], error = _e.error, touched = _e.touched;
    var _f = __read(React.useState(null), 2), tempVal = _f[0], setTempVal = _f[1];
    var touchedError = touched && error;
    var classesFormik = useStylesFormik();
    var _g = __read(useState(false), 2), isFocused = _g[0], setIsFocused = _g[1];
    var onChange = function (e, value) {
        var _a, _b, _c;
        var options = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            options[_i - 2] = arguments[_i];
        }
        try {
            if ((value === null || value === void 0 ? void 0 : value.length) && ((_a = props.options) === null || _a === void 0 ? void 0 : _a.length)) {
                var _loop_1 = function (i) {
                    var label = ((_b = value[i]) === null || _b === void 0 ? void 0 : _b.label) || value[i];
                    if (props.options.find(function (e) { return label === e.label || label === e.value; })) {
                        return "continue";
                    }
                    var similar = props.options.find(function (e) { return e.label.match(new RegExp(label, 'i')); });
                    if (similar) {
                        value[i] = ((_c = value[i]) === null || _c === void 0 ? void 0 : _c.label) ? similar : similar.label;
                    }
                };
                for (var i = 0; i < value.length; i++) {
                    _loop_1(i);
                }
                var lastOption = value[value.length - 1];
                var label1_1 = (lastOption === null || lastOption === void 0 ? void 0 : lastOption.label) || lastOption;
                if (!props.options.find(function (e) { return label1_1 === e.label; }))
                    return;
            }
            value = __spread(new Set(value));
        }
        catch (e) {
            console.log(e);
        }
        if (Array.isArray(value) && value.length && typeof value[0] === 'string') {
            value = value
                .map(function (e) { return (typeof e === 'string' ? e.trim() : e); })
                .filter(function (e) { return (typeof e === 'string' ? e.length : e); });
        }
        (props.onChange || autocompleteProps.onChange).apply(void 0, __spread([{
                target: __assign(__assign({}, inputProps), { value: value }),
            }, value], options));
        setTempVal(value);
    };
    var _onChange = function (e) {
        var options = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            options[_i - 1] = arguments[_i];
        }
        if (props.onChageText)
            return props.onChageText(e);
        props.onChange.apply(props, __spread([e], options));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InputTitle, __assign({}, props)),
        React.createElement(Autocomplete, __assign({ disabled: disabled, style: inputProps.style, ChipProps: __assign(__assign({}, chipProps), { className: classes.chipRoot }), options: options, popupIcon: React.createElement(ExpandMoreIcon, null) }, autocompleteProps, { classes: { root: classes1.root }, onChange: onChange, renderInput: function (params) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                var _m, _o, _p, _q, _r, _s, _t;
                var moreOptions = __assign({}, (inputProps.readOnly && { readOnly: true }));
                var noTitle = true;
                if (inputProps.placeholder) {
                    Object.assign(moreOptions, {
                        // endAdornment: <HelpIconComponent title={inputProps.placeholder} />,
                        style: __assign(__assign({}, inputProps.style), { paddingRight: 6 }),
                    });
                }
                if (moreOptions && params.InputProps) {
                    Object.assign(params.InputProps, moreOptions);
                }
                if (moreOptions && params.inputProps) {
                    Object.assign(params.inputProps, moreOptions);
                }
                if (inputProps.help && !moreOptions.endAdornment && !title) {
                    Object.assign(moreOptions, {
                        endAdornment: React.createElement(HelpIconComponent, { title: inputProps.help }),
                    });
                }
                var startAdornment = (React.createElement("span", { className: 'start-icon', style: { display: 'flex', alignItems: 'center' } },
                    React.createElement(HelpIconComponent, { title: inputProps.help })));
                if (inputProps.help && !props.v19 && !moreOptions.startAdornment) {
                    Object.assign(moreOptions, {
                        startAdornment: startAdornment,
                    });
                    if (params.InputProps) {
                        if (Array.isArray(params.InputProps.startAdornment)) {
                            params.InputProps.startAdornment.splice(0, 0, startAdornment);
                            noTitle = false;
                        }
                        else {
                            params.InputProps.startAdornment = startAdornment;
                            noTitle = false;
                        }
                    }
                }
                var style = {
                    marginTop: 0,
                    marginBottom: 0,
                };
                if (props.style)
                    Object.assign(style, props.style);
                if (!params.inputProps.style)
                    params.inputProps.style = {};
                // if (disabled && Array.isArray(field.value) && field.value.length && Object.keys(field.value[0]).length > 0)
                //   Object.assign(params.inputProps.style, { display: 'none' });
                var titlePlace = inputProps.placeholder || (typeof title === 'string' && title) || null;
                if (noTitle)
                    Object.assign(params, { title: titlePlace });
                var errorText = touchedError || helperText;
                if (Array.isArray(errorText)) {
                    errorText = errorText.filter(function (e) { return (e || '').toString().trim(); }).join(', ');
                }
                var onBlur = function (e) {
                    if (props.onBlur) {
                        props.onBlur(e);
                    }
                    setIsFocused(false);
                };
                var _inputProps = (inputProps === null || inputProps === void 0 ? void 0 : inputProps.inputProps) || (params === null || params === void 0 ? void 0 : params.inputProps);
                return (React.createElement(BaseTextField, __assign({}, (_a = {}, _a["data-title-1"] = titlePlace || title, _a), { className: clsx(classesFormik.base, classes1.root2, (_b = {}, _b[classesFormik.baseStartAdornment] = moreOptions === null || moreOptions === void 0 ? void 0 : moreOptions.startAdornment, _b), (_c = {}, _c[classes1.adorRootV17] = props.v17, _c), (_d = {}, _d[classes1.adorRootV25] = props.v25, _d), (_e = {}, _e[classesFormik.baseV1] = props.v26, _e), (_f = {}, _f[classesFormik.baseV1] = !isFocused && props.v27, _f), (_g = {},
                        _g[props.v25 ? classes1.adorRootV25WithValues : classes1.adorRootV17WithValues] = Array.isArray(field.value) && ((_m = field.value) === null || _m === void 0 ? void 0 : _m.length),
                        _g), (_h = {},
                        _h[props.v25 ? classes1.adorRootV25WithoutValues : classes1.adorRootV17WithoutValues] = Array.isArray(field.value) && !((_o = field.value) === null || _o === void 0 ? void 0 : _o.length),
                        _h), (_j = {},
                        _j[classes1.adorRootV25Focused] = props.v25 && isFocused,
                        _j), (_k = {}, _k[classes1.root] = Array.isArray(field.value) && ((_p = field.value) === null || _p === void 0 ? void 0 : _p.length) > 1, _k), (_l = {}, _l[classes1.root1] = disabled && Array.isArray(field.value) && ((_q = field.value) === null || _q === void 0 ? void 0 : _q.length) > 0, _l)), id: props.id || (props.title || 'text-autocmplete-input').toString().replace(/ /gi, '').toLowerCase(), ref: function (input) {
                        if (input) {
                            input.setAttribute('maxLength', '1000');
                        }
                    }, variant: variant, margin: margin, fullWidth: fullWidth }, inputProps, field, params, { inputProps: __assign(__assign({}, _inputProps), { onKeyDown: function (e) {
                            var _a = e.target, name = _a.name, stringValue = _a.value;
                            if (e.key === 'Enter') {
                                if (!stringValue || stringValue === '')
                                    e.stopPropagation();
                                if (props.onEnter)
                                    props.onEnter(e);
                            }
                        } }), InputLabelProps: {
                        shrink: !!(((_r = inputProps === null || inputProps === void 0 ? void 0 : inputProps.value) === null || _r === void 0 ? void 0 : _r.length) ||
                            (Array.isArray(field.value) ? (_s = field.value) === null || _s === void 0 ? void 0 : _s.length : field.value) ||
                            params.inputProps.value ||
                            (Array.isArray(tempVal) && (tempVal === null || tempVal === void 0 ? void 0 : tempVal.length)) ||
                            (props.v24 && inputProps.value === '') ||
                            (props.v25 && ((_t = params.InputProps.startAdornment) === null || _t === void 0 ? void 0 : _t.length) > 1)),
                    }, disabled: disabled, style: style, onChange: _onChange, onBlur: onBlur, onFocus: function () { return setIsFocused(true); }, autoComplete: "off", error: !!touchedError, helperText: errorText && React.createElement(TextOverflow, { v2: true }, errorText), required: false, label: !(props.v1 || props.v14) ? titlePlace : '', placeholder: !(props.v1 || props.v14) ? '' : titlePlace, title: '' })));
            } }))));
};
var optionLabel = function (option) {
    return (option &&
        ((option.orderId && [option.orderId, option.status].filter(function (e) { return e; }).join(',')) || option.label || option.name)) ||
        option.toString() ||
        '';
};
var optionLabelV1 = function (option) {
    return option.fullName ||
        [option.firstName, option.lastName].filter(function (e) { return e; }).join(' ') ||
        (option.value &&
            ([option.value.firstName, option.value.lastName].filter(function (e) { return e; }).join(' ') ||
                [option.value.name].filter(function (e) { return e; }).join(' '))) ||
        option.name ||
        '';
};
export var formikAutocompleteMultiOptionsProps = function (defaultValue, onBlur, onDeleteEmail, options, opt) {
    if (options === void 0) { options = []; }
    return ({
        variant: 'outlined',
        margin: 'dense',
        fullWidth: true,
        options: options,
        autocompleteProps: {
            multiple: true,
            freeSolo: true,
            defaultValue: defaultValue,
            onBlur: onBlur,
            popupIcon: React.createElement(ExpandMoreIcon, null),
            getOptionSelected: function (option, value) {
                return option === value;
            },
            getOptionLabel: function (option) {
                return ((option && (option.name || option.label || [option.orderId, option.status].filter(function (e) { return e; }).join(','))) ||
                    (typeof option === 'string' && option) ||
                    '-').toString();
            },
            renderTags: function (value, getTagProps) {
                var re = (Array.isArray(value) && value) || [];
                if (Array.isArray(re) && re.length && typeof re[0] === 'string') {
                    re = re
                        .map(function (e) { return (typeof e === 'string' ? e.trim() : e); })
                        .filter(function (e) { return (typeof e === 'string' ? e.length : e); });
                }
                var optionContent = function (option, index) {
                    return option && (React.createElement(BaseChip, __assign({}, (opt && opt.disabled && { color: 'primary' }), { label: React.createElement(TextOverflow, { variant: 'subtitle1', style: {
                            // ...(opt && opt.disabled && { color: theme.palette.common.white }),
                            // ...(opt &&
                            //   opt.chipProps &&
                            //   opt.chipProps.color === 'primary' && { color: theme.palette.common.white }),
                            } }, optionLabel(option)) }, getTagProps({ index: index }), (onDeleteEmail && { onDelete: function () { return onDeleteEmail(option); } }), (opt && opt.disabled && { onDelete: null }), { style: __assign(__assign(__assign({ maxWidth: "calc(100% - " + ((opt === null || opt === void 0 ? void 0 : opt.v25) ? (index === 0 ? '35' : '12') : '70') + "px)", margin: theme.spacing(0.5, 0.5, 0.5, 0) }, (opt && opt.chipStyleProps)), (re.length > 1 && { marginRight: theme.spacing(0.5) })), { height: 24 }) }, (opt && opt.chipProps))));
                };
                if (0 && re.length > 1 && (!opt || (opt && !opt.disabled))) {
                    return [
                        optionContent(re[0], 0),
                        React.createElement(BaseTooltip, { title: re
                                .filter(function (e, i) { return i > 0; })
                                .map(optionLabel)
                                .join(', ') },
                            React.createElement(BaseChip, __assign({}, getTagProps({ index: 1 }), (onDeleteEmail && { onDelete: function () { return onDeleteEmail(re[1]); } }), (opt && opt.disabled && { onDelete: null }), { style: __assign(__assign(__assign(__assign({ height: 24, maxWidth: "calc(100% - " + ((opt === null || opt === void 0 ? void 0 : opt.v25) ? '12' : '50') + "px)" }, (opt && opt.chipStyleProps)), { margin: theme.spacing(0.5, 0.5, 0.5, 0) }), (opt && opt.chipStyleProps)), (re.length > 1 && { marginRight: theme.spacing(0.5) })) }, (opt && opt.chipProps), { label: re.length - 1 + "+" }))),
                    ];
                }
                return re.map(optionContent);
            },
        },
    });
};
export var formikAutocompleteMultiOptionsUsersProps = function (defaultValue, onBlur, opt) { return ({
    variant: 'outlined',
    margin: 'dense',
    fullWidth: true,
    options: [],
    autocompleteProps: {
        multiple: true,
        // freeSolo: true,
        defaultValue: defaultValue,
        onBlur: onBlur,
        popupIcon: React.createElement(ExpandMoreIcon, null),
        getOptionSelected: function (option, value) { return option._id === value._id; },
        getOptionLabel: function (option) {
            return ([
                option.fullName || [option.firstName, option.lastName].filter(function (e) { return e; }).join(', '),
                option.company && option.company.name,
                option.company && option.company.type,
            ]
                .filter(function (e) { return e; })
                .join(', ') || [option.name].filter(function (e) { return e; }).join(', '));
        },
        renderTags: function (value, getTagProps) {
            var re = (Array.isArray(value) && value) || [];
            var optionContent = function (option, index) {
                return (option && (React.createElement(BaseChip, __assign({ label: React.createElement(Grid, { container: true, direction: 'column' },
                        React.createElement(TextOverflow, { variant: 'subtitle2', style: {
                                lineHeight: 1.1,
                            } }, optionLabelV1(option)),
                        (option.company && (React.createElement(TextOverflow, { variant: 'caption', style: { lineHeight: 1 } }, [option.company.name, option.company.type].filter(function (e) { return e; }).join(', ')))) ||
                            (option.value && option.value.company && (React.createElement(TextOverflow, { variant: 'caption', style: { lineHeight: 1 } }, [option.value.company.name, option.value.company.type].filter(function (e) { return e; }).join(', ')))) ||
                            (option.value && option.value.company && (React.createElement(TextOverflow, { variant: 'caption', style: { lineHeight: 1 } }, [option.name].filter(function (e) { return e; }).join(', ')))) ||
                            null) }, getTagProps({ index: index }), { style: __assign(__assign(__assign({ maxWidth: 'calc(100% - 50px)', margin: theme.spacing(0, 0.5, 0, 0) }, (re.length > 1 && index === 0 && { marginTop: theme.spacing(0.5) })), { height: 24 }), (opt && opt.chipStyleProps)) }, (opt && opt.chipProps)))));
            };
            if (0 && re.length > 1) {
                return (React.createElement(Grid, { container: true, style: { width: 'initial' }, alignItems: 'center' },
                    re.filter(function (e, index) { return index < 1; }).map(optionContent),
                    re
                        .filter(function (e, index) { return index < 1; })
                        .map(function (option, index) { return (React.createElement(BaseTooltip, { title: re
                            .filter(function (e, i) { return i > 0; })
                            .map(optionLabelV1)
                            .join(', ') },
                        React.createElement(BaseChip, __assign({}, getTagProps({ index: index + 1 }), { style: __assign({ maxWidth: 'calc(100% - 50px)', margin: theme.spacing(0, 0.5, 0, 0), height: 24 }, (opt && opt.chipStyleProps)) }, (opt && opt.chipProps), { label: re.length - 1 + "+" })))); })));
            }
            return re.map(optionContent);
        },
    },
}); };
