var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { Grid, List, ListItem, useTheme } from '@material-ui/core';
import { LanguageMenu } from '../LanguageMenu';
import { Link, withRouter } from 'react-router-dom';
import { MenuItem, useMenuItemStyles } from './MenuItem';
import { InProgressCollapseItem } from './CollapseItem';
import { globalUtils } from '../../utils';
import { SOUND_KEY } from '../Sounds';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { onPreventEvent } from '../Dialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
var filterAdvancedItems = function (isShowOthersEnabled) { return function (menu) {
    if (isShowOthersEnabled)
        return menu;
    return menu.filter(function (item) { return !item.isHiddenUnderOther; });
}; };
function BasicSideBarComponent(_a) {
    var location = _a.location, menu = _a.menu, config = _a.config, store = _a.store, isShowOthersEnabled = _a.isShowOthersEnabled, props = __rest(_a, ["location", "menu", "config", "store", "isShowOthersEnabled"]);
    var classesMenuItem = useMenuItemStyles();
    var _isShowOthersEnabled = isShowOthersEnabled || false;
    var theme = useTheme();
    var _b = __read(React.useState(!_isShowOthersEnabled), 2), showOther = _b[0], setShowOther = _b[1];
    var _menu = filterAdvancedItems(showOther)(menu);
    var langApp = useTranslation(KEYS.APP);
    var onShowMore = function (e) {
        onPreventEvent(e);
        setShowOther(true);
        globalUtils.sounds.play(SOUND_KEY.BUTTON_CLICK);
    };
    var showOtherButton = _isShowOthersEnabled && !showOther ? (React.createElement(MenuItem, { "data-id": 'show_others', onClick: onShowMore, key: 'show_others', selected: false, title: langApp.t('show_others'), icon: React.createElement(ExpandMoreIcon, null), id: 99 })) : null;
    return (React.createElement(Grid, { container: true, direction: "column", justify: "space-between" },
        React.createElement(List, { style: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 'calc(100vh - 74px)',
            } },
            React.createElement("div", { style: { width: '100%' } },
                (_menu.length &&
                    _menu.map(function (item) {
                        if (item.client)
                            return React.createElement(InProgressCollapseItem, __assign({ key: item.path }, item));
                        var props = {};
                        var selected = item.path && location.pathname.toLowerCase().startsWith(item.path);
                        if (item.onClick) {
                            props.onClick = function () {
                                var e = [];
                                for (var _i = 0; _i < arguments.length; _i++) {
                                    e[_i] = arguments[_i];
                                }
                                globalUtils.sounds.play(SOUND_KEY.BUTTON_CLICK);
                                item.onClick.apply(item, __spread(e));
                            };
                        }
                        var menuItem = (React.createElement(MenuItem, __assign({}, props, { key: item.path, selected: selected, title: item.title, icon: item.icon, id: item._id })));
                        return item.path ? (React.createElement(Link, { key: item.path, to: item.path, style: { textDecoration: 'none' }, onClick: function () { return globalUtils.sounds.play(SOUND_KEY.BUTTON_CLICK); } }, menuItem)) : (menuItem);
                    })) ||
                    null,
                showOtherButton),
            React.createElement("div", { style: { width: '100%' }, className: 'bottom-menu' },
                React.createElement(ListItem, { component: 'li', className: clsx(classesMenuItem.select, 'lang-menu') },
                    React.createElement(LanguageMenu, { fullWidth: true }))))));
}
var component = BasicSideBarComponent;
export var BasicSideBar = inject('store')(withRouter(component));
export * from './types';
