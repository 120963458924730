var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, FORWARDER_USER_BASE, LOCATION_ADDRESS, UPLOAD_BASE, USER_ROLES_BASE_SHORT } from './base';
import { USER_COMPANY_BASE } from './base';
import { USER_SETTINGS_BASE } from './userSettings';
import { STATS_DETAILS } from './order/types';
export var TOKEN_BASE = "\ntoken\nlink\naction\n";
export var USER_BASE_MIN = "\n " + BASE_QUERY + "\n        fullName\n        firstName\n        lastName\n        email\n        rating\n        appType\n        company{\n            _id\n            name\n            phone\n            addressV1{\n              " + LOCATION_ADDRESS + "\n            }\n        }";
export var USER_BASE_V1 = "\n " + BASE_QUERY + "\n        email \n        additionalEmail \n        phone\n        appType\n        portalLinK\n        status\n        fullName\n        firstName\n        lastName\n        language\n        invited\n        roles\n        rolesV1{\n          " + USER_ROLES_BASE_SHORT + "\n        }\n        rating\n        isVerified\n        forwarders{\n          " + FORWARDER_USER_BASE + "\n        }\n";
export var USER_BASE = "\n " + USER_BASE_V1 + "\n        token{\n          " + TOKEN_BASE + " \n        }\n        joinRequest{\n          " + USER_COMPANY_BASE + "\n        }\n";
export var USER_WITH_COMPANY_BASE = "\n    " + USER_BASE + "\n    state\n    latestVisitAt\n    avatar{\n      " + UPLOAD_BASE + "\n    }\n     company{\n      " + USER_COMPANY_BASE + "\n      invited\n     }\n     settings{\n      " + USER_SETTINGS_BASE + "\n     }\n      totalVehicleQTY\n";
export var USER_WITH_COMPANY_BASE_V1 = "\n    " + USER_BASE + "\n    state\n    latestVisitAt\n    avatar{\n      " + UPLOAD_BASE + "\n    }\n     company{\n      " + USER_COMPANY_BASE + "\n      invited\n     }\n     settings{\n      " + USER_SETTINGS_BASE + "\n     }\n";
export var FetchUser = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query user($_id: MongoId!) {\n    user(_id: $_id) {\n      ", "\n    }\n  }\n"], ["\n  query user($_id: MongoId!) {\n    user(_id: $_id) {\n      ", "\n    }\n  }\n"])), USER_WITH_COMPANY_BASE);
export var FetchAdminUser = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query user($_id: MongoId!) {\n    user(_id: $_id) {\n      ", "\n      totalCompanyUsers \n      totalOrdersToday \n      totalOrdersMonth \n      totalOrders \n      totalOffersToday \n      totalOffersMonth \n      totalOffers \n      totalVehicleQTY\n    }\n  }\n"], ["\n  query user($_id: MongoId!) {\n    user(_id: $_id) {\n      ", "\n      totalCompanyUsers \n      totalOrdersToday \n      totalOrdersMonth \n      totalOrders \n      totalOffersToday \n      totalOffersMonth \n      totalOffers \n      totalVehicleQTY\n    }\n  }\n"])), USER_WITH_COMPANY_BASE);
export var FetchUsers = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query users($filter: MainFilter) {\n    users(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n  query users($filter: MainFilter) {\n    users(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), USER_WITH_COMPANY_BASE);
export var FetchAffiliateUsers = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query affiliateUsers($filter: MainFilter) {\n    affiliateUsers(filter: $filter) {\n      data {\n        ", "\n        users{\n            ", "\n        }\n        totalOffers\n        totalCreatedOffers\n        totalOrders\n        totalRevenue\n      }\n      count\n    }\n  }\n"], ["\n  query affiliateUsers($filter: MainFilter) {\n    affiliateUsers(filter: $filter) {\n      data {\n        ", "\n        users{\n            ", "\n        }\n        totalOffers\n        totalCreatedOffers\n        totalOrders\n        totalRevenue\n      }\n      count\n    }\n  }\n"])), USER_COMPANY_BASE, USER_BASE);
export var UpdateUserActivity = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation updateActivity($input: String) {\n    updateActivity(input: $input) {\n      status\n    }\n  }\n"], ["\n  mutation updateActivity($input: String) {\n    updateActivity(input: $input) {\n      status\n    }\n  }\n"])));
export var FetchOrderUsers = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  query users($filter: MainFilter) {\n    users(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n  query users($filter: MainFilter) {\n    users(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), USER_WITH_COMPANY_BASE);
export var FetchAdminUsers = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  query users($filter: MainFilter) {\n    users(filter: $filter) {\n      data {\n        ", "\n    \n        lastNote{\n          _id\n          text\n          createdAt\n        }\n      }\n      count\n      totalStatus{\n        ", "\n      }\n    }\n  }\n"], ["\n  query users($filter: MainFilter) {\n    users(filter: $filter) {\n      data {\n        ", "\n    \n        lastNote{\n          _id\n          text\n          createdAt\n        }\n      }\n      count\n      totalStatus{\n        ", "\n      }\n    }\n  }\n"])), USER_WITH_COMPANY_BASE, STATS_DETAILS);
export var UsersExport = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  query exportUsers($filter: MainFilter) {\n    exportUsers(filter: $filter) {\n      filePath\n    }\n  }\n"], ["\n  query exportUsers($filter: MainFilter) {\n    exportUsers(filter: $filter) {\n      filePath\n    }\n  }\n"])));
export var FetchCarrierUsers = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  query carriers($filter: MainFilter) {\n    carriers(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n  query carriers($filter: MainFilter) {\n    carriers(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), USER_WITH_COMPANY_BASE);
export var FetchForwardUsers = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  query forwardUsers($filter: MainFilter) {\n    forwardUsers(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n  query forwardUsers($filter: MainFilter) {\n    forwardUsers(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), USER_WITH_COMPANY_BASE);
export var InviteTeamMembers = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  mutation inviteMembers($input: [InvitedMemberInput]) {\n    inviteMembers(input: $input) {\n      _id\n      email\n      fullName\n      firstName\n      lastName\n      roles\n    }\n  }\n"], ["\n  mutation inviteMembers($input: [InvitedMemberInput]) {\n    inviteMembers(input: $input) {\n      _id\n      email\n      fullName\n      firstName\n      lastName\n      roles\n    }\n  }\n"])));
export var ReInviteMember = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  mutation reInviteMember($_id: MongoId!) {\n    reInviteMember(_id: $_id) {\n      _id\n      email\n      fullName\n      firstName\n      lastName\n      roles\n    }\n  }\n"], ["\n  mutation reInviteMember($_id: MongoId!) {\n    reInviteMember(_id: $_id) {\n      _id\n      email\n      fullName\n      firstName\n      lastName\n      roles\n    }\n  }\n"])));
export var ReInviteMembers = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  mutation reInviteMembers($input: UserInputReinvites) {\n    reInviteMembers(input: $input) {\n      email\n    }\n  }\n"], ["\n  mutation reInviteMembers($input: UserInputReinvites) {\n    reInviteMembers(input: $input) {\n      email\n    }\n  }\n"])));
export var DeleteUser = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  mutation deleteUser($_id: MongoId!) {\n    deleteUser(_id: $_id) {\n      status\n      code\n    }\n  }\n"], ["\n  mutation deleteUser($_id: MongoId!) {\n    deleteUser(_id: $_id) {\n      status\n      code\n    }\n  }\n"])));
export var UpdateCurrentUser = gql(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  mutation updateUser($input: UserInputUpdate!) {\n    updateUser(input: $input) {\n      ", "\n    }\n  }\n"], ["\n  mutation updateUser($input: UserInputUpdate!) {\n    updateUser(input: $input) {\n      ", "\n    }\n  }\n"])), USER_WITH_COMPANY_BASE);
export var ApproveRegisterUser = gql(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  mutation approveRegisterUser($input: UserInputUpdate!) {\n    approveRegisterUser(input: $input) {\n      ", "\n    }\n  }\n"], ["\n  mutation approveRegisterUser($input: UserInputUpdate!) {\n    approveRegisterUser(input: $input) {\n      ", "\n    }\n  }\n"])), USER_WITH_COMPANY_BASE);
export var SoftInvite = gql(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  mutation softInvite($input: SoftInviteInput) {\n    softInvite(input: $input) {\n      user {\n        ", "\n      }\n      isNew\n    }\n  }\n"], ["\n  mutation softInvite($input: SoftInviteInput) {\n    softInvite(input: $input) {\n      user {\n        ", "\n      }\n      isNew\n    }\n  }\n"])), USER_BASE_MIN);
export var BecomeAffiliateUser = gql(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  mutation becomeAffiliateUser($input: UserAffiliate!) {\n    becomeAffiliateUser(input: $input) {\n      platformUrl\n      user {\n        appType\n      }\n    }\n  }\n"], ["\n  mutation becomeAffiliateUser($input: UserAffiliate!) {\n    becomeAffiliateUser(input: $input) {\n      platformUrl\n      user {\n        appType\n      }\n    }\n  }\n"])));
export var CheckEmailExists = gql(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  query checkEmailExists($email: String!) {\n    checkEmailExists(email: $email) {\n      email\n    }\n  }\n"], ["\n  query checkEmailExists($email: String!) {\n    checkEmailExists(email: $email) {\n      email\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
