var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { dayInEn, toDateOnly } from '../../../utils';
import { OrderRouteType } from '../../../models';
import { timeViewFormat3 } from '../../DateView';
var DELTA = 0.14;
var cellTransport = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var items = _a.items, noneWorking = _a.noneWorking, warehouses = _a.warehouses, isAll = _a.isAll, currentDay = _a.currentDay, _hour = _a._hour, item = _a.item;
    var result = [];
    var _loop_1 = function (i) {
        var e = items[i];
        var _routes = e.routes.filter(function (e) { return e.origin.warehouse && warehouses.includes(e.origin.warehouse._id); });
        if (!_routes.length) {
            return "continue";
        }
        var pickUp = _routes.find(function (f) { return f.type === OrderRouteType.Preferred; });
        var delivery = _routes.find(function (f) { return f.type === OrderRouteType.Delivery; });
        var start = e.startTime && e.startTime[0] && parseFloat((e.startTime[0] || '').toString().replace(':', '.'));
        var end = e.endTime && e.endTime[0] && parseFloat((e.endTime[0] || '').toString().replace(':', '.'));
        var startDuration = parseFloat(e.startTime[0] && ((_d = timeViewFormat3(e.startTime[0], ((_c = (_b = e.order.timeToSpends) === null || _b === void 0 ? void 0 : _b.find(function (t) { return t.name === e._id + "_start"; })) === null || _c === void 0 ? void 0 : _c.value) || e.order.timeToSpend || 60)) === null || _d === void 0 ? void 0 : _d.replace(':', '.')));
        var endDuration = parseFloat((_g = (e.endTime[0] &&
            timeViewFormat3(e.endTime[0], ((_f = (_e = e.order.timeToSpends) === null || _e === void 0 ? void 0 : _e.find(function (t) { return t.name === e._id + "_end"; })) === null || _f === void 0 ? void 0 : _f.value) || e.order.timeToSpend || 60))) === null || _g === void 0 ? void 0 : _g.replace(':', '.'));
        var isFullAll = isAll &&
            ((delivery && e.endDate === currentDay && (end || Infinity) <= _hour + DELTA && _hour < (endDuration || -1)) ||
                (pickUp &&
                    e.startDate === currentDay &&
                    (start || Infinity) <= _hour + DELTA &&
                    _hour < (startDuration || -1)));
        var isUsual = (delivery && e.endDate === currentDay && (end || Infinity) <= _hour + DELTA && _hour <= (end || -1)) ||
            (pickUp && e.startDate === currentDay && (start || Infinity) <= _hour + DELTA && _hour <= (start || -1));
        e.tempSort = isUsual ? 1 : -1;
        var matched = ((e.startDate === currentDay && (pickUp === null || pickUp === void 0 ? void 0 : pickUp.origin.warehouse._id) === item.ramp._id) ||
            (e.endDate === currentDay && (delivery === null || delivery === void 0 ? void 0 : delivery.origin.warehouse._id) === item.ramp._id)) &&
            (isUsual || isFullAll);
        if (matched) {
            result.push(e);
        }
    };
    for (var i = 0; i < items.length; i++) {
        _loop_1(i);
    }
    if (!result.length && !noneWorking.length) {
    }
    return __spread(result, noneWorking);
};
export var cellTransportations = function (_a) {
    var day = _a.day, transportations = _a.transportations, noneWorkingDayTime = _a.noneWorkingDayTime, hour = _a.hour, warehouses = _a.warehouses, item = _a.item, isAll = _a.isAll;
    var currentDay = toDateOnly(day);
    var _hour = parseFloat((hour || '').toString().replace(':', '.'));
    var items = (transportations.data.find(function (e) { return e.date === currentDay; }) || { items: [] }).items;
    var noneWorking = ((noneWorkingDayTime === null || noneWorkingDayTime === void 0 ? void 0 : noneWorkingDayTime.data) || [])
        .filter(function (e) {
        var _start = parseFloat((e.time || '').toString().replace(':', '.'));
        var _end = parseFloat((timeViewFormat3(e.time, e.timeToSpend || 60) || '').toString().replace(':', '.'));
        return (e.warehouse._id === item.ramp._id &&
            e.date === currentDay &&
            (typeof hour === 'undefined' || ((_start || Infinity) <= _hour + DELTA && _hour < (_end || -1))));
    })
        .map(function (e) { return ({
        _id: e._id + "-none-working",
        startDate: e.date,
        routes: [],
        item: item,
        record: e,
        order: { timeToSpend: e.timeToSpend, routes: [] },
        title: e.description,
        name: e.name,
        startTime: [e.time],
        tempSort: 1,
    }); });
    /*let lastNoneWorking: any;
    if (!noneWorking.length) {
      lastNoneWorking = (noneWorkingDayTime?.data || [])
        .filter((e: any) => {
          const _start: any = parseFloat((e.time || '').toString().replace(':', '.'));
          const _end: any = parseFloat((timeViewFormat3(e.time, e.timeToSpend || 60) || '').toString().replace(':', '.'));
          return (
            e.warehouse._id === item.ramp._id &&
            e.date === currentDay &&
            (typeof hour === 'undefined' || ((_start || Infinity) <= _hour + DELTA && _hour < (_end || -1)))
          );
        })
        .map((e) => ({
          _id: `${e._id}-none-working`,
          startDate: e.date,
          routes: [],
          item,
          record: e,
          order: { timeToSpend: e.timeToSpend, routes: [] },
          title: e.description,
          name: e.name,
          startTime: [e.time],
          tempSort: 1,
        }));
    }*/
    return cellTransport({ items: items, noneWorking: noneWorking, warehouses: warehouses, isAll: isAll, currentDay: currentDay, _hour: _hour, item: item }).filter(function (e) { return e; });
};
export var MINIMUM_MINUTE_RANGE = 15;
var parseTableRowSpan = function (cells, rows, hour, columns, rowIndex, colIndex) {
    for (var i = 0; i < cells.length; i++) {
        var key = cells[i].key;
        if (rows[hour][key]) {
            Object.assign(cells[i], rows[hour][key]);
        }
        var _rowIndex = rowIndex + 1;
        var nextCeil = columns[_rowIndex] && columns[_rowIndex][colIndex] && columns[_rowIndex][colIndex][i];
        if (nextCeil) {
            var _nextCeil = 1;
            while (!nextCeil.rowSpan && _nextCeil) {
                _rowIndex++;
                _nextCeil = columns[_rowIndex] && columns[_rowIndex][colIndex] && columns[_rowIndex][colIndex][i];
                if (_nextCeil)
                    nextCeil = _nextCeil;
            }
        }
        while (cells[i].rowSpan > 0 &&
            // nextCeil?.key &&
            (nextCeil === null || nextCeil === void 0 ? void 0 : nextCeil.rowSpan) > 0 &&
            toMinutes(cells[i].key.split('-')[0]) + cells[i].rowSpan * MINIMUM_MINUTE_RANGE >
                toMinutes(nextCeil.key.split('-')[0])) {
            cells[i].rowSpan--; //when cell on over next ceil
        }
        if (!cells[i].order && cells[i].duration && cells[i].rowSpan * MINIMUM_MINUTE_RANGE < cells[i].duration) {
            cells[i].notAvailable = 1;
        }
    }
};
var getTableRowSpan = function (_a) {
    var _b;
    var _c, _d, _e, _f, _g, _h, _j, _k;
    var hours = _a.hours, hour = _a.hour, item = _a.item, rows = _a.rows, calendarState = _a.calendarState, days = _a.days, weekIndex = _a.weekIndex, index = _a.index, warehouses = _a.warehouses;
    if (!rows[hour])
        rows[hour] = {};
    var key = hour + "-" + item.day + "-" + item.ramp._id;
    var transportations = { data: __spread(calendarState.transportations.data.map(function (e) { return (__assign(__assign({}, e), { items: __spread(e.items) })); })) };
    for (var i = 0; i < calendarState.transportationsBusy.data.length; i++) {
        var used = 0;
        for (var j = 0; j < transportations.data.length; j++) {
            if (transportations.data[j].date === calendarState.transportationsBusy.data[i].date) {
                (_b = transportations.data[j].items).push.apply(_b, __spread(calendarState.transportationsBusy.data[i].items));
                used = 1;
            }
        }
        if (!used) {
            transportations.data.push(calendarState.transportationsBusy.data[i]);
        }
    }
    var dayTransportations = cellTransportations({
        transportations: transportations,
        noneWorkingDayTime: calendarState.noneWorkingDayTime,
        day: days[weekIndex],
        warehouses: warehouses,
        hour: hour,
        item: item,
        isAll: true,
    });
    var _dayWorkingTime = ((_d = (_c = calendarState.workingDays) === null || _c === void 0 ? void 0 : _c.data.find(function (e) { return e.day === item.day && e.warehouse._id === item.ramp._id; })) === null || _d === void 0 ? void 0 : _d.plans) || [];
    var dayWorkingTime = (_dayWorkingTime === null || _dayWorkingTime === void 0 ? void 0 : _dayWorkingTime.length) && _dayWorkingTime.find(function (plan) { return hour >= plan.start && hour < plan.end; });
    var nextWorkingTimeOfDay = (_dayWorkingTime === null || _dayWorkingTime === void 0 ? void 0 : _dayWorkingTime.length) && _dayWorkingTime.find(function (plan) { return hour <= plan.start; });
    var timeBreaker = !dayWorkingTime &&
        _dayWorkingTime.filter(function (e, index) { return _dayWorkingTime[index + 1] && hour >= e.end && hour <= _dayWorkingTime[index + 1].start; });
    if (!timeBreaker.length)
        timeBreaker = 0;
    if (!dayWorkingTime) {
        for (var index_1 = 0; index_1 < _dayWorkingTime.length; index_1++) {
            if (_dayWorkingTime[index_1 + 1] &&
                hour >= _dayWorkingTime[index_1].end &&
                hour <= _dayWorkingTime[index_1 + 1].start) {
                timeBreaker = [_dayWorkingTime[index_1], _dayWorkingTime[index_1 + 1]];
                break;
            }
        }
        if ((timeBreaker === null || timeBreaker === void 0 ? void 0 : timeBreaker.length) === 2) {
            timeBreaker = toMinutes(timeBreaker[1].start) - toMinutes(timeBreaker[0].end);
        }
        else {
            timeBreaker = 0;
        }
    }
    else {
        timeBreaker = 0;
    }
    if (!rows[hour][key]) {
        var lastTransportation_1 = dayTransportations[dayTransportations.length - 1];
        var keyBooking_1 = 'start';
        var ramps = calendarState.ramps.data.map(function (e) { return e._id; });
        if (toDateOnly(days[weekIndex]) === (lastTransportation_1 === null || lastTransportation_1 === void 0 ? void 0 : lastTransportation_1.endDate) && (lastTransportation_1 === null || lastTransportation_1 === void 0 ? void 0 : lastTransportation_1.endTime[0]) &&
            ramps.includes((_g = (_f = (_e = lastTransportation_1.routes.find(function (e) { return e.type === OrderRouteType.Delivery; })) === null || _e === void 0 ? void 0 : _e.origin) === null || _f === void 0 ? void 0 : _f.warehouse) === null || _g === void 0 ? void 0 : _g._id)) {
            keyBooking_1 = 'end';
        }
        var orderTimeSpend = ((_j = (_h = lastTransportation_1 === null || lastTransportation_1 === void 0 ? void 0 : lastTransportation_1.order.timeToSpends) === null || _h === void 0 ? void 0 : _h.find(function (t) { return t.name === lastTransportation_1._id + "_" + keyBooking_1; })) === null || _j === void 0 ? void 0 : _j.value) || (lastTransportation_1 === null || lastTransportation_1 === void 0 ? void 0 : lastTransportation_1.order.timeToSpend);
        var lastTransportationEndHour = lastTransportation_1 &&
            lastTransportation_1[keyBooking_1 + "Time"] &&
            lastTransportation_1[keyBooking_1 + "Time"][0] &&
            timeViewFormat3(lastTransportation_1[keyBooking_1 + "Time"][0], orderTimeSpend);
        var isFinishedLastTransportation = hour >= lastTransportationEndHour;
        var timeToSpend = timeBreaker ||
            (!isFinishedLastTransportation && orderTimeSpend) ||
            (item.ramp.isDayWorkingMatchBooking && (dayWorkingTime === null || dayWorkingTime === void 0 ? void 0 : dayWorkingTime.duration));
        rows[hour][key] = {
            timeToSpend: timeToSpend,
            timeBreaker: timeBreaker,
            rowSpan: Math.ceil((timeToSpend || MINIMUM_MINUTE_RANGE) / MINIMUM_MINUTE_RANGE),
            order: lastTransportation_1 === null || lastTransportation_1 === void 0 ? void 0 : lastTransportation_1._id,
        };
        // if (timeBtw) console.log(hour, timeBtw, rows[hour][key]);
    }
    var prevIndex = index - 1;
    var prevHour = hours[prevIndex];
    while (prevHour && ((_k = rows[prevHour][prevHour + "-" + item.day + "-" + item.ramp._id]) === null || _k === void 0 ? void 0 : _k.rowSpan) === 0) {
        prevHour = hours[--prevIndex];
    }
    var _key = prevHour + "-" + item.day + "-" + item.ramp._id;
    var lastCellInited = rows[prevHour] && rows[prevHour][_key];
    if (!_dayWorkingTime.length) {
        rows[hour][key].notAvailable = 1;
        if (index === 0) {
            rows[hour][key].rowSpan = hours.length;
        }
        else {
            rows[hour][key].rowSpan = 0;
        }
    }
    if (index > 0 && lastCellInited) {
        if (rows[hour][key].order) {
            if (lastCellInited.order === rows[hour][key].order && lastCellInited.rowSpan / (index - prevIndex) > 1) {
                rows[hour][key].rowSpan = 0;
            }
            else {
                while (lastCellInited.rowSpan > 0 &&
                    toMinutes(prevHour) + MINIMUM_MINUTE_RANGE * lastCellInited.rowSpan > toMinutes(hour)) {
                    lastCellInited.rowSpan--;
                }
            }
        }
        else if ((!dayWorkingTime && lastCellInited.notAvailable) ||
            (!lastCellInited.order && (dayWorkingTime === null || dayWorkingTime === void 0 ? void 0 : dayWorkingTime.duration) && index - prevIndex < lastCellInited.rowSpan)) {
            rows[hour][key].rowSpan = 0;
            // console.log(hour, key, rows[hour][key], prevHour, lastCellInited);
            if (!dayWorkingTime && lastCellInited.notAvailable) {
                // lastCellInited.rowSpan++;
                if (index === hours.length - 1 || (!lastCellInited.timeBreaker && lastCellInited.rowSpan > 0)) {
                    lastCellInited.rowSpan++;
                }
            }
        }
    }
    if (!dayWorkingTime) {
        rows[hour][key].notAvailable = 1;
        if (nextWorkingTimeOfDay) {
            // console.log(hour, prevHour);
            var dayWorkingTimeStrict = (_dayWorkingTime === null || _dayWorkingTime === void 0 ? void 0 : _dayWorkingTime.length) && _dayWorkingTime.find(function (plan) { return hour >= plan.start && hour <= plan.end; });
            if (lastCellInited && dayWorkingTimeStrict) {
                // console.log(lastCellInited, lastCellInited.rowSpan);
                if (lastCellInited.rowSpan > 0 &&
                    toMinutes(prevHour) + MINIMUM_MINUTE_RANGE * lastCellInited.rowSpan > toMinutes(hour)) {
                    if (index === hours.length - 1) {
                    }
                    else {
                        if (toMinutes(hour) < toMinutes(prevHour) + lastCellInited.timeToSpend) {
                        }
                        else {
                            lastCellInited.rowSpan--;
                        }
                        // console.log(
                        //   prevHour,
                        //   hour,
                        //   lastCellInited,
                        //   toMinutes(prevHour),
                        //   MINIMUM_MINUTE_RANGE * lastCellInited.rowSpan,
                        //   toMinutes(hour)
                        // );
                    }
                    // console.log(hour, prevHour, lastCellInited.rowSpan);
                }
                // console.log('after--', lastCellInited.rowSpan);
            }
            return __assign(__assign({ index: index++, key: key, allDayTransportations: [] }, rows[hour][key]), { notAvailable: 1 });
        }
    }
    return __assign(__assign({ index: index++, key: key, allDayTransportations: dayTransportations.filter(function (e) { return e.tempSort === 1; }) }, (dayWorkingTime && item.ramp.isDayWorkingMatchBooking && { duration: dayWorkingTime.duration })), rows[hour][key]);
};
export var getWeekDays = function (current, countDays) {
    if (countDays === void 0) { countDays = 7; }
    var firstDay = current;
    var startFrom = firstDay.getDate();
    while (firstDay.getDay() !== 0) {
        firstDay = new Date(new Date(current).setDate(startFrom--));
    }
    var _days = [];
    for (var i = 0; i < countDays; i++) {
        _days.push(new Date(new Date(firstDay).setDate(firstDay.getDate() + i + 1)));
    }
    return _days;
};
export var getSlots = function (_a) {
    var calendarState = _a.calendarState, hours = _a.hours, days = _a.days, ramps = _a.ramps, switchers = _a.switchers;
    var weekDays = days.map(function (e) { return ({
        day: dayInEn(e),
        id: toDateOnly(e),
        ramp: calendarState.ramps.data.find(function (r) { return r._id === ramps[0]; }),
        children: [],
    }); });
    if (switchers.length) {
        var newWeekDays_1 = [];
        var _loop_2 = function (i) {
            var _a;
            newWeekDays_1.push(weekDays[i]);
            for (var j = 0; j < switchers.length; j++) {
                if (switchers[j] === weekDays[i].day) {
                    (_a = newWeekDays_1[newWeekDays_1.length - 1].children).push.apply(_a, __spread(ramps
                        .filter(function (e, i) { return i > 0; })
                        .map(function (ramp) { return ({
                        children: [],
                        ramp: calendarState.ramps.data.find(function (r) { return r._id === ramp; }),
                        day: weekDays[i].day,
                        id: weekDays[i].id + "-" + newWeekDays_1.length,
                    }); })));
                    break;
                }
            }
        };
        for (var i = 0; i < weekDays.length; i++) {
            _loop_2(i);
        }
        weekDays = newWeekDays_1;
    }
    var rows = {};
    var warehouses = __spread([
        calendarState.warehouse && calendarState.warehouse._id
    ], calendarState.ramps.data.map(function (e) { return e._id; }));
    var hourSlots = [];
    var _loop_3 = function (i) {
        var hour = hours[i];
        var index = i;
        var hourWeekSlot = [];
        var _loop_4 = function (j) {
            var item = weekDays[j];
            var cells = __spread([
                getTableRowSpan({
                    hours: hours,
                    hour: hour,
                    item: item,
                    rows: rows,
                    calendarState: calendarState,
                    days: days,
                    weekIndex: j,
                    index: index,
                    warehouses: warehouses,
                })
            ], item.children.map(function (item, rampIndex) {
                return getTableRowSpan({
                    hours: hours,
                    hour: hour,
                    item: item,
                    rows: rows,
                    calendarState: calendarState,
                    days: days,
                    weekIndex: j,
                    index: index,
                    warehouses: warehouses,
                });
            })).filter(function (e) { return e; });
            hourWeekSlot.push(cells);
        };
        for (var j = 0; j < weekDays.length; j++) {
            _loop_4(j);
        }
        hourSlots.push(hourWeekSlot);
    };
    // const maxHour = hours.length ? toMinutes(hours[hours.length - 1]) + MINIMUM_MINUTE_RANGE : Infinity;
    for (var i = 0; i < hours.length; i++) {
        _loop_3(i);
    }
    for (var i = 0; i < hourSlots.length; i++) {
        var hour = hours[i];
        for (var j = 0; j < hourSlots[i].length; j++) {
            parseTableRowSpan(hourSlots[i][j], rows, hour, hourSlots, i, j);
        }
    }
    return { hourSlots: hourSlots, weekDays: weekDays, rows: rows };
};
export var toMinutes = function toMinutes(hour) {
    return hour.split(':')[0] * 60 + +hour.split(':')[1];
};
