var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { getDaysArray, getMonthRange, toDateOnly } from '../utils';
import moment from 'moment';
import { BaseSingleDatePicker, SingleDatePicker } from './DatePicker';
import { inject } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { CircularIndeterminate } from './CircularIndeterminate';
import { i18n, KEYS } from '../i18n';
import { withOrderListItemStore } from './Order/List/Customer/storeListItem';
import { SOCKET_CUSTOM_EVENT_NAME } from './Socket/types';
import { useAnimationStyles } from '../styles/main';
import clsx from 'clsx';
var isInvalidDate = function (d) { return !d || toDateOnly(d).toLowerCase() === new Date('test').toString().toLowerCase(); };
var LimitDatePickerC = function (_a) {
    var _b, _c;
    var _d, _e, _f, _g;
    var defaultValue = _a.defaultValue, isSimple = _a.isSimple, route = _a.route, warehouseStore = _a.store.warehouseStore, props = __rest(_a, ["defaultValue", "isSimple", "route", "store"]);
    var _h = __read(React.useState(false), 2), loading = _h[0], setLoading = _h[1];
    var currentProps = Object.assign({}, defaultValue && defaultValue.minDate && { minDate: defaultValue.minDate }, defaultValue && defaultValue.maxDate && { maxDate: defaultValue.maxDate }, props.maxDate && { maxDate: props.maxDate }, props.minDate && { minDate: props.minDate }, defaultValue && typeof defaultValue === 'string' && { defaultValue: defaultValue }, props);
    var classesAnimation = useAnimationStyles();
    var eventsToRefresh = ['startDate', 'startTime'].includes(props.name)
        ? [SOCKET_CUSTOM_EVENT_NAME.QUOTE_LOADING_AT_CHANGED]
        : [SOCKET_CUSTOM_EVENT_NAME.QUOTE_UNLOADING_AT_CHANGED];
    var hasUpdates = eventsToRefresh.includes((_f = (_e = (_d = props.orderListItemProps) === null || _d === void 0 ? void 0 : _d.state) === null || _e === void 0 ? void 0 : _e.lastUpdates) === null || _f === void 0 ? void 0 : _f.event);
    var dateToBook = currentProps.value;
    if (dateToBook && isInvalidDate(dateToBook)) {
        dateToBook = toDateOnly();
    }
    if (isInvalidDate(currentProps.minDate)) {
        currentProps.minDate = new Date();
    }
    if (isInvalidDate(currentProps.maxDate)) {
        currentProps.maxDate = null;
    }
    var onOpen = function () {
        var e = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            e[_i] = arguments[_i];
        }
        return __awaiter(void 0, void 0, void 0, function () {
            var date, _dateProps, filters;
            var _a, _b;
            return __generator(this, function (_c) {
                // const route = (name === 'startDate' && 'loading') || 'unloading';
                // const action = (name === 'startDate' && setStartDateProps) || setEndDateProps;
                if (route && route.origin && route.origin.warehouse) {
                    setLoading(true);
                    date = currentProps.value || new Date();
                    _dateProps = __assign(__assign({}, dateProps), getMonthRange(date));
                    filters = {
                        fields: [
                            {
                                name: 'warehouse',
                                value: (route.origin.warehouse.parent && [route.origin.warehouse._id, route.origin.warehouse.parent._id]) ||
                                    route.origin.warehouse._id,
                            },
                            {
                                name: 'start',
                                value: toDateOnly(_dateProps.minDate),
                            },
                            {
                                name: 'end',
                                value: toDateOnly(_dateProps.maxDate),
                            },
                            ((_a = props.order) === null || _a === void 0 ? void 0 : _a.timeToSpend) && { name: 'timeToSpend', value: (_b = props.order) === null || _b === void 0 ? void 0 : _b.timeToSpend },
                        ].filter(function (e) { return e; }),
                    };
                    onLoadDetails(filters);
                }
                return [2 /*return*/];
            });
        });
    };
    var onLoadDetails = function (filters) { return __awaiter(void 0, void 0, void 0, function () {
        var start, end, data, daysUnavailable, workingDays, unavailableDays_1, i, days, daysToExclude_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!(route && route.origin && route.origin.warehouse)) return [3 /*break*/, 4];
                    setLoading(true);
                    start = ((_a = filters.fields.find(function (e) { return e.name === 'start'; })) === null || _a === void 0 ? void 0 : _a.value) || toDateOnly();
                    end = ((_b = filters.fields.find(function (e) { return e.name === 'end'; })) === null || _b === void 0 ? void 0 : _b.value) || toDateOnly();
                    return [4 /*yield*/, warehouseStore.loadHolidays(filters)];
                case 1:
                    data = (_c.sent()) || [];
                    filters.fields = filters.fields.map(function (e) {
                        return e.name === filters.fields[0].name ? __assign(__assign({}, filters.fields[0]), { value: route.origin.warehouse._id }) : e;
                    });
                    return [4 /*yield*/, warehouseStore.loadNoneAvailableForBooking(filters)];
                case 2:
                    daysUnavailable = (_c.sent()) || [];
                    return [4 /*yield*/, warehouseStore.loadWarehouseWorkingDays(filters)];
                case 3:
                    workingDays = (_c.sent()) || [];
                    unavailableDays_1 = [];
                    for (i = 0; i < data.length; i++) {
                        days = getDaysArray(new Date(data[i].start), new Date(data[i].end)).map(function (e) { return toDateOnly(e); });
                        unavailableDays_1 = __spread(new Set(__spread(days, unavailableDays_1)));
                    }
                    daysToExclude_1 = workingDays.filter(function (e) { return !(e.minTime || e.maxTime); }).map(function (e) { return e.day; });
                    unavailableDays_1 = __spread(new Set(__spread(getDaysArray(new Date(start), new Date(end))
                        .filter(function (day) { return daysToExclude_1.includes(moment(day).format('dddd')); })
                        .map(function (e) { return toDateOnly(e); }), unavailableDays_1, daysUnavailable)));
                    setDateProps(__assign(__assign({}, dateProps), { shouldDisableDate: function (date) { return unavailableDays_1.includes(toDateOnly(date)); } }));
                    setLoading(false);
                    _c.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _onChange = function () {
        var e = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            e[_i] = arguments[_i];
        }
        if (props.v3) {
            props.onChange.apply(props, __spread(e));
        }
        else {
            dateProps.onChange.apply(dateProps, __spread(e));
        }
    };
    if (!currentProps.minDate)
        currentProps.minDate = new Date();
    if (!currentProps.onMonthChange)
        currentProps.onMonthChange = function (date) {
            var _a, _b, _c;
            if ((_a = route === null || route === void 0 ? void 0 : route.origin) === null || _a === void 0 ? void 0 : _a.warehouse) {
                var _dateProps = __assign(__assign({}, dateProps), getMonthRange(date));
                var filters = {
                    fields: [
                        {
                            name: 'warehouse',
                            value: (route.origin.warehouse.parent && [route.origin.warehouse._id, route.origin.warehouse.parent._id]) ||
                                route.origin.warehouse._id,
                        },
                        { name: 'start', value: toDateOnly(_dateProps.minDate) },
                        { name: 'end', value: toDateOnly(_dateProps.maxDate) },
                        ((_b = props.order) === null || _b === void 0 ? void 0 : _b.timeToSpend) && { name: 'timeToSpend', value: (_c = props.order) === null || _c === void 0 ? void 0 : _c.timeToSpend },
                    ].filter(function (e) { return e; }),
                };
                onLoadDetails(filters);
            }
        };
    var _j = __read(React.useState(currentProps), 2), dateProps = _j[0], setDateProps = _j[1];
    if (isSimple) {
        return (React.createElement("span", { style: { zIndex: 1, position: 'relative' }, className: clsx((_b = {},
                _b[classesAnimation.pulse] = hasUpdates,
                _b)) },
            React.createElement(BaseSingleDatePicker, __assign({ key: dateProps.updated, title: [
                    i18n.getResource(i18n.language, KEYS.APP, 'click_to_book'),
                    (_g = i18n
                        .getResource(i18n.language, KEYS.OFFER_FORM, "offer_" + ((props.name === 'endTime' && 'to') || 'from') + "_label_date")) === null || _g === void 0 ? void 0 : _g.toLowerCase(),
                ].join(' ') }, dateProps, { onChange: _onChange, is_mini: props.is_mini, onOpen: onOpen, loading: loading, renderLoading: function () { return (React.createElement(Grid, { container: true, justify: 'center' },
                    React.createElement(CircularIndeterminate, null))); } }))));
    }
    return (React.createElement(SingleDatePicker, __assign({ style: { zIndex: 1, position: 'relative' }, className: clsx((_c = {},
            _c[classesAnimation.pulse] = hasUpdates,
            _c)), key: dateProps.updated, title: i18n.getResource(i18n.language, KEYS.APP, 'click_to_book') }, dateProps, { onChange: _onChange, onOpen: onOpen, loading: loading, specialEnd: props.specialEnd, specialStart: props.specialStart, renderLoading: function () { return (React.createElement(Grid, { container: true, justify: 'center' },
            React.createElement(CircularIndeterminate, null))); } })));
};
export var LimitDatePicker = withOrderListItemStore(inject('store')(LimitDatePickerC));
