import { createStyles, makeStyles } from '@material-ui/core/styles';
export var dialogStyles = makeStyles(function (theme) {
    var _a, _b;
    return createStyles({
        '@keyframes errorEffect': {
            '0%': {
                transform: 'translateX(-6px)',
            },
            '25%': {
                transform: 'translateX(6px)',
            },
            '50%': {
                transform: 'translateX(-3px)',
            },
            '75%': {
                transform: 'translateX(3px)',
            },
            '100%': {
                transform: 'translateX(0px)',
            },
        },
        icon: {
            '&:hover': {
                color: theme.palette.primary.light,
            },
        },
        root: {},
        error: {
            animation: '$errorEffect .2s ease-in-out',
        },
        paperSideBar: {
            borderRadius: "0!important",
        },
        paper: (_a = {
                minWidth: 500,
                minHeight: 100,
                borderRadius: 10
            },
            _a[theme.breakpoints.down('xs')] = {
                minWidth: '100%',
                maxHeight: '100%',
                margin: "0!important",
                padding: 0,
            },
            _a),
        paperV5: (_b = {
                position: 'absolute',
                right: 10,
                top: 50,
                minWidth: 300,
                maxWidth: 300
            },
            _b[theme.breakpoints.down('xs')] = {
                minWidth: '100%',
                maxHeight: '100%',
                margin: "0!important",
                padding: 0,
                right: 0,
            },
            _b),
        paperWithoutShadow: {
            boxShadow: 'none',
        },
        split: {
            borderColor: 'rgb(216 216 216 / 30%)',
            width: 401,
            margin: 'auto',
        },
        text: {
            fontWeight: 400,
            // fontSize: 20,
            margin: 'auto',
            textAlign: 'center',
        },
        danger: {
            color: 'red',
        },
    });
});
export var dialogActionsStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            justifyContent: 'center',
        },
    });
});
export var dialogBackDropActionsStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            backgroundColor: 'rgb(0 0 0 / 26%)',
        },
    });
});
export var dialogContentStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        rootMiddle: {
            display: 'flex',
            alignItems: 'center',
        },
        rootBlock: {
            display: 'block!important',
        },
        rootSidebar: {
            maxHeight: 'calc(100vh - 120px)!important',
        },
        root: (_a = {
                display: 'flex',
                alignItems: 'center',
                // width: '100%',
                '& form': {
                    width: '100%',
                },
                minHeight: 100,
                maxHeight: 'calc(100vh - 200px)',
                overflow: 'auto',
                borderTop: "1px solid #e2e2e2",
                borderBottom: "1px solid #e2e2e2"
            },
            _a[theme.breakpoints.down('sm')] = {
                maxHeight: 'calc(100vh - 270px)',
                padding: theme.spacing(0, 3),
            },
            _a['& iframe'] = {
                '& body': {
                    textAlign: 'center',
                    '& img': {
                        height: '100%',
                    },
                },
            },
            _a),
        rootWithoutBorder: {
            border: 'none',
        },
    });
});
