var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import BaseTooltip from '../BaseTooltip';
import BugReportIcon from '@material-ui/icons/BugReport';
import { KEYS } from '../../i18n';
import { useIconStyles } from '../../styles/main';
import OrderRouteMenuItem from '../Order/List/OrderRoutesMenuItem';
import { Link } from 'react-router-dom';
import { IssuesChannelsSocket, withIssuesChannelsStore } from './store';
import { StyledBadgeV7 } from '../StyledBadge';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme';
import { TextOverflow } from '../TextOverflow';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        '& .MuiBadge-badge': {
            right: 0,
            top: 0,
        },
    },
}); });
function ReportIssue(props) {
    var _a;
    var langApp = useTranslation(KEYS.APP);
    var globalIconStyles = useIconStyles();
    var title = langApp.t('main_issue_report');
    var classesS = useStyles();
    var iconO = (React.createElement(BaseTooltip, { title: title },
        React.createElement(IconButton, { id: 'report-btn', style: {
                padding: 0,
            } },
            React.createElement(BugReportIcon, { className: globalIconStyles.menuIcon }))));
    var icon = props.isAdmin || 1 ? (iconO) : (React.createElement(OrderRouteMenuItem, { v2: true, icon: iconO, label: React.createElement(TextOverflow, { style: { marginLeft: theme.spacing(1) } }) }));
    if (props.issueChannelStore.state.totalUnread) {
        icon = (React.createElement(StyledBadgeV7, { className: clsx((_a = {}, _a[classesS.root] = props.isAdmin, _a)), badgeContent: props.issueChannelStore.state.totalUnread }, icon));
    }
    if (props.isAdmin)
        return icon;
    return React.createElement(Link, { to: '/issues' }, icon);
}
var ReportIssueC = withIssuesChannelsStore(ReportIssue);
export default function (p) {
    return (React.createElement(IssuesChannelsSocket, null,
        React.createElement(ReportIssueC, __assign({}, p))));
}
