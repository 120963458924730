var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { inject, observer } from 'mobx-react';
import OrderRouteMenu from './OrderRoutesMenu';
import MenuListItem from '../../MenuListItem';
import BaseTooltip from '../../BaseTooltip';
import { StyledBadgeV14 } from '../../StyledBadge';
import { USER_ROLE_TOOLS } from '../../../models/UserRoles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import BusinessIcon from '@material-ui/icons/Business';
import ErrorIcon from '@material-ui/icons/Error';
import OrderRoutesHeader from './OrderRoutesHeader';
import PreloadingComponent from '../../PreloadingComponent';
import SectionHistory from '../../SectionDetails/SectionHistory';
import { DeleteDialog } from '../../Dialog';
import CompanyFormModal from '../../Account/CompanyFormModal';
var OrderEditForm = React.lazy(function () { return import('./EditOrder'); });
export var OrderRoutesComponent = inject('store')(observer(function (_a) {
    var _this = this;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var order = _a.order, canViewAdditional = _a.canViewAdditional, additionalContent = _a.additionalContent, continueContent = _a.continueContent, canRedirect = _a.canRedirect, moreActions = _a.moreActions, onClick = _a.onClick, hideActions = _a.hideActions, v1 = _a.v1, config = _a.config, onDeleteItem = _a.onDeleteItem, store = _a.store, props = __rest(_a, ["order", "canViewAdditional", "additionalContent", "continueContent", "canRedirect", "moreActions", "onClick", "hideActions", "v1", "config", "onDeleteItem", "store"]);
    var currentUser = store.accountStore.currentUser;
    var isToDo = (_c = (_b = props === null || props === void 0 ? void 0 : props.defaultFilter) === null || _b === void 0 ? void 0 : _b.fields) === null || _c === void 0 ? void 0 : _c.find(function (e) { return ['to-do-list'].includes(e.name); });
    var isWithP44Issues = (_e = (_d = props === null || props === void 0 ? void 0 : props.defaultFilter) === null || _d === void 0 ? void 0 : _d.fields) === null || _e === void 0 ? void 0 : _e.find(function (e) { return ['withP44Issues'].includes(e.name); });
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langApp = useTranslation(KEYS.APP);
    var mainBodyStyles = { padding: theme.spacing(1, 0, 1, 2) };
    if (v1) {
        Object.assign(mainBodyStyles, { padding: theme.spacing(3, 4) });
    }
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var isAdmin = window.location.href.match('localhost:3002') || window.location.href.match('admin');
    var isAllowChangeDate = currentUser.rolesV1.find(function (role) { var _a, _b; return (_b = (_a = role === null || role === void 0 ? void 0 : role.tools) === null || _a === void 0 ? void 0 : _a.general) === null || _b === void 0 ? void 0 : _b.includes(USER_ROLE_TOOLS.GENERAL_allow_change_dates); });
    var iconStyle = { width: 16, height: 16 };
    var helpIcons = (React.createElement(React.Fragment, null,
        React.createElement(NotificationsIcon, { color: "primary", style: __assign(__assign(__assign({}, iconStyle), { visibility: 'hidden' }), ((((_f = order === null || order === void 0 ? void 0 : order.approvedCarrier) === null || _f === void 0 ? void 0 : _f._id) && { marginLeft: -12 }) || { marginLeft: -iconStyle.width })) }),
        (order.lastRequestChange && isAllowChangeDate && store.accountStore.isLoggedIn && (React.createElement(Grid, { item: true, style: { padding: 0, display: 'flex' } },
            React.createElement(Link, { to: "/orders/" + order._id + "/restore" },
                React.createElement(BaseTooltip, { title: ((_g = order === null || order === void 0 ? void 0 : order.lastRequestChange) === null || _g === void 0 ? void 0 : _g.comment) || langOfferForm.t('offer_form_request_change') },
                    React.createElement(StyledBadgeV14, { color: "secondary", variant: "dot" },
                        React.createElement(NotificationsIcon, { color: "primary", style: iconStyle }))))))) ||
            null,
        ((order === null || order === void 0 ? void 0 : order.comment) && store.accountStore.isLoggedIn && (React.createElement(Grid, { item: true, style: { padding: 0, display: 'flex' } },
            React.createElement(BaseTooltip, { title: (order === null || order === void 0 ? void 0 : order.comment) || langOfferForm.t('offer_form_request_change') },
                React.createElement(ErrorIcon, { color: "primary", fontSize: "small", style: iconStyle }))))) ||
            null,
        ((order === null || order === void 0 ? void 0 : order.noQuotes) &&
            store.accountStore.isLoggedIn &&
            ((_h = order === null || order === void 0 ? void 0 : order.createdBy) === null || _h === void 0 ? void 0 : _h.company._id) === store.accountStore.currentCompany._id && (React.createElement(Grid, { item: true, style: { padding: 0, display: 'flex' } },
            React.createElement(BaseTooltip, { title: langApp.t('inner_help') },
                React.createElement(BusinessIcon, { color: "primary", style: iconStyle }))))) ||
            null));
    var menuIcon = !store.accountStore.isLoggedIn || isToDo ? null : isAdmin || isWithP44Issues ? (React.createElement(MenuListItem, { v1: true, show: true, id: "order-" + order.orderId, list: [
            {
                content: function (open) {
                    var _a;
                    return (React.createElement(PreloadingComponent, null,
                        React.createElement(CompanyFormModal, { label: true, config: config, withText: true, record: (_a = order.latestQuote) === null || _a === void 0 ? void 0 : _a.createdBy.company, onChange: props.onChange, isAdmin: true, isP44: true })));
                },
                available: (_l = (_k = (_j = order.latestQuote) === null || _j === void 0 ? void 0 : _j.createdBy) === null || _k === void 0 ? void 0 : _k.company) === null || _l === void 0 ? void 0 : _l._id,
            },
            isAdmin && {
                content: function (open) { return (React.createElement(PreloadingComponent, null,
                    React.createElement(OrderEditForm, { defaultValue: order, config: config, label: 1, onChange: props.onChange }))); },
                available: true,
            },
            {
                id: 'btn-order-history',
                content: (React.createElement(PreloadingComponent, null,
                    React.createElement(SectionHistory, { v2: true, isAdmin: true, filters: {
                            fields: [
                                { name: 'orders', value: [order._id] },
                                { name: 'type', value: 2 },
                            ],
                        }, type: 1, record: order, order: order }))),
                available: true,
            },
            isAdmin && {
                id: 'btn-delete-order',
                content: (React.createElement(DeleteDialog, { id: 'btn-delete-order', withoutWrapper: true, value: order.orderId, onOk: function (e) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                        return [2 /*return*/, onDeleteItem && onDeleteItem(e, order)];
                    }); }); }, withText: true })),
                available: true,
            },
        ].filter(function (e) { return e; }) })) : (React.createElement(OrderRouteMenu, __assign({ key: canViewAdditional ? 1 : -1, show: !canViewAdditional }, {
        config: config,
        onDeleteItem: onDeleteItem,
        moreActions: moreActions,
        onChange: props.onChange,
        onChangeV1: props.onChangeV1,
        onChangeV2: props.onChangeV2,
        fileInput: props.fileInput,
        order: order,
    })));
    var mainBody = (React.createElement(OrderRoutesHeader, __assign({}, __assign({ onClick: onClick,
        config: config,
        order: order,
        canViewAdditional: canViewAdditional,
        helpIcons: helpIcons,
        menuIcon: menuIcon,
        continueContent: continueContent,
        v1: v1,
        hideActions: hideActions }, props))));
    return additionalContent ? (React.createElement(Grid, { container: true, direction: "row", alignItems: 'center' },
        mainBody,
        additionalContent ? (!canViewAdditional || isSmall ? (additionalContent) : (React.createElement("span", { style: {
                // padding: theme.spacing(1),
                paddingLeft: 0,
                paddingBottom: 0,
                paddingRight: 0,
            } }, additionalContent))) : (additionalContent))) : (mainBody);
}));
export var OrderRoutes = withRouter(OrderRoutesComponent);
