var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ItemBody from './ItemBody';
import ItemHeader from './ItemHeader';
import { ExpandedRow } from '../../components/BasicList/Body/ExpandedRow';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { withChannelListStore } from '../Chat/stores/ChannelList';
import { StyledBadgeV5 } from '../../components/StyledBadge';
import BaseTooltip from '../../components/BaseTooltip';
import { i18n, KEYS } from '../../i18n';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            '&.MuiBadge-root': {
                position: 'absolute',
                right: 0,
            },
        },
    });
});
function IssueItem(props) {
    var _a;
    var classes = useStyles();
    var isParentCollectionOrderQuote = ((_a = props === null || props === void 0 ? void 0 : props.record) === null || _a === void 0 ? void 0 : _a.parent_collection_name) === 'order_quotes';
    if (isParentCollectionOrderQuote) {
        return React.createElement(ItemHeader, __assign({}, props));
    }
    var content = React.createElement(ExpandedRow, __assign({}, props, { v2: true, body: React.createElement(ItemBody, __assign({}, props)), header: React.createElement(ItemHeader, __assign({}, props)) }));
    var chat = props.channelListStore.state.channelsTotalUnread.find(function (e) { var _a; return e._id === ((_a = props.record.chat) === null || _a === void 0 ? void 0 : _a._id); });
    return (React.createElement(React.Fragment, null,
        ((chat === null || chat === void 0 ? void 0 : chat.totalUnread) && (React.createElement(BaseTooltip, { title: i18n.getResource(i18n.language, KEYS.APP, 'unread_messages') },
            React.createElement(StyledBadgeV5, { className: classes.root, badgeContent: chat.totalUnread, key: chat.totalUnread },
                React.createElement("span", null))))) ||
            null,
        content));
}
export default withChannelListStore(IssueItem);
