var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createContext, useReducer } from 'react';
import { FetchOrderFull } from '../../../../graphql/order';
var initialState = {
    record: null,
    lastUpdates: null,
};
var OrderListItemStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = OrderListItemStore.Provider, Consumer = OrderListItemStore.Consumer;
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["DELETE_ITEM"] = "DELETE_ITEM";
    ACTIONS["UPDATE_ITEM"] = "UPDATE_ITEM";
})(ACTIONS || (ACTIONS = {}));
export var CUSTOM_EVENTS_ORDER_LIST_ITEM = {
    NEW_NOTIFICATION: "CUSTOM_EVENTS_ORDER_LIST_ITEM_NEW_NOTIFICATION",
};
export var OrderListItemProvider = function (_a) {
    var _b;
    var children = _a.children, defaultValue = _a.defaultValue, props = __rest(_a, ["children", "defaultValue"]);
    var initState = __assign(__assign({}, JSON.parse(JSON.stringify(initialState))), JSON.parse(JSON.stringify(defaultValue)));
    var _c = __read(React.useState([
        {
            name: CUSTOM_EVENTS_ORDER_LIST_ITEM.NEW_NOTIFICATION,
            context: document.body,
            callback: function (_a) {
                var _b, _c;
                var detail = _a.detail;
                if (((_b = defaultValue.record) === null || _b === void 0 ? void 0 : _b._id) === detail.data.item_id) {
                    // config.onChange({ record: { ...defaultValue.record } as any });
                    if (props.config)
                        props.config.client
                            .query({
                            query: FetchOrderFull,
                            variables: {
                                _id: (_c = defaultValue.record) === null || _c === void 0 ? void 0 : _c._id,
                            },
                        })
                            .then(function (res) {
                            try {
                                if (res.data.order) {
                                    var _a = res.data.order, updatedAt = _a.updatedAt, updates = __rest(_a, ["updatedAt"]);
                                    onChange(__assign(__assign({}, defaultValue), { lastUpdates: __assign(__assign({}, detail.data), { time: Date.now() }), record: __assign(__assign(__assign({}, defaultValue.record), updates), { full: 1 }) }));
                                    setTimeout(function () {
                                        onChange({
                                            lastUpdates: null,
                                        });
                                    }, 3000);
                                }
                            }
                            catch (e) { }
                        })
                            .catch(function (e) {
                            console.log(e);
                        });
                }
            },
        },
    ]), 1), events = _c[0];
    React.useEffect(function () {
        events.forEach(function (_a) {
            var name = _a.name, context = _a.context, callback = _a.callback;
            context.addEventListener(name, callback);
        });
        return function () {
            events.forEach(function (_a) {
                var name = _a.name, context = _a.context, callback = _a.callback;
                context.removeEventListener(name, callback);
            });
        };
    }, [(_b = defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.record) === null || _b === void 0 ? void 0 : _b._id]);
    var _d = __read(useReducer(function (state, action) {
        var _a;
        switch (action.type) {
            case ACTIONS.DELETE_ITEM:
            case ACTIONS.UPDATE_ITEM: {
                var updates = __assign(__assign({}, state), action.payload);
                if (typeof ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.lastUpdates) === 'undefined') {
                    updates.lastUpdates = null;
                }
                return updates;
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _d[0], dispatch = _d[1];
    var onChange = function (payload) {
        var e = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            e[_i - 1] = arguments[_i];
        }
        dispatch({
            type: ACTIONS.UPDATE_ITEM,
            payload: JSON.parse(JSON.stringify(payload)),
        });
    };
    var value = {
        state: state,
        onChange: onChange,
    };
    return React.createElement(Provider, { value: value }, children);
};
export var withOrderListItemStore = function (Component) {
    return function (props, ref) {
        return (React.createElement(Consumer, null, function (orderListItemProps) {
            return React.createElement(Component, __assign({}, __assign(__assign({}, props), { orderListItemProps: orderListItemProps })));
        }));
    };
};
