export var FORWARDER_USER_BASE = "_id\n            firstName\n            lastName\n            email\n            rating\n            appType\n            encoded\n            company{\n              _id\n              name\n              origin_name\n              settings{\n                isIziBranding\n                canRequestOfferForCA\n                isViewAllOrders\n                isDefaultFastPayment\n                defaultFastPaymentPercentage\n              }\n              type\n            }";
export var LIST_SIMPLE_DATA = "\n    _id\n    count\n";
export var USER_ROLES_BASE_SHORT = "\n       _id\n       name\n       description\n       tools{\n            general\n            admin\n            settings\n       }\n";
export var USER_BASE_I = "\n        _id\n        createdAt\n        updatedAt\n        email\n        fullName\n        appType\n        firstName\n        lastName\n        rating\n        roles\n        encoded\n        rolesV1{\n          " + USER_ROLES_BASE_SHORT + "  \n        }\n         avatar{\n             path\n        }\n";
export var LOCATION_ADDRESS = "streetNumber \n        address\n        company\n        place_id\n        streetName\n        state\n        country\n        postal\n        area\n        location{\n            type\n            coordinates\n            zone\n            rawOffset\n        }";
export var USER_COMPANY_BASE_I = "\n      _id\n      createdAt\n      updatedAt\n      name\n      vatCode\n      type\n      companiesParents\n      isFastPayment\n      fastPaymentPercentage\n      addressV1{\n        " + LOCATION_ADDRESS + "\n      }\n";
export var USER_COMPANY_BASE_I2 = "\n      " + USER_COMPANY_BASE_I + "\n      bank\n      bankCode\n      bankSwiftCode\n      bankAccountCode\n";
export var USER_WITH_COMPANY_BASE_I = "\n    " + USER_BASE_I + "\n     company{\n      " + USER_COMPANY_BASE_I + "\n     }\n";
export var BASE_MAIN_QUERY = "\n    _id\n    createdAt\n    updatedAt\n    isDeleted\n    createdBy{\n        _id\n    }\n";
export var BASE_QUERY = "\n   " + BASE_MAIN_QUERY + "\n    createdBy{\n        " + USER_WITH_COMPANY_BASE_I + "\n    }\n    updatedBy{\n         " + USER_WITH_COMPANY_BASE_I + "\n    } \n";
export var COMPANY_TERM_BUDGET = "\n      " + BASE_QUERY + "\n     term\n     requested_budget\n     budget\n     balance\n     comment\n     status\n     currency\n     chat{\n        _id\n     }\n";
export var USER_COMPANY_BASE = "\n    " + BASE_QUERY + "\n     name\n     brandName\n     origin_name\n     type\n     companyCode\n     parentName\n     phone\n     p44Code\n     status\n\n  \n     addressV1{\n        " + LOCATION_ADDRESS + "\n     }\n     paymentTerm{\n        " + COMPANY_TERM_BUDGET + "\n     }\n     orders\n     hasOffer\n     companiesParents\n     sync\n     companyEmail\n     companyEmailName\n     vatCode\n     turnoverCurrency\n     forwarderOrderMarkUpPercentage\n     forwarderOrderMarkUpMinPrice\n     forwarderOrderMarkUpMinPriceCurrency\n     forwarderOrderMarkUpMaxPrice\n     forwarderOrderMarkUpMaxPriceCurrency\n     isFastPayment\n     fastPaymentPercentage\n     bank\n     bankCode\n     bankSwiftCode\n     bankAccountCode\n     turnover\n     totalUsers \n     state\n     logo{\n        _id\n        path\n     }\n     settings{\n        canP44OnBoard\n        only_my_companies_receive_notifications\n        has_email_notifications\n        canRequestOfferForCA\n        canViewAllOrders\n        canViewAllOrderBookings\n        paymentAutoApprove\n        autoArchiveOrderInDays\n        autoArchiveOpenOrderOrderInDays\n        invoiceTermPayment\n        chat_open_from_my_team\n        affiliateFraction\n        affiliateRevenue\n        chat_open_from_to_team\n        isIziBranding\n        isViewAllOrders\n        hasToDo\n        canPublishOrderForAnotherUser\n        isAbleToCalMarginForCA\n        accounting_emails\n        weightMeasure\n        chat_users{\n            " + USER_BASE_I + "\n            company{\n                " + USER_COMPANY_BASE_I + "\n            }\n        }\n        isDefaultFastPayment\n        defaultFastPaymentPercentage\n     }\n";
export var USER_COMPANY_MORE = "\n    " + USER_COMPANY_BASE + "\n       forwarders{\n        " + FORWARDER_USER_BASE + "\n     }\n";
export var BASE_QUERY_WITH_AVATAR = "\n   " + BASE_MAIN_QUERY + "\n    createdBy{\n        " + USER_WITH_COMPANY_BASE_I + "\n        \n         avatar{\n             path\n        }\n    }\n    updatedBy{\n         " + USER_WITH_COMPANY_BASE_I + "\n         \n         avatar{\n             path\n        }\n    } \n";
export var BASE_RESPONSE = "\n   status\n";
export var BASE_INDEX_RESPONSE = "\n   order\n";
export var UPLOAD_TYPE_BASE = "\n      " + BASE_QUERY + "\n     name\n     description\n      origin\n      approvedAt\n      isGlobal\n";
export var LOCATION_ADVANCED_BASE = "_id\n       email\n       access\n       documentType{\n        " + UPLOAD_TYPE_BASE + "\n       }";
var WAREHOUSE_SHORT_V1 = "\n    _id\n        name\n        emails\n        company{\n            _id\n        }\n          address{\n           " + LOCATION_ADDRESS + "\n          }";
export var WAREHOUSE_SHORT = "\n  " + WAREHOUSE_SHORT_V1 + "\n          parent{\n          " + WAREHOUSE_SHORT_V1 + "\n          }\n";
var LOCATION_BASE_V1 = "\n  " + BASE_MAIN_QUERY + "\n    origin\n    mode\n    name\n    approvedAt \n      address{\n       " + LOCATION_ADDRESS + "\n      }\n      warehouse{\n       " + WAREHOUSE_SHORT + "\n      }\n      members{\n        phone\n        email\n        name\n        notify\n      }\n      additionalMembers{\n        phone\n        email\n        name\n        notify\n      }\n      email\n      companyId{\n        _id\n        name\n        createdAt\n        vatCode\n        isDeleted\n        isNotEditable\n        addressV1{\n            " + LOCATION_ADDRESS + "\n        }\n      }\n      companyAddress{\n       " + LOCATION_ADDRESS + "\n      }\n      companyName\n      companyVAT\n      startTime\n      endTime";
export var LOCATION_BASE = "\n      " + LOCATION_BASE_V1 + "\n";
export var LOCATION_BASE_LIST = "\nlastIssue{\n      _id\n      title\n      description\n      status\n    }\n    " + LOCATION_BASE_V1 + "\n      warehouse{\n       " + WAREHOUSE_SHORT + "\n      }\n      warehouses{\n       " + WAREHOUSE_SHORT + "\n      }\n      my_warehouses{\n       " + WAREHOUSE_SHORT + "\n      }\n      ";
export var LOCATION_ADDITIONAL = "\n" + LOCATION_BASE + "\n locationAdvanced{\n      " + LOCATION_ADVANCED_BASE + "\n      }";
export var UPLOAD_BASE = "\n      " + BASE_QUERY + "\n     needSign\n     isSendingToEmails\n     isAssigned\n     isRefreshing\n     generatedAt\n     access\n     emails\n     type{\n        " + UPLOAD_TYPE_BASE + "\n     }\n     parent_collection_name\n     name\n     size\n     status\n     path\n     logs{\n        createdAt\n        changes\n        createdBy{\n            firstName\n            lastName\n            company{\n                _id\n            }\n        }\n     }\n";
export var INVOICE_TYPE_BASE = "\n " + BASE_QUERY + "\n name\n description\n serialPrefix\n";
export var ORDER_LESS_BASE = "\n        _id\n        orderId\n                    sorting_pickupAt\n                    sorting_deliveryAt\n                    sorting_countryPickup\n                    sorting_countryDelivery\n                    sorting_totalWeight\n";
