var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Formik } from 'formik';
import { inject, observer, useLocalStore } from 'mobx-react';
import { Checkbox, FormControlLabel, Grid, Radio, useMediaQuery, Box } from '@material-ui/core';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { formUserErrorMessage, onlySpacesRegExp, pswRegExp, vatCodeRegExp } from '../../common';
import { BasicDialog, EmptyLayout, FormikTextField, FormikTextFieldForPassword, TextOverflow, WholePageFormLayout, } from '../../components';
import { phoneRegExp } from '../../common/validators';
import { KEYS } from '../../i18n';
import { BaseUserAppTYpe, USER_CATEGORY } from '../../stores/common';
import UserAppTypView from '../../components/UserAppTypeView';
import { useUserTypeFormStyles } from '../../styles';
import { theme } from '../../theme';
import { googleTagManager, useQuery } from '../../utils1';
import { getSnapshot } from 'mobx-state-tree';
var canJoin;
export var RegisterPage = inject('store')(observer(function (props) {
    var className = useUserTypeFormStyles();
    var store = props.store, _a = props.appType, appType = _a === void 0 ? BaseUserAppTYpe.CUSTOMER : _a, isAffiliate = props.isAffiliate;
    var langAuth = useTranslation(KEYS.AUTH);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var registerStore = store.registerStore, accountStore = store.accountStore;
    var isAsyncActionInProgress = registerStore.isAsyncActionInProgress;
    var _b = __read(React.useState(false), 2), isJoin = _b[0], setIsJoin = _b[1];
    var _c = __read(React.useState(null), 2), joinCompany = _c[0], setJoinCompany = _c[1];
    var confirmJoinCompanyDialog = React.useRef(null);
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var query = useQuery();
    var token = query.get('token');
    var registerToken = query.get('registerToken');
    var step = token ? 2 : 1;
    var localStore = useLocalStore(function () { return ({
        userErrorMessage: '',
        isRegistered: false,
        initialValues: __assign(__assign({ phoneNumber: '', promoCode: query.get('code') || '', email: '', source: step }, (!isAffiliate && { companyName: '', vatCode: '', companyType: appType })), (step > 1 && __assign(__assign({}, getSnapshot(accountStore.tempUser)), { phoneNumber: getSnapshot(accountStore.tempUser).phone, companyType: getSnapshot(accountStore.tempUser).appType[0], firstName: '', lastName: '', password: '', password2: '', checked: false, terms: false }))),
        onSignUpRequested: function (data) {
            localStore.userErrorMessage = '';
            registerStore
                .signUp(data)
                .then(function (user) {
                var _a;
                var initialValues = localStore.initialValues;
                if (initialValues.source === 1) {
                    Object.assign(localStore.initialValues, __assign(__assign({}, user), { password: '', password2: '', phoneNumber: user.phone, companyType: user.appType[0], token: (_a = user.token) === null || _a === void 0 ? void 0 : _a.token, source: 2 }));
                }
                else {
                    localStore.isRegistered = true;
                }
                googleTagManager('event', 'sign_up', {
                    event_name: "sign_up",
                    value: JSON.stringify(data),
                    user_id_dimension: user._id,
                    user_id: [user._id, user.firstName, user.lastName].join(', '),
                    company_id: [user.company._id, user.company.name, user.company.vatCode].join(', '),
                    app_type: user.company.type,
                });
                googleTagManager('set', 'user_properties', {
                    user_id: [user._id, user.firstName, user.lastName].join(', '),
                    company_id: [user.company._id, user.company.name, user.company.vatCode].join(', '),
                    app_type: user.company.type,
                });
                googleTagManager('user_id', [user._id, user.firstName, user.lastName].filter(function (e) { return e; }).join(', '));
            })
                .catch(function (error) {
                localStore.userErrorMessage = formUserErrorMessage(error);
            })
                .finally(function () {
                accountStore.clearOnSignUp();
            });
        },
        clearUserErrorMessage: function () {
            localStore.userErrorMessage = '';
        },
    }); });
    useEffect(function () {
        localStore.clearUserErrorMessage();
    }, [localStore]);
    var error = localStore.userErrorMessage;
    var isRegistered = localStore.isRegistered;
    var initialValues = localStore.initialValues;
    return (React.createElement(EmptyLayout, { key: initialValues.source, v2: isAffiliate, style: __assign({ maxWidth: 900 }, (isAffiliate && { maxWidth: 1400 })), paperStyle: { marginTop: -77, display: 'flex', justifyContent: 'center' } },
        React.createElement(Formik, { initialValues: initialValues, validationSchema: Yup.object(__assign({ email: Yup.string()
                    .typeError(langForm.t('form_input_validate_required'))
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                    .email(langForm.t('form_input_validate_invalid_email'))
                    .required(langForm.t('form_input_validate_required')), phoneNumber: Yup.string()
                    .typeError(langForm.t('form_input_validate_required'))
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                    .matches(phoneRegExp, langForm.t('form_input_validate_invalid_phone'))
                    .required(langForm.t('form_input_validate_required')), promoCode: Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')) }, (initialValues.source > 1 && __assign({ firstName: Yup.string()
                    .typeError(langForm.t('form_input_validate_required'))
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')), lastName: Yup.string()
                    .typeError(langForm.t('form_input_validate_required'))
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')), password: Yup.string()
                    .typeError(langForm.t('form_input_validate_required'))
                    .required(langForm.t('form_input_validate_required'))
                    .min(8, langForm.t('form_input_validate_min_char').replace(/_X_/g, '8'))
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50')), password2: Yup.string()
                    .typeError(langForm.t('form_input_validate_required'))
                    .required(langForm.t('form_input_validate_required'))
                    .matches(pswRegExp, langForm.t('form_input_validate_invalid_psw'))
                    .test('password match', langForm.t('form_input_validate_psw_not_match'), function (item) {
                    var _a, _b;
                    return ((_a = this.parent) === null || _a === void 0 ? void 0 : _a.password) === ((_b = this.parent) === null || _b === void 0 ? void 0 : _b.password2);
                }) }, (!isAffiliate && {
                companyName: Yup.string()
                    .typeError(langForm.t('form_input_validate_required'))
                    .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/g, '250'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
                vatCode: Yup.string()
                    .typeError(langForm.t('form_input_validate_required'))
                    .min(5, langForm.t('form_input_validate_min_char').replace(/_X_/g, '5'))
                    .max(200, langForm.t('form_input_validate_max_char').replace(/_X_/g, '200'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .matches(vatCodeRegExp, langForm.t('form_input_invalid_vat_code'))
                    .required(langForm.t('form_input_validate_required')),
            }))))), onSubmit: function (values) {
                localStore.onSignUpRequested(__assign(__assign(__assign(__assign(__assign({}, ((token && { token: token }) || (initialValues.token && { token: initialValues.token }))), (registerToken && { registerToken: registerToken })), { phone: values.phoneNumber, promoCode: values.promoCode, source: values.source, email: values.email }), ((!isAffiliate && {
                    companyType: values.companyType,
                    companyName: values.companyName,
                    vatCode: values.vatCode,
                }) || { companyType: BaseUserAppTYpe.AFFILIATE, category: USER_CATEGORY.AFFILIATE })), (initialValues.source > 1 && {
                    lastName: values.lastName,
                    firstName: values.firstName,
                    password: values.password,
                })));
            } }, function (formik) {
            var onBlur = function (e) { return __awaiter(void 0, void 0, void 0, function () {
                var _a, name, value, companyWithSameVatCode;
                var _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _a = e.target, name = _a.name, value = _a.value;
                            if (!(name === 'vatCode' && value)) return [3 /*break*/, 2];
                            setIsJoin(false);
                            canJoin = false;
                            return [4 /*yield*/, accountStore.currentCompany.checkVatCodeExists(value)];
                        case 1:
                            companyWithSameVatCode = _c.sent();
                            if (companyWithSameVatCode) {
                                setJoinCompany(companyWithSameVatCode);
                                (_b = confirmJoinCompanyDialog.current) === null || _b === void 0 ? void 0 : _b.click();
                            }
                            _c.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); };
            var onFieldChangeHandler = function (e) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    formik.handleChange(e);
                    localStore.clearUserErrorMessage();
                    return [2 /*return*/];
                });
            }); };
            var onClick = function (e) {
                localStore.clearUserErrorMessage();
                formik.setFieldValue(e.target.name, e.target.value);
            };
            var isNoneBrand = process.env.REACT_APP_NONE_BRAND;
            var disabled = !isNoneBrand && !(formik.values.terms || initialValues.source > 1);
            var subText = initialValues.source === 1
                ? null
                : langAuth.t('registration_page_our_team_will_review_your_request_explanatory_text');
            return (React.createElement(React.Fragment, null,
                React.createElement(BasicDialog, { onOk: function () {
                        canJoin = true;
                        setIsJoin(true);
                    }, onClose: function () {
                        if (!canJoin) {
                            setJoinCompany(null);
                            formik.setFieldValue('vatCode', '');
                        }
                    }, body: React.createElement(Box, { textAlign: 'center' }, langForm.t('form_ask_for_company_join_body').replace(/_X_/, (joinCompany === null || joinCompany === void 0 ? void 0 : joinCompany.name) || '')), title: langForm.t('form_ask_for_company_join_title'), actionOkTitle: langApp.t('app_join'), withoutWrapper: true, bodyStyle: { alignItems: 'center', justifyContent: 'center' }, dialogProps: { maxWidth: 'xl' }, actionInit: function (onOpen) { return React.createElement("span", { ref: confirmJoinCompanyDialog, onClick: onOpen }); } }),
                React.createElement(WholePageFormLayout, { v1: true, v2: isAffiliate, v3: initialValues.source === 1, hideRecapture: initialValues.source > 1, isLoading: isAsyncActionInProgress, error: error, wholePageFormBackButtonVisible: true, wholePageFormBackButton: {
                        navPath: '/login',
                    }, wholePageFormTitle: isRegistered
                        ? {
                            text: langAuth.t(initialValues.source === 1
                                ? 'registration_page_check_email'
                                : 'registration_page_thanks_submitting_registration_request_explanatory_text'),
                            style: { textAlign: 'center' },
                        }
                        : {
                            text: langAuth.t('sign_up_page_title'),
                        }, submitButtonWithLoading: {
                        text: initialValues.source === 1 ? langApp.t('app_next') : langAuth.t('sign_up_page_title'),
                        disabled: disabled,
                    }, wholePageFormHelperNavLinkVisible: false, wholePageFormFooterNavLinkVisible: true, wholePageFormFooterNavLink: {
                        paragraphText: langAuth.t('login_page_i_have_account_explanatory_text'),
                        navPath: '/login',
                        linkText: langAuth.t('login_page_log_in'),
                    }, successfulRequest: isRegistered, path: '/login', buttonText: langAuth.t('sign_up_page_log_in_explanatory_text'), subText: subText },
                    [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.CUSTOMER].includes(formik.values.companyType) &&
                        !isAffiliate &&
                        initialValues.source > 1 ? (React.createElement(Grid, { container: true, spacing: 1 }, [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.CUSTOMER].map(function (type) {
                        var _a;
                        return (React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center', onClick: function () { return onClick({ target: { name: 'companyType', value: type } }); }, item: true, xs: 12, md: 6, key: type },
                            React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center', className: clsx(className.root, (_a = {},
                                    _a[className.active] = type === formik.values.companyType,
                                    _a)) },
                                React.createElement(UserAppTypView, { type: type }),
                                React.createElement(Radio, { color: "primary", checked: type === formik.values.companyType }))));
                    }))) : null,
                    !isAffiliate && initialValues.source > 1 ? (React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                            React.createElement(FormikTextField, { v3: true, name: "companyName", label: langForm.t('form_input_name_company'), value: formik.values.companyName, onChange: onFieldChangeHandler })),
                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                            React.createElement(FormikTextField, { v3: true, name: "vatCode", label: langForm.t('form_input_name_vat_code'), value: formik.values.vatCode, warning: !isJoin && (joinCompany === null || joinCompany === void 0 ? void 0 : joinCompany.name) &&
                                    langForm.t('form_ask_for_company_join_body').replace('_X_', joinCompany === null || joinCompany === void 0 ? void 0 : joinCompany.name), onChange: onFieldChangeHandler, onBlur: onBlur })))) : null,
                    initialValues.source > 1 ? (React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, xs: 12, md: isAffiliate ? 12 : 6 },
                            React.createElement(FormikTextField, { v3: true, name: "firstName", label: langForm.t('form_input_name_first_name'), value: formik.values.firstName, onChange: onFieldChangeHandler })),
                        React.createElement(Grid, { item: true, xs: 12, md: isAffiliate ? 12 : 6 },
                            React.createElement(FormikTextField, { v3: true, name: "lastName", label: langForm.t('form_input_name_last_name'), value: formik.values.lastName, onChange: onFieldChangeHandler })))) : null,
                    React.createElement(FormikTextField, { type: "email", name: "email", v3: true, label: langForm.t('form_input_name_email'), value: formik.values.email, onChange: onFieldChangeHandler }),
                    React.createElement(FormikTextField, { v3: true, name: "phoneNumber", label: langForm.t('form_input_name_phone'), value: formik.values.phoneNumber, onChange: onFieldChangeHandler }),
                    initialValues.source > 1 ? (React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, xs: 12, md: isAffiliate ? 12 : 6, style: { marginBottom: theme.spacing(0.5) } },
                            React.createElement(FormikTextFieldForPassword, { v3: true, name: "password", label: langForm.t('form_input_name_password'), value: formik.values.password, onChange: onFieldChangeHandler })),
                        React.createElement(Grid, { item: true, xs: 12, md: isAffiliate ? 12 : 6, style: { marginBottom: theme.spacing(0.5) } },
                            React.createElement(FormikTextFieldForPassword, { v3: true, name: "password2", label: langForm.t('form_input_name_password_confirm'), value: formik.values.password2, onChange: onFieldChangeHandler })))) : null,
                    initialValues.source === 2 ? (React.createElement(FormikTextField, { name: "promoCode", label: langApp.t('promo_code'), value: formik.values.promoCode, onChange: onFieldChangeHandler })) : null,
                    (initialValues.source === 1 && !isNoneBrand && (React.createElement(React.Fragment, null,
                        React.createElement(FormControlLabel, { id: 'privacyTerms', style: __assign(__assign({}, (formik.values.terms && { color: theme.palette.success.light })), { marginTop: '1.5rem' }), onClick: function (e) {
                                return onFieldChangeHandler({ target: { name: 'terms', value: e.target.checked } });
                            }, control: React.createElement(Checkbox, { name: "terms", checked: formik.values.terms, color: "primary" }), label: React.createElement(TextOverflow, { v1: true, variant: 'caption' },
                                langAuth.t('sign_up_page_yes_i_agree_to_privacy_policy_checkbox'),
                                ' ',
                                React.createElement(BasicDialog, { dialogProps: {
                                        fullWidth: true,
                                        maxWidth: 'xl',
                                    }, hidActionOkBth: true, body: React.createElement("iframe", { frameBorder: 'none', style: { width: '100%', height: 'calc(100vh - 250px)' }, src: 'https://izi-logistics-prod.s3.us-east-2.amazonaws.com/public/Izi-logistics_sutartis.pdf' }), title: langAuth.t('sign_up_page_yes_i_agree_to_privacy_policy_terms_label'), actionInit: function (onClick) { return (React.createElement("a", { href: "#", onClick: onClick, style: { color: theme.palette.success.light } }, langAuth.t('sign_up_page_yes_i_agree_to_privacy_policy_terms_label'))); } })) })))) ||
                        null)));
        })));
}));
export default RegisterPage;
