var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseList } from '../../components';
import { FetchIssues } from '../../graphql/issues';
import IssueItem from './Item';
import { theme } from '../../theme';
import { i18n, KEYS } from '../../i18n';
import BaseListTitle from '../../components/BasicList/Header/BaseListTitle';
import FormItem from './FormItem';
import { Typography } from '@material-ui/core';
import { Issues } from '../../models/Issues';
import { getSnapshot } from 'mobx-state-tree';
import IssueFilters from './Filters';
import { withChannelListStore } from '../Chat/stores/ChannelList';
import { inject } from 'mobx-react';
import { BaseUserAppTYpe } from '../../stores';
function IssuesList(props) {
    var _a;
    var store = props.store;
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var _b = __read(React.useState(1), 2), updated = _b[0], setUpdated = _b[1];
    var onChange = function () { return setUpdated(Date.now()); };
    var v1 = props.parent_collection_name === 'order_quotes';
    var isCustomer = store.accountStore.currentUser.appType[0] === BaseUserAppTYpe.CUSTOMER;
    var _config = __assign(__assign({}, props.configList), { title: (React.createElement(React.Fragment, null,
            React.createElement(BaseListTitle, { title: langApp.t('main_issue') }),
            !props.isAdmin && (!v1 || (v1 && isCustomer)) && (React.createElement("div", null,
                React.createElement(FormItem, { config: props.config, onChange: onChange, item: __assign(__assign({}, getSnapshot(Issues.create())), (parent && { parent: props.parent, parent_collection_name: props.parent_collection_name })) }))))), headerStyle: { padding: theme.spacing(0, 1) }, hideSort: true, hasHeaderSort: true, fullWidth: v1, searchMDSize: 5, headerColumns: [
            { size: 2, name: langApp.t('main_user') },
            {
                size: 2,
                name: v1 ? langForm.t('form_title') : langForm.t('form_input_name'),
                sortable: true,
                sortKey: 'title',
            },
            { size: 3, name: langForm.t('form_input_description') },
            {
                size: 2,
                name: langForm.t('form_input_name_created_at'),
                sortable: true,
                sortKey: 'createdAt',
            },
            {
                size: 2,
                name: langForm.t('form_status'),
                sortable: true,
                sortKey: 'status',
            },
            {
                size: 1,
                name: i18n.getResource(i18n.language, KEYS.FORM, 'form_input_actions'),
                style: { display: 'flex', justifyContent: 'flex-end' },
            },
            {
                size: 1,
                version: 1,
                name: '',
            },
        ], filters: function (filter, onChange, options) {
            if (options === void 0) { options = {}; }
            return (React.createElement(IssueFilters, __assign({ v1: v1 }, { options: options, onChange: onChange, filter: filter, config: props.config, isAdmin: props.isAdmin })));
        }, rowContent: function (record, onChange) { return (React.createElement(IssueItem, __assign({}, __assign(__assign({}, props), { record: record, _config: _config, onChange: onChange, parentOnChange: props.onChange })))); }, noData: !props.isAdmin && (!v1 || (v1 && isCustomer)) && (React.createElement(React.Fragment, null,
            React.createElement(Typography, null, langOrderList.t('order_list_filter_no_data_general')),
            React.createElement(FormItem, { config: props.config, onChange: onChange, item: __assign(__assign({}, getSnapshot(Issues.create())), (parent && { parent: props.parent, parent_collection_name: props.parent_collection_name })) }))) });
    var defaultFilter = __assign({}, props.defaultFilter);
    var baseListProps = {
        client: (_a = props.config) === null || _a === void 0 ? void 0 : _a.client,
        query: FetchIssues,
        defaultFilter: defaultFilter,
        useUrlQuery: !v1,
        searchFields: ['_id', 'title', 'description'],
        searchFieldsMatches: [
            { key: '_id', text: langForm.t('form_input_name') },
            { key: 'title', text: langForm.t('form_input_name') },
            { key: 'description', text: langForm.t('form_input_description') },
        ],
        onLoad: function (_a) {
            var data = _a.data;
            if (!v1) {
                props.channelListStore.setChannelReadStatusData(data
                    .filter(function (e) { var _a; return (_a = e.chat) === null || _a === void 0 ? void 0 : _a._id; })
                    .map(function (_a) {
                    var chat = _a.chat;
                    return ({ _id: chat._id, totalUnread: 0 });
                }), true);
            }
        },
    };
    return React.createElement(BaseList, { key: updated, options: baseListProps, title: langApp.t('main_issue'), config: _config });
}
export default inject('store')(withChannelListStore(IssuesList));
