var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useEffect } from 'react';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TextOverflow } from '../../TextOverflow';
import { withCalendarStore } from '../store';
import { sequenceGenerator, sequenceGeneratorV2, timeViewFormat2ToValue, toDateOnly } from '../../../utils';
import { DayHourView } from './DayHourView';
import { dateViewFormat, timeViewFormat, timeViewFormat3 } from '../../DateView';
import clsx from 'clsx';
import { useMonthDayStyles } from '../MonthViewV1/style';
import { DayHolidayDetails } from '../MonthViewV1/DayHolidayDetails';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { theme } from '../../../theme';
import { useTableStyles } from '../../../styles/table';
import RampsSwitcher from './RampsSwitcher';
import { BasicDialog } from '../../Dialog';
import Loader from '../../Loader';
import DayOptions from '../MonthViewV1/DayOptions';
import { WAREHOUSE_BOOKING_EVENT } from '../BookingNewOrder/types';
import { getSlots, getWeekDays } from './utils';
export var useDaysViewStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            borderSpacing: 0,
        },
        gmt: {
            position: 'relative',
            overflow: 'initial',
            '&::before': {
                content: "' '",
                width: 2,
                height: '323%',
                right: 0,
                top: 20,
                transform: 'translate(89%,-50%)',
                zIndex: 2,
                backgroundColor: theme.palette.grey[100],
                position: 'absolute',
            },
        },
        firstColumn: {},
    });
});
var parseTransportations = function (transportations) {
    return transportations
        .map(function (e) {
        return e.items
            .map(function (e) {
            var _a, _b;
            var key = 'end';
            var booked = e[key + "Time"][0];
            if (!booked) {
                key = 'start';
                booked = e[key + "Time"][0];
            }
            if (!booked)
                return [];
            var v = timeViewFormat2ToValue(booked.toString());
            var timeToSpend = e.order.timeToSpend || ((_b = (_a = e.order.timeToSpends) === null || _a === void 0 ? void 0 : _a.find(function (t) { return t.name === e._id + "_" + key; })) === null || _b === void 0 ? void 0 : _b.value) || 60;
            return [v, v + timeToSpend / 60];
        })
            .reduce(function (a, b) { return __spread(a, b); }, []);
    })
        .reduce(function (a, b) { return __spread(a, b); }, []);
};
export var DaysView = withCalendarStore(function (_a) {
    var _this = this;
    var _b, _c, _d;
    var current = _a.current, loadAll = _a.loadAll, mode = _a.mode, countDays = _a.countDays, changeOrderBooking = _a.changeOrderBooking, _e = _a.state, holidays = _e.holidays, filter = _e.filter, calendarState = __rest(_e, ["holidays", "filter"]), props = __rest(_a, ["current", "loadAll", "mode", "countDays", "changeOrderBooking", "state"]);
    var classesTable = useTableStyles();
    var classes = useDaysViewStyles();
    var classesMonth = useMonthDayStyles();
    var tableContainer = React.useRef(null);
    var spanContainer = React.useRef(null);
    var headerDom = React.useRef(null);
    var langApp = useTranslation(KEYS.APP);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var isWeek = countDays === 7;
    var _f = __read(React.useState(null), 2), changes = _f[0], setChanges = _f[1];
    var _g = __read(React.useState([]), 2), days = _g[0], setDays = _g[1];
    var _h = __read(React.useState(1), 2), update = _h[0], setUpdate = _h[1];
    var _j = __read(React.useState(location.pathname.match(/^\/public\//i)
        ? isWeek
            ? Array.from({ length: countDays }).map(function (e, i) {
                return moment(new Date(new Date(current).setDate(current.getDate() + i + 1))).format('dddd');
            })
            : [moment(current).format('dddd')]
        : []), 2), switchers = _j[0], setSwitchers = _j[1];
    var _k = __read(React.useState([]), 2), hours = _k[0], setHours = _k[1];
    useEffect(function () {
        loadAllData();
    }, [current]);
    useEffect(function () {
        if (update !== 1) {
            loadAllData({ softRefresh: 1 });
        }
    }, [update]);
    var loadAllData = function (opt) {
        if (opt === void 0) { opt = {}; }
        var softRefresh = opt.softRefresh;
        if (isWeek) {
            var _days = getWeekDays(current, countDays);
            setDays(_days);
            loadAll({
                fields: [
                    { name: 'start', value: toDateOnly(_days[0]) },
                    { name: 'end', value: toDateOnly(_days[_days.length - 1]) },
                    { name: 'mode', value: mode },
                ],
            }, { softRefresh: softRefresh }).then(onFinishLoad);
        }
        else {
            setDays([current]);
            loadAll({
                fields: [
                    { name: 'start', value: toDateOnly(current) },
                    { name: 'end', value: toDateOnly(current) },
                    { name: 'mode', value: mode },
                ],
            }, { softRefresh: softRefresh }).then(onFinishLoad);
        }
    };
    var onFinishLoad = function (data) {
        var _a, _b;
        try {
            var bookedTime = __spread(parseTransportations(data.transportations), parseTransportations(data.transportationsBusy));
            var timesMin = __spread(data.workingDays.map(function (e) { var _a; return ((_a = e.minTime) === null || _a === void 0 ? void 0 : _a.start) && timeViewFormat2ToValue(e.minTime.start); }).filter(function (e) { return e; }), bookedTime);
            var timesMax = __spread(data.workingDays.map(function (e) { var _a; return ((_a = e.maxTime) === null || _a === void 0 ? void 0 : _a.end) && timeViewFormat2ToValue(e.maxTime.end); }).filter(function (e) { return e; }), bookedTime);
            setHours(sequenceGenerator((_a = Math.min).call.apply(_a, __spread([[]], timesMin)), (_b = Math.max).call.apply(_b, __spread([[]], timesMax))));
        }
        catch (e) {
            console.log(e);
        }
    };
    var availableRamps = calendarState.ramps.data.map(function (e) { return e._id; });
    var ramps = (filter.fields.find(function (e) { return e.name === 'ramps'; }) || { value: [] })
        .value.filter(function (e) { return availableRamps.includes(e); });
    if (!ramps.length || calendarState.loading || !calendarState.loaded) {
        if (calendarState.loading)
            return React.createElement(Loader, null);
        return React.createElement(TextOverflow, { color: 'error' }, "Require at least one ramp!");
    }
    var onToggleShowRamps = function (day) {
        setSwitchers(switchers.includes(day) ? switchers.filter(function (e) { return e !== day; }) : __spread(switchers, [day]));
    };
    var onChangeBooking = function (item) {
        setChanges(item);
        if (spanContainer && spanContainer.current)
            spanContainer.current.click();
    };
    var onOk = function () { return __awaiter(_this, void 0, void 0, function () {
        var v, lastHour, hoursV;
        var _a, _b;
        var _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0: return [4 /*yield*/, changeOrderBooking(changes)];
                case 1:
                    _e.sent();
                    setChanges(null);
                    v = timeViewFormat2ToValue(changes.newData.hour.toString());
                    lastHour = v + (((_d = (_c = changes === null || changes === void 0 ? void 0 : changes.oldData) === null || _c === void 0 ? void 0 : _c.order) === null || _d === void 0 ? void 0 : _d.timeToSpend) || 60) / 60;
                    hoursV = hours.map(function (hour) { return timeViewFormat2ToValue(hour.toString()); });
                    setHours(sequenceGeneratorV2((_a = Math.min).call.apply(_a, __spread([[]], hoursV)), (_b = Math.max).call.apply(_b, __spread([[]], hoursV, [lastHour]))));
                    setUpdate(Date.now());
                    return [2 /*return*/];
            }
        });
    }); };
    var onClick = function (detail) {
        var _a;
        if (calendarState.isPublic || !((_a = detail.transportations) === null || _a === void 0 ? void 0 : _a.length)) {
            document.body.dispatchEvent(new CustomEvent(WAREHOUSE_BOOKING_EVENT.SHOW, { detail: detail }));
        }
    };
    var currentDay = toDateOnly(new Date());
    var currentMonth = moment(current).format('MMMM');
    var rampStyles = {};
    if (headerDom && headerDom.current) {
        rampStyles.top = headerDom.current.clientHeight;
    }
    var _l = getSlots({ calendarState: calendarState, hours: hours, days: days, ramps: ramps, switchers: switchers }), hourSlots = _l.hourSlots, weekDays = _l.weekDays, rows = _l.rows;
    return (React.createElement(Grid, { container: true, direction: 'column', className: classesTable.root, style: {
            overflowX: 'auto',
            overflowY: 'hidden',
        } },
        React.createElement(BasicDialog, { onOk: onOk, actionOkTitle: langForm.t('form_btn_continue'), title: langOfferForm.t('offer_booking_title'), body: React.createElement(Grid, { container: true, alignItems: 'center' }, changes && (React.createElement(Typography, { dangerouslySetInnerHTML: {
                    __html: "" + langApp
                        .t('you_are_going_to_change')
                        .replace(/_X_/, "<b>" + [
                        parseInt(changes.oldData.time[0]) !== changes.newData.hour &&
                            langApp.t('app_time').toLowerCase() + " = " + timeViewFormat(changes.newData.hour),
                        changes.oldData.date !== changes.newData.day &&
                            langApp.t('app_date').toLowerCase() + " = " + dateViewFormat(changes.newData.day),
                        changes.oldData.item.ramp._id !== changes.newData.item.ramp._id &&
                            langApp.t('warehouse_ramps').toLowerCase() + " = " + changes.newData.item.ramp.name,
                    ]
                        .filter(function (e) { return e; })
                        .join(', ') + "</b>")
                        .replace(/_XX_/, "<b>" + [
                        changes.oldData.order.orderId,
                        changes.oldData.order.sorting_totalWeight,
                        changes.oldData.order.sorting_countryPickup,
                        changes.oldData.order.sorting_countryDelivery,
                    ]
                        .filter(function (e) { return e; })
                        .join(', ') + "</b>"),
                }, style: { width: '100%', textAlign: 'center' } }))), withoutWrapper: true, actionInit: function (onOpen) { return React.createElement("span", { onClick: onOpen, ref: spanContainer }); } }),
        React.createElement("table", { "data-id": "izi-warehouse-days-view", className: classes.root, style: {
                tableLayout: 'auto',
            }, ref: tableContainer },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: 'fix-cell', rowSpan: 2, style: __assign(__assign({ verticalAlign: 'top' }, ((countDays > 1 && { width: 60 }) || {})), { zIndex: 10 }) }, ((_b = calendarState === null || calendarState === void 0 ? void 0 : calendarState.warehouse) === null || _b === void 0 ? void 0 : _b._id) ? (React.createElement(TextOverflow, { className: classes.gmt, style: { textAlign: 'center' } }, [
                        'GMT',
                        calendarState.warehouse.address.location.rawOffset / 60 / 60,
                        (((_d = (_c = calendarState.warehouse.address) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.zone) &&
                            "[" + new Date().toLocaleString('ua', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                                timeZone: calendarState.warehouse.address.location.zone,
                            }) + "]") ||
                            '',
                    ]
                        .filter(function (e) { return e; })
                        .join(' '))) : (React.createElement(TextOverflow, { className: classes.gmt, style: { textAlign: 'center' } },
                        "GMT ",
                        new Date().getTimezoneOffset() / 60))),
                    weekDays.map(function (_a, index) {
                        var _b;
                        var day = _a.day, id = _a.id, ramp = _a.ramp, children = _a.children;
                        var currentDayMoment = moment(toDateOnly(days[index]));
                        var _holidays = holidays.data.filter(function (e) {
                            return moment(toDateOnly(e.start)).isSameOrBefore(currentDayMoment) &&
                                moment(toDateOnly(e.end)).isSameOrAfter(currentDayMoment);
                        });
                        return (React.createElement("th", __assign({ ref: headerDom }, (children.length && { colSpan: children.length + 1 }), { key: day.toString(), style: __assign(__assign(__assign({}, ((countDays > 1 && { width: 60, maxWidth: 100 / countDays + "%" }) || {})), ((switchers.includes(day) && { borderRight: "2px solid " + theme.palette.success.light }) || {})), { verticalAlign: 'top' }) }),
                            React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
                                React.createElement(TextOverflow, { style: { textAlign: 'center', marginRight: theme.spacing(0.5) } },
                                    React.createElement("b", null, langApp.t(day.toLowerCase()))),
                                React.createElement(DayOptions, { day: id })),
                            React.createElement(TextOverflow, { variant: 'subtitle1' },
                                React.createElement("span", { style: { margin: 'auto' }, className: clsx(classesMonth.labelItem, (_b = {},
                                        _b[classesMonth.currentV1] = currentDay === toDateOnly(days[index]),
                                        _b[classesMonth.currentMonth] = moment(days[index]).format('MMMM') === currentMonth,
                                        _b)) }, moment(days[index]).format('DD MMM YYYY'))),
                            (_holidays.length && (React.createElement(DayHolidayDetails, { warehouse: calendarState.warehouse, style: { paddingTop: theme.spacing(1) }, holidays: _holidays }))) ||
                                null));
                    })),
                React.createElement("tr", null, weekDays.map(function (item) {
                    var size = item.children.length;
                    return (React.createElement(React.Fragment, null,
                        React.createElement(RampsSwitcher, { config: props.config, key: "ramp-" + item.ramp._id, record: item, show: !switchers.includes(item.day), total: ramps.length, rampsId: ramps, style: rampStyles, open: switchers.includes(item.day), onToggleShow: function () { return onToggleShowRamps(item.day); }, history: props.history }),
                        item.children.map(function (item, index) { return (React.createElement(RampsSwitcher, { config: props.config, key: "ramp-additional-" + item.ramp._id, record: item, rampsId: ramps, index: index + "-" + size, show: index === size - 1, total: ramps.length, open: switchers.includes(item.day), onToggleShow: function () { return onToggleShowRamps(item.day); }, history: props.history, style: __assign(__assign({ zIndex: 3, backgroundColor: theme.palette.primary.main }, rampStyles), ((index === size - 1 &&
                                switchers.includes(item.day) && {
                                borderRight: "2px solid " + theme.palette.success.light,
                            }) ||
                                {})) })); })));
                }))),
            React.createElement("tbody", null, hours.map(function (hour, index) {
                return (React.createElement("tr", { key: "hours-" + hour, "data-id": "izi-warehouse-hour-" + hour },
                    React.createElement("td", { id: "working-hour-" + hour.toString().replace(':', '-'), className: 'fix-cell', style: __assign({ height: 30, width: 60, verticalAlign: 'top', color: theme.palette.primary.main, position: 'relative', zIndex: 9 }, (countDays > 1 && { maxWidth: 100 / countDays + "%" })) },
                        React.createElement("div", { style: __assign({ position: 'absolute', top: index === 0 ? -4 : -11 }, (parseInt(hour.toString().split(':')[1]) % 30 === 0
                                ? {}
                                : { fontSize: 10, color: theme.palette.grey[900] })) }, hour),
                        index === hours.length - 1 && (React.createElement("div", { style: { position: 'absolute', bottom: -4 } }, timeViewFormat3(hour, 15)))),
                    weekDays.map(function (item, weekIndex) {
                        var cells = hourSlots[index][weekIndex];
                        var size = item.children.length;
                        return (React.createElement(React.Fragment, null,
                            React.createElement(DayHourView, __assign({}, props, { style: __assign({}, (countDays > 1 && { minWidth: 120, width: "calc(100vw - " + 80 * countDays + "px)" })), day: days[weekIndex], hour: hour, item: item, isStyled: true }, cells[0], { onClick: onClick, onChange: onChangeBooking, rows: rows, hours: hours })),
                            item.children
                                .map(function (item, index) {
                                return (React.createElement(DayHourView, __assign({}, props, { onClick: onClick, isStyled: true, style: __assign(__assign({}, (countDays > 1 && { minWidth: 120, width: "calc(100vw - " + 80 * countDays + "px)" })), ((index === size - 1 &&
                                        switchers.includes(item.day) && {
                                        borderRight: "2px solid " + theme.palette.success.light,
                                    }) ||
                                        {})), day: days[weekIndex], hour: hour, item: item }, cells[index + 1], { onChange: onChangeBooking, rows: rows, hours: hours })));
                            })
                                .filter(function (e) { return e; })));
                    })));
            })))));
});
